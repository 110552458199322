export const WhatIsSSC = () => {
    return (<div class="Praesent-commodo-cur COndition1-Answer">
    <p>
    Systemic sclerosis (SSc) is an autoimmune disorder, which means that it 
    is a condition in which the immune system attacks the body. Healthy tissue 
    is destroyed because the immune system mistakenly thinks it’s a 
    foreign substance or infection. There are different kinds of autoimmune 
    disorders, and they can affect the body in different ways. SSc affects 
    the texture and appearance of the skin by causing an increase in collagen 
    production. Collagen is a component of connective tissue. However, 
    SSc can affect areas other than the skin. The condition can negatively 
    affect blood vessels, muscles and the heart. Other areas that can be 
    affected are the lungs, kidneys and digestive system. Systemic sclerosis 
    can appear in other autoimmune disorders. When this occurs, it’s called 
    a mixed connective disorder. The disease is typically seen in people 30 
    to 50 years old, but it can be diagnosed at any age. Women are more likely 
    than men to be diagnosed with SSc, and the symptoms and severity of the 
    condition vary from one person to another based on the systems and organs involved.
    </p>              
  </div>)    
}

export const SSCSymptoms = () => {
  return (<div class="Praesent-commodo-cur COndition1-Answer">
  <p>
  In the early stages, SSc may only affect the skin. Sufferers may notice a 
  thickening of the skin and shiny areas developing around the mouth, nose, 
  fingers and other bony areas. As the condition progresses, SSc sufferers 
  may start to experience limited movement of the affected areas. Other 
  symptoms can include hair loss, joint pain, diarrhea, difficulty swallowing, 
  esophageal reflux and shortness of breath. White lumps under the skin and dilated 
  blood vessels beneath the skin can also occur. Additionally, SSc sufferers may also 
  experience spasms of the blood vessels in the fingers and toes. The extremities may 
  also turn white and blue when in the cold or feeling extreme emotional stress.
  </p>              
</div>)    
}

export const SSCTreatment = () => {
  return (<div class="Praesent-commodo-cur COndition1-Answer">
  <p>
  Typical treatment for systemic sclerosis focuses on managing the condition 
  and the symptoms associated with it. Each person experiences a different set 
  of symptoms and problems with the disease, so healthcare providers play a 
  crucial role in the proper management of systemic sclerosis. By understanding 
  specific symptoms, doctors can create the best disease management plan for 
  SSc sufferers and maximize the chances for a positive outcome.
  </p>              
</div>)    
}
