import React from "react";
import "./glossary.less";
import Spinner from "../shared/spinner";
import BackButton from "../shared/backButton";
import { gql, useQuery } from "@apollo/client";

const GET_GUIDES_GLOSSARY = gql`
  query GetGuidesFour($pageId: String) {
    getGuidesFour(where: { pageId: $pageId }) {
      data {
        pageTitle
        crumbs1
        crumbs2
        crumbs3
        backButtonText
        circleArrowLeftTorch
        terms
        definitions
      }
      error {
        code
        message
        data
      }
    }
  }
`;

const Glossary = () => {
  const { data, loading, error } = useQuery(GET_GUIDES_GLOSSARY, {
    variables: { pageId: "cpex_guides_glossary" },
    errorPolicy: "all",
  });
  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;

  window.gtag("event", "page_view", {
    page_title: "Glossary",
    page_path: window.location.pathname,
  });

  window.addEventListener("scroll", (event) => {
    var Alhadiv = document.getElementById("AlphaSeries");
    if (window.scrollY < 200 && Alhadiv !== null && Alhadiv !== undefined) {
      document.getElementById("AlphaSeries").className = "Div-Glossary";
    } else if (
      window.scrollY > 300 &&
      Alhadiv !== null &&
      Alhadiv !== undefined
    ) {
      document.getElementById("AlphaSeries").className =
        "Div-BackButton-Glossary Div-Glossary ScrolApplied";
    }
  });

  const Alphbets = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  const TermsArray = [
    { Terms: "arm", RootTitle: "Arm" },
    { Terms: "eligibility criteria", RootTitle: "Eligibilitycriteria" },
    {
      Terms: "principal investigator",
      RootTitle: "Principalinvestigator (PI)",
    },
    { Terms: "Phase 1", RootTitle: "Phase1" },
    { Terms: "serious adverse events", RootTitle: "Seriousadverse event" },
    { Terms: "placebo", RootTitle: "Placebo" },
  ];
  const GlossaryVisible = [];
  const BuildTerminology = () => {
    return Alphbets.map((Alph, index) => {
      let TerminologyList = data.getGuidesFour.data.terms;
      TerminologyList = TerminologyList.filter((Term, TermIndex) =>
        Term.startsWith(Alph)
      );
      if (TerminologyList !== null && TerminologyList !== undefined) {
        if (TerminologyList.length > 0) {
          GlossaryVisible.push(Alph);
          return (
            <div key={index} id={"AlphMain" + Alph}>
              <div
                className="glossary-Sub-Catagory"
                key={index}
                id={"Alph" + Alph}
              >
                {Alph}
              </div>
              <div
                className={
                  Alph === "S"
                    ? "margin-bottom-32-Glossary mb-Glossary-0"
                    : "margin-bottom-32-Glossary"
                }
              >
                {BuildSubTerminology(TerminologyList)}
              </div>
            </div>
          );
        }
      }

      return null;
    });
  };
  const BuildSubTerminology = (TerminologyList) => {
    return TerminologyList.map((Term, TermIndex) => {
      let Index = 0;
      let COntainsTerms = false;
      let COntainText = "";
      let TermIndexScroll = "";
      data.getGuidesFour.data.terms.map((Guides, GuidesIndex) => {
        if (Term === Guides) {
          Index = GuidesIndex;
        }
        return 0;
      });
      TermsArray.map((Guides, GuidesIndex) => {
        if (
          COntainsTerms === false &&
          data.getGuidesFour.data.terms[Index].toUpperCase() !==
            Guides.Terms.toUpperCase()
        ) {
          COntainsTerms = data.getGuidesFour.data.definitions[
            Index
          ].toUpperCase().includes(Guides.Terms.toUpperCase());
          TermIndexScroll = Guides.RootTitle;
          if (Guides.Terms === "arm") {
            if (data.getGuidesFour.data.terms[Index] !== "Participant flow") {
              COntainsTerms = false;
            }
          }
          if (Guides.Terms === "serious adverse events") {
            if (data.getGuidesFour.data.terms[Index] !== "Phase 1") {
              COntainsTerms = false;
            }
          }
          COntainText = Guides.Terms;
        }
        return 0;
      });
      let ReplaceBreakLines = data.getGuidesFour.data.definitions[
        Index
      ].replace("<br /><br />", "");

      return (
        <div key={TermIndex}>
          <div
            className="Accepts-healthy-volu Accepts-healthy-volu-text-style-1"
            id={data.getGuidesFour.data.terms[Index].replace(" ", "")}
          >
            {data.getGuidesFour.data.terms[Index]}
          </div>
          <span className="Accepts-healthy-volu">
            {COntainsTerms === false
              ? ReplaceBreakLines
              : BuildLink(COntainText, ReplaceBreakLines, TermIndexScroll)}
          </span>
        </div>
      );
    });
  };
  const BuildLink = (ContainText, textvalue, TermTitle) => {
    let StartIndex = textvalue.indexOf(ContainText);
    let Phasevlaue = false;
    if (ContainText === "Phase 1") {
      StartIndex = textvalue.lastIndexOf(ContainText);
      Phasevlaue = true;
    }
    return (
      <React.Fragment>
        <span>{textvalue.slice(0, StartIndex)}</span>
        {Phasevlaue !== true ? (
          <span
            className="text-style-2"
            onClick={(e) => ScrollToPage(TermTitle, e)}
          >
            {textvalue.slice(StartIndex, StartIndex + ContainText.length)}
          </span>
        ) : (
          <React.Fragment>
            <span
              className="text-style-2"
              onClick={(e) => ScrollToPage(TermTitle, e)}
            >
              {textvalue.slice(StartIndex, StartIndex + ContainText.length)}
            </span>
            <span>
              {textvalue.slice(
                StartIndex + ContainText.length,
                StartIndex + ContainText.length + 2
              )}
            </span>
            <span
              className="text-style-2"
              onClick={(e) => ScrollToPage("Phase2", e)}
            >
              {textvalue.slice(
                StartIndex + ContainText.length + 2,
                StartIndex + ContainText.length + 9
              )}
            </span>

            <span>
              {textvalue.slice(
                StartIndex + ContainText.length + 9,
                StartIndex + ContainText.length + 11
              )}
            </span>
            <span
              className="text-style-2"
              onClick={(e) => ScrollToPage("Phase3", e)}
            >
              {textvalue.slice(
                StartIndex + ContainText.length + 11,
                StartIndex + ContainText.length + 18
              )}
            </span>

            <span>
              {textvalue.slice(
                StartIndex + ContainText.length + 18,
                StartIndex + ContainText.length + 24
              )}
            </span>
            <span
              className="text-style-2"
              onClick={(e) => ScrollToPage("Phase4", e)}
            >
              {textvalue.slice(
                StartIndex + ContainText.length + 24,
                StartIndex + ContainText.length + 31
              )}
            </span>
          </React.Fragment>
        )}

        {Phasevlaue !== true ? (
          <span>{textvalue.slice(StartIndex + ContainText.length)}</span>
        ) : (
          <span>{textvalue.slice(StartIndex + ContainText.length + 31)}</span>
        )}
      </React.Fragment>
    );
  };
  const BuildAlphSeries = () => {
    return Alphbets.map((Alph, index) => {
      let TerminologyList = data.getGuidesFour.data.terms;
      TerminologyList = TerminologyList.filter((Term, TermIndex) =>
        Term.startsWith(Alph)
      );
      return (
        <div
          key={index}
          className={
            TerminologyList !== null &&
            TerminologyList !== undefined &&
            TerminologyList.length > 0
              ? "Rectangle-Glossary"
              : "Rectangle-Glossary Alph-light"
          }
          onClick={(e) =>
            ScrollToPage(
              TerminologyList !== null &&
                TerminologyList !== undefined &&
                TerminologyList.length > 0
                ? "Alph" + Alph
                : "",
              e
            )
          }
        >
          {Alph}
        </div>
      );
    });
  };
  const ScrollToPage = (Classname, e) => {
    e.preventDefault();
    if (Classname !== "") {
      Element.prototype.documentOffsetTop = function () {
        return (
          this.offsetTop +
          (this.offsetParent ? this.offsetParent.documentOffsetTop() : 0)
        );
      };
      var top =
        document.getElementById(Classname).documentOffsetTop() -
        window.innerHeight / 2;
      top =
        document.getElementById("AlphaSeries").className === "Div-Glossary"
          ? top - 25
          : top + 95;
      window.scrollTo(0, top);
    }
  };

  return (
    <React.Fragment>
      <div className="Guides---Glossary---S">
        <BackButton
          ButtonText={data.getGuidesFour.data.backButtonText}
          Crumb1={data.getGuidesFour.data.crumbs1}
          Crumb2={data.getGuidesFour.data.crumbs2}
          Crumb3={data.getGuidesFour.data.crumbs3}
          Path1="/"
          Path2="/learn"
        ></BackButton>

        <div className="Clinical-Trial-Frequ-Glossary">
          {data.getGuidesFour.data.pageTitle}
        </div>
        <div className="Div-BackButton-Glossary Div-Glossary" id="AlphaSeries">
          {BuildAlphSeries()}
        </div>

        <div className="Clinical-Trial-Glossary-Alph">{BuildTerminology()}</div>
      </div>
    </React.Fragment>
  );
};
export default Glossary;
