import React from "react";
import "./guideFQA.less";
import Spinner from "../shared/spinner";
import BackButton from "../shared/backButton";
import { gql, useQuery } from "@apollo/client";
import FQAQuestion from "./GuideFQAQuestion";

export const GET_GUIDES_FAQS = gql`
  query GetGuidesThree($pageId: String) {
    getGuidesThree(where: { pageId: $pageId }) {
      data {
        pageTitle
        crumbs1
        crumbs2
        crumbs3
        backButtonText
        circleArrowLeftTorch
        questions
        answers {
          answerItems {
            type
            text
            position
          }
          position
        }
      }
    }
  }
`;

const GuideFQA = () => {
  const { data, loading, error } = useQuery(GET_GUIDES_FAQS, {
    variables: { pageId: "cpex_guides_faqs" },
    errorPolicy: "all",
  });
  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;
  window.gtag("event", "page_view", {
    page_title: "FAQ",
    page_path: window.location.pathname,
  });
  const BuildFAQ = () => {
    let answers = data.getGuidesThree.data.answers
      .slice()
      .sort((a, b) => a.position - b.position);
    return data.getGuidesThree.data.questions.map((Ques, Index) => {
      return (
        <React.Fragment key={"FAQs" + Index}>
          <FQAQuestion
            Question={data.getGuidesThree.data.questions[Index]}
            Answer={answers[Index].answerItems}
            Index={Index}
            classname={
              Index > data.getGuidesThree.data.questions.length - 2
                ? "mb-0"
                : ""
            }            
          ></FQAQuestion>    
        </React.Fragment>        
      );
    });
  };
  return (
    <React.Fragment>
      <div className="Guides---FAQ---S">
        <div className="Div-BackButton">
          <BackButton
            ButtonText={data.getGuidesThree.data.backButtonText}
            Crumb1={data.getGuidesThree.data.crumbs1}
            Crumb2={data.getGuidesThree.data.crumbs2}
            Crumb3={data.getGuidesThree.data.crumbs3}
            Path1="/"
            Path2="/learn"
          ></BackButton>
        </div>
        <div className="Clinical-Trial-Frequ">
          {data.getGuidesThree.data.pageTitle}
        </div>
        {BuildFAQ()}
        <div class="Praesent-commodo-cur COndition1-Answer">
          <p>Source: CISCRP (Center for Information and Study on 
            Clinical Research Participation)
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
export default GuideFQA;
