import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  makeVar,
} from "@apollo/client";
import { AUTH_TOKEN } from "../constants";
import { setContext } from "@apollo/client/link/context";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
  const token = process.env.REACT_APP_API_TOKEN;

  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
      "X-Cpex-Auth": token ? `${sessionStorage.getItem(AUTH_TOKEN)}` : "",
    },
  };
});

export const isLoggedInVar = makeVar(!!sessionStorage.getItem(AUTH_TOKEN));

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          },
          merge: true,
        },
      },
    },
  },
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
});
