export const whatIsAMR = () => {
  return (<div class="Praesent-commodo-cur COndition1-Answer">
  <p>
    Antibody-mediated rejection (AMR) is a condition that may occur 
    after receiving a transplanted kidney or another organ. During a 
    rejection episode, your immune system recognizes the transplanted 
    organ as foreign, developing white blood cells and proteins, called 
    antibodies, to attack it. Antibodies that cause AMR can severely 
    damage or even destroy the new organ. Anti-rejection medications 
    may help to prevent or control some parts of the rejection response, 
    but once AMR occurs, it can be difficult to treat and may slowly 
    damage the new organ over a long period.
  </p>              
</div>)    
}

export const AMRSymptoms = () => {
    return (<div class="Praesent-commodo-cur COndition1-Answer">
    <p>
    AMR in kidney transplantation is often discovered through routine blood work. 
    After the transplant, doctors will monitor for symptoms such as increased 
    serum creatinine level, or increased protein in the urine (also called proteinuria). 
    AMR is diagnosed through a biopsy, a procedure that removes a very small piece 
    of the kidney for analysis in a laboratory under a microscope.
    </p>              
  </div>)    
}