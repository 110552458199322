import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

export class MapContainer extends Component {
  state = {
    showingInfoWindow: false,
    activeMarkerId: 0,
    activeMarker: {},
    selectedPlace: {},
  };
  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
        activeMarkerId: 0,
      });
    }
  };
  render() {
    return (
      <Map
        google={this.props.google}
        zoom={4}
        initialCenter={{
          lat: 40.46032,
          lng: -75.40962,
        }}
        onClick={this.onMapClicked}
      >
        {this.props.data.map((item, i) => {
          return (
            <Marker
              key={i}
              id={i}
              data={item}
              position={{ lat: item.latitude, lng: item.longitude }}
              onClick={this.onMarkerClick}
              name={"Current location"}
            />
          );
        })}

        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
        >
          <div>
            <h5>
              {this.state.activeMarker !== undefined &&
              this.state.activeMarker !== null &&
              this.state.activeMarker !== {} &&
              this.state.activeMarker.data !== undefined
                ? this.state.activeMarker.data.name
                : ""}
            </h5>
            <h5>{`${
              this.state.activeMarker !== undefined &&
              this.state.activeMarker !== null &&
              this.state.activeMarker !== {} &&
              this.state.activeMarker.data !== undefined
                ? this.state.activeMarker.data.city
                : ""
            }, ${
              this.state.activeMarker !== undefined &&
              this.state.activeMarker !== null &&
              this.state.activeMarker !== {} &&
              this.state.activeMarker.data !== undefined
                ? this.state.activeMarker.data.state
                : ""
            }, ${
              this.state.activeMarker !== undefined &&
              this.state.activeMarker !== null &&
              this.state.activeMarker !== {} &&
              this.state.activeMarker.data !== undefined
                ? this.state.activeMarker.data.zip_code
                : ""
            }, ${
              this.state.activeMarker !== undefined &&
              this.state.activeMarker !== null &&
              this.state.activeMarker !== {} &&
              this.state.activeMarker.data !== undefined
                ? this.state.activeMarker.data.country
                : ""
            }`}</h5>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  data: [],
})(MapContainer);
