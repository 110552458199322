import React from "react";
import "./index.less";
import Flicking from "@egjs/react-flicking";
import CarouselItem from "../home/carouselItem";
import Spinner from "../shared/spinner";
import BackButton from "../shared/backButton";
import { gql, useQuery } from "@apollo/client";
import ResponsiveBg from "../shared/responsiveBg";
import {whatIsASickleCell, sickleCellSymptoms, sickleCellTreatments} from "./SDC/SickleCellDiseaseDescriptions";
import { whatIsGVHD, GVHDSymptoms } from "./GVHD/gVHDDescriptions";
import {whatIsDKD} from "./DKD/dkdDescriptions";
import {whatIsAMR, AMRSymptoms} from "./AMR/amrDescriptions";
import {WhatIsSSC, SSCSymptoms, SSCTreatment} from "./SSC/SSCdescriptions";

const GET_CONDITION = gql`
  query GetCondition($pageId: String) {
    getCondition(where: { pageId: $pageId }) {
      data {
        pageTitle
        crumbs1
        crumbs2
        crumbs3
        backButtonText
        circleLeftArrowTorch
        circleRightArrowTorch
        sections {
          title
          description
          position
        }
        moreSectionTitle
        vitaLogo
        newsAndUpdatesSectionTitle
        articles {
          image
          title
          date
          content
          link
          position
        }
      }
      error {
        code
        message
        data
      }
    }
  }
`;

const Conditions = () => {
  const COnditionPageId = new URLSearchParams(window.location.search);
  const { data, loading, error } = useQuery(GET_CONDITION, {
    variables: { pageId: COnditionPageId.get("pageid") },
    errorPolicy: "all",
  });
  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;

  const MoreConditions = () => {
    return data.getCondition.data.articles.map(
      (articlesItem, articlesIndex) => {
        return (
          <React.Fragment key={"articles" + articlesIndex}>
            <div className="panel">
              <CarouselItem
                headerImage={articlesItem.image}
                title={articlesItem.title}
                posted={articlesItem.date}
                description={articlesItem.text}
                link={articlesItem.link}
              />
            </div>
          </React.Fragment>
        );
      }
    );
  };

  const BuildSection = () => {
    let Section = data.getCondition.data.sections
      .slice()
      .sort((a, b) => a.position - b.position);
    return Section.map((sectionsItem, sectionsIndex) => {
      //Is SCD Page Id?
      const pageId = new URLSearchParams(window.location.search);

      let isSCDPage = pageId.get("pageid") === "cpex_conditions_scd";
      let isGVHDPage = pageId.get("pageid") === "cpex_conditions_gvhd";
      let isDKDPage = pageId.get("pageid") === "cpex_conditions_dkd";
      let isAMRPage = pageId.get("pageid") === "cpex_conditions_amr";
      let isSSCPage = pageId.get("pageid") === "cpex_conditions_ssc";
      
      return (
        <React.Fragment key={"sections" + sectionsIndex}>
          <div className="Condition-Title SUbTitle-Condition">
            {sectionsItem.title}
          </div>
          <div
            className={
              sectionsIndex === 0
                ? "Praesent-commodo-cur COndition1-Answer"
                : "Praesent-commodo-cur"
            }
          >            
          </div>         
          {isSCDPage ?
              (sectionsItem.title === "What is Sickle Cell Disease?" ? whatIsASickleCell() : 
              sectionsItem.title === "What are the Symptoms of Sickle Cell Disease?" 
                || sectionsItem.title === "[DEV/Local] What are the Symptoms of Sickle Cell Disease?" ? sickleCellSymptoms() :
              sectionsItem.title === "How is Sickle Cell Disease Treated?" ?  sickleCellTreatments() : (sectionsItem.description)) 
          : 
          isGVHDPage ? 
            (sectionsItem.title === "What is Graft-versus-Host Disease?" ? whatIsGVHD() : 
            sectionsItem.title === "What are the Symptoms of Graft-versus-Host Disease?" ? GVHDSymptoms() : (sectionsItem.description))
          :
          isDKDPage ? 
            (sectionsItem.title === "What is Diabetic Kidney Disease?" ? whatIsDKD() : sectionsItem.description)
          :
          isAMRPage ? 
            (sectionsItem.title === "What is Antibody-Mediated Rejection?" ? whatIsAMR() : 
            (sectionsItem.title === "What are the Symptoms of Antibody-Mediated Rejection?" ? AMRSymptoms() : sectionsItem.description))
            
          :
          isSSCPage ? 
            (sectionsItem.title === "What is Systemic Sclerosis?" ? WhatIsSSC() : 
            (sectionsItem.title === "What are the Symptoms of Systemic Sclerosis?" ? SSCSymptoms() : 
            (sectionsItem.title === "How is Systemic Sclerosis Treated?" ? SSCTreatment()  : sectionsItem.description)))
          :
          sectionsItem.description
          }            
        </React.Fragment>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="Guides---Glossary---S">
        <BackButton
          ButtonText={data.getCondition.data.backButtonText}
          Crumb1={data.getCondition.data.crumbs1}
          Crumb2={data.getCondition.data.crumbs2}
          Crumb3={data.getCondition.data.crumbs3}
          Path1="/"
          Path2="/learn"
        ></BackButton>
        <div className="Condition-Title">
          {data.getCondition.data.pageTitle}
        </div>
        {BuildSection()}
        <div className="More-on-Condition">
          {data.getCondition.data.moreSectionTitle}
        </div>
        <ResponsiveBg
          className="Bitmap-Condition"
          smallBg={data.getCondition.data.vitaLogo}
          mediumBg={data.getCondition.data.vitaLogo}
          largeBg={data.getCondition.data.vitaLogo}
        />
        <div className="More-on-Condition">
          {data.getCondition.data.newsAndUpdatesSectionTitle}
        </div>
        <div className="flicking-wrap">
          <Flicking
            className="flicking"
            gap={0}
            duration={650}
            bound={true}
            autoResize={true}
          >
            {MoreConditions()}
          </Flicking>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Conditions;
