import React from "react";
import { useMediaQuery } from "react-responsive";

const ResponsiveBg = ({ className, smallBg, mediumBg, largeBg }) => {
  const isMedium = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1199px)",
  });
  const isLarge = useMediaQuery({ query: "(min-width: 1200px)" });

  return (
    <div
      style={{
        backgroundImage:
          !isMedium && !isLarge
            ? `url('${smallBg}')`
            : isMedium
            ? `url('${mediumBg}')`
            : `url('${largeBg}')`,
      }}
      className={className}
    />
  );
};

export default ResponsiveBg;
