import React from "react";
import { useParams, Link } from "react-router-dom";
import ImageButton from "../shared/imageButton";
import "./studyGuide.less";

import LeftArrow from "../../assets/icons/ic_circle-arrow-left Torch.png";
import RightArrow from "../../assets/icons/ic_circle-arrow-right Torch.png";
import PlaceholderImage from "../../assets/study-guide-placeholder.png";
import PDFIcon from "../../assets/icons/ic_pdf.png";

const StudyGuide = () => {
  const { guide } = useParams();

  const guides = [
    {
      id: "welcome",
      image: PlaceholderImage,
      title: "Welcome",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      id: "overview",
      image: PlaceholderImage,
      title: "Overview",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Felis donec et odio pellentesque diam volutpat commodo.",
    },
    {
      id: "participation",
      image: PlaceholderImage,
      title: "Your Participation",
      content:
        "It is important for purposes of the study, and for you as a patient, that you understand what taking part in this study will mean for you, how the study will be run, and what you can do in case of study-related side effects. This will help you make an informed decision about whether you can take part in the study for the entire length of the trial — with the tests and procedures neces- sary, as noted on pages 6 and 7. All people taking part in the study will sign an Informed Consent Form before starting any other study-related procedures or treatment.<br/><br/>Taking part in any clinical research study is your choice. If you decide not to take part in the IMPRESS study, your care will not be impacted in any way. If you do sign up to take part in the study, but then decide you no longer want to participate, you can stop at any time, and you can talk about the best option for treatment of your SSc with your Study Doctor.<br/><br/>You will be randomly assigned (meaning by chance, like the flip of a coin) to either the Treatment group or the Placebo group. Again, the Treatment group will receive the Study Drug (IgPro10) for all 72 weeks. The Placebo group will receive a placebo for the first 48 weeks and the Study Drug (IgPro10) for the next 24 weeks. You will not know which group you are in, and neither will the Study Doctor. The entire study lasts about 80 weeks.<br/><br/>Throughout the IMPRESS study, you will be asked to:<br/><br/>• Follow all study procedures as directed by your Study Doctor.<br/><br/>• Keep all scheduled appointments with your Study Doctor.",
    },
    {
      id: "expectations",
      image: PlaceholderImage,
      title: "What to expect",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sit amet justo donec enim diam vulputate.",
    },
    {
      id: "study-tests-and-procedures",
      image: PlaceholderImage,
      title: "Study Tests and Procedures",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Porta lorem mollis aliquam ut porttitor leo a diam sollicitudin.",
    },
    {
      id: "other-tests-and-procedures",
      image: PlaceholderImage,
      title: "Other Tests and Procedures",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Maecenas accumsan lacus vel facilisis.",
    },
    {
      id: "faq",
      image: PlaceholderImage,
      title: "Frequently Asked Questions",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nulla malesuada pellentesque elit eget gravida cum sociis natoque penatibus.",
    },
    {
      id: "study-visits",
      image: PlaceholderImage,
      title: "Study Visits",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Laoreet non curabitur gravida arcu.",
    },
    {
      id: "your-privacy",
      image: PlaceholderImage,
      title: "Your Privacy",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Egestas sed sed risus pretium quam vulputate.",
    },
  ];

  const index = guides.findIndex((g) => g.id === guide);
  const prevGuide = guides[index - 1];
  const nextGuide = guides[index + 1];

  return (
    <React.Fragment>
      <div className="content-edge content-ceiling">
        <div className="study-guide-back-wrap">
          <ImageButton type="left" to="/trial" text="Back" />
          <Link className="plain" to="/trial">
            <span
              className="pdf-img"
              style={{ backgroundImage: `url(${PDFIcon}})` }}
            />
            Download Study Guide
          </Link>
        </div>
        <div className="study-guide-page-wrap">
          <h1>Study Guide</h1>
          <h2 className="study-guide-name">{guides[index].title}</h2>
          <div className="study-guide-content-area">
            <div
              className="study-guide-content-img"
              style={{ backgroundImage: `url('${guides[index].image}')` }}
            ></div>
            <div
              className="study-guide-content"
              dangerouslySetInnerHTML={{
                __html: guides[index].content,
              }}
            />
          </div>
          <div className="study-guide-next-prev-wrap">
            {prevGuide !== undefined && (
              <Link
                className="plain prev"
                style={{ backgroundImage: `url('${LeftArrow}')` }}
                to={`/trial/study-guide/${prevGuide.id}`}
              >
                {prevGuide.title}
              </Link>
            )}

            {prevGuide !== undefined && nextGuide !== undefined && (
              <div className="study-guide-splitter"></div>
            )}

            {nextGuide !== undefined && (
              <Link
                className="plain next"
                style={{ backgroundImage: `url('${RightArrow}')` }}
                to={`/trial/study-guide/${nextGuide.id}`}
              >
                {nextGuide.title}
              </Link>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StudyGuide;
