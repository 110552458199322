import React from "react";
import Header from "./header";
import Error from "../shared/error";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: {},
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidMount() {
    this.props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ ...this.state, hasError: false });
      }
    });
  }

  componentDidCatch(error) {
    this.setState({ ...this.state, error: error });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id="main-content">
          <Header isLight="true" />
          <div id="page-wrap">
            <div className="error-container">
              <div className="error-content">
                <Error error={this.state.error} />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
