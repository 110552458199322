import React from "react";
import {
  NavLink,
  Route,
  Switch,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { client as apiClient } from "../../api/apiClient";
import { IS_USER_PROFILE_EDITED } from "../../constants";
import CheckMultiSession from "./checkMultiSession";
import Dashboard from "./dashboard";
import Location from "./location";
import Settings from "./settings";
import Messages from "./messages";
import "./trial.less";
import DashboardIcon from "../../assets/icons/ic_dashboard.png";
import DashboardTorchIcon from "../../assets/icons/ic_dashboard torch.png";
import PinIcon from "../../assets/icons/ic_pin.png";
import PinTorchIcon from "../../assets/icons/ic_pin torch.png";
import SettingsIcon from "../../assets/icons/ic_settings.png";
import SettingsTorchIcon from "../../assets/icons/ic_settings Torch.png";
import MessagesIcon from "../../assets/icons/ic_messages.png";
import MessagesTorchIcon from "../../assets/icons/ic_messages Torch.png";
const ERROR_FIELDS = gql`
  {
    code
    message
    data
  }
`;
export const GET_STUDY = gql`
  query GetStudy {
    cpex {
      getStudy {
        data {
          dashboard_type
          study_name
          study_code
          dashboard {
            image_link
            description
            overview {
              content
              pdf_link
            }
            study_guide {
              content
              pdf_link
            }
          }
          locations {
            id
            name
            city
            state
            country
            zip_code
          }
          messages {
            count
            messages {
              id
              subject
              message
              position
              read
            }
          }
          settings {
            patient_type
            email
            first_name
            last_name
            phone
            contact_preference
            notifications_ct_info
            notifications_marketing
            notifications_news
          }
        }
        error ${ERROR_FIELDS}
      }
    }
  }
`;
const Trial = () => {
  const { data } = useQuery(GET_STUDY, {
    client: apiClient,
  });
  const history = useHistory();
  if (
    localStorage.getItem(IS_USER_PROFILE_EDITED) === 1 ||
    localStorage.getItem(IS_USER_PROFILE_EDITED) === "1"
  ) {
    localStorage.setItem(IS_USER_PROFILE_EDITED, "");
    window.location.reload();
  } else if (
    localStorage.getItem(IS_USER_PROFILE_EDITED) === 2 ||
    localStorage.getItem(IS_USER_PROFILE_EDITED) === "2"
  ) {
    window.location.reload();
    history.push("/trial/location");
  }
  return (
    <div className="content-edge expanded-xxl content-ceiling">
      <h1>
        {data !== undefined &&
        data.cpex.getStudy.data !== null &&
        data.cpex.getStudy.data.study_name !== undefined
          ? data.cpex.getStudy.data.study_name
          : ""}
      </h1>
      <div className="trial-menu">
        <NavLink
          className={
            data !== undefined &&
            data.cpex.getStudy.data !== null &&
            data.cpex.getStudy.data.dashboard !== undefined
              ? "trial-link"
              : "CBL-trial-hide"
          }
          to="/trial"
          style={{
            backgroundImage: `url('${
              useRouteMatch({ path: "/trial", strict: true, exact: true })
                ? DashboardTorchIcon
                : DashboardIcon
            }'`,
          }}
        >
          Dashboard
        </NavLink>

        <NavLink
          className="trial-link"
          to="/trial/location"
          style={{
            backgroundImage: `url('${
              useRouteMatch({
                path: "/trial/location",
                strict: true,
                exact: true,
              })
                ? PinTorchIcon
                : PinIcon
            }'`,
          }}
        >
          Location
        </NavLink>

        <NavLink
          className="CBL-trial-hide"
          to="/trial/messages"
          style={{
            backgroundImage: `url('${
              useRouteMatch({
                path: "/trial/messages",
                strict: true,
                exact: true,
              })
                ? MessagesTorchIcon
                : MessagesIcon
            }'`,
          }}
        >
          Messages <b>(1)</b>
        </NavLink>

        <NavLink
          className="trial-link"
          to="/trial/settings"
          style={{
            backgroundImage: `url('${
              useRouteMatch({
                path: "/trial/settings",
                strict: true,
                exact: true,
              })
                ? SettingsTorchIcon
                : SettingsIcon
            }'`,
          }}
        >
          Settings
        </NavLink>
      </div>
      <CheckMultiSession></CheckMultiSession>
      <hr className="message-hr" />
      <Switch>
        <Route exact path="/trial">
          <Dashboard cslData={data}></Dashboard>
        </Route>
        <Route exact path="/trial/location">
          <Location cslData={data}></Location>
        </Route>
        <Route exact path="/trial/messages">
          <Messages cslData={data}></Messages>
        </Route>
        <Route exact path="/trial/settings">
          <Settings cslData={data}></Settings>
        </Route>
      </Switch>
    </div>
  );
};

export default Trial;
