import React from "react";

import "./error.less";

const Error = ({ error }) => {
  let errorMessage = "Oops! Something went wrong!";

  if (
    error.networkError &&
    typeof window !== "undefined" &&
    !window.navigator.onLine
  ) {
    errorMessage = "Network error";
  }

  if (error.errorMessage) {
    errorMessage = error.errorMessage;
  }

  for (const gqlError in error.graphQLErrors) {
    errorMessage = gqlError.message;
  }

  window.gtag("event", "exception", {
    description: errorMessage,
    fatal: false,
  });

  return (
    <div className="error-container">
      <div className="error-content">
        <h2>{errorMessage}</h2>
      </div>
    </div>
  );
};

export default Error;
