import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ReactModal from "react-modal";
import { gql, useQuery } from "@apollo/client";
import { client, isLoggedInVar } from "../../api/apiClient";
import { client as apiClient } from "../../api/apiClient";
const MULTIPLE_SESSIONS = gql`
  query MultipleSessions {
    cpex {
      hasMultipleSessions {
        data {
          multiple_sessions
        }
        error {
          code
          message
          data
        }
      }
    }
  }
`;
const MultiSession = () => {
  const [openPopUp, setopenPopUp] = useState(false);

  const history = useHistory();
  const { data } = useQuery(MULTIPLE_SESSIONS, {
    client: apiClient,
  });

  if (data !== undefined) {
    if (
      data.cpex.hasMultipleSessions.data.multiple_sessions !== undefined &&
      data.cpex.hasMultipleSessions.data.multiple_sessions !== null &&
      openPopUp === false
    ) {
      if (data.cpex.hasMultipleSessions.data.multiple_sessions === true) {
        setopenPopUp(true);
      }
    }
  }
  const closeMultiAthenPopUp = () => {
    setopenPopUp(false);
    sessionStorage.clear();
    client.clearStore();
    isLoggedInVar(false);
    history.push("/");
  };
  return (
    <ReactModal
      isOpen={openPopUp}
      className={"csl-modal Combined-Shape-MultiSession"}
      overlayClassName="csl-modal-overlay"
      onRequestClose={() => closeMultiAthenPopUp()}
    >
      <div className="Important-Multi-ses">
        {"Important! Multi-session detected"}
      </div>
      <div className="Border-Rectangle"></div>
      <div className="This-session-was-has">
        {
          "This session was has been closed because you logged in somewhere else."
        }
      </div>
      <button
        className="tertiary margin-top-8 Rectangle-Multi-Ses"
        onClick={() => closeMultiAthenPopUp()}
      >
        Close
      </button>
    </ReactModal>
  );
};
export default MultiSession;
