import React from "react";
import "./messages.less";
import LinkList from "../shared/linkList";

const Messages = () => {
  const messages = [
    {
      to: "/trial/messages",
      text: "Welcome to the prescreening for [Title] clinical study",
    },
  ];

  return (
    <div className="message-block shadow-box-small">
      <div className="message-head">
        <h2 className="force-mobile">Messages</h2>
        <span className="message-count">1</span>
      </div>
      <LinkList items={messages} />
    </div>
  );
};

export default Messages;
