import React, { useState, useEffect, useCallback } from "react";
import "./passwordValidator.less";

const PasswordValidator = ({
  password,
  className,
  onValidChange,
  lengthText,
  lowerText,
  numberText,
  specialText,
  upperText,
}) => {
  //validation state
  const [minMaxCharacterValid, setMinMaxCharacterValid] = useState(false);
  const [oneUpperValid, setOneUpperValid] = useState(false);
  const [oneLowerValid, setOneLowerValid] = useState(false);
  const [oneSpecialValid, setOneSpecialValid] = useState(false);
  const [oneNumberValid, setOneNumberValid] = useState(false);

  //callback
  const handleValidChange = useCallback(
    (e) => {
      if (onValidChange == null) return;
      onValidChange(e);
    },
    [onValidChange]
  );

  useEffect(() => {
    //min character
    if (password.length >= 8 && password.length <= 12) {
      setMinMaxCharacterValid(true);
    } else {
      setMinMaxCharacterValid(false);
    }

    //has upper
    if (password.match("(?=.*[A-Z])")) {
      setOneUpperValid(true);
    } else {
      setOneUpperValid(false);
    }

    //has lower
    if (password.match("(?=.*[a-z])")) {
      setOneLowerValid(true);
    } else {
      setOneLowerValid(false);
    }

    //has special
    var specialRegEx = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;
    if (password.match(specialRegEx)) {
      setOneSpecialValid(true);
    } else {
      setOneSpecialValid(false);
    }

    //has number
    if (password.match(".*[0-9].*")) {
      setOneNumberValid(true);
    } else {
      setOneNumberValid(false);
    }

    //raise result
    if (
      minMaxCharacterValid &&
      oneUpperValid &&
      oneLowerValid &&
      oneSpecialValid &&
      oneNumberValid
    ) {
      handleValidChange(true);
      return;
    }

    handleValidChange(false);
  }, [
    password,
    minMaxCharacterValid,
    oneUpperValid,
    oneLowerValid,
    oneSpecialValid,
    oneNumberValid,
    handleValidChange,
  ]);

  return (
    <ul className={`pass-requirements ${className}`}>
      <li>
        <i className={`material-icons ${minMaxCharacterValid ? "valid" : ""}`}>
          {minMaxCharacterValid ? "check_circle" : "brightness_1"}
        </i>
        <span>{lengthText}</span>
      </li>
      <li>
        <i className={`material-icons ${oneUpperValid ? "valid" : ""}`}>
          {oneUpperValid ? "check_circle" : "brightness_1"}
        </i>
        <span>{upperText}</span>
      </li>
      <li>
        <i className={`material-icons ${oneLowerValid ? "valid" : ""}`}>
          {oneLowerValid ? "check_circle" : "brightness_1"}
        </i>
        <span>{lowerText}</span>
      </li>
      <li>
        <i className={`material-icons ${oneSpecialValid ? "valid" : ""}`}>
          {oneSpecialValid ? "check_circle" : "brightness_1"}
        </i>
        <span>{specialText}</span>
      </li>
      <li>
        <i className={`material-icons ${oneNumberValid ? "valid" : ""}`}>
          {oneNumberValid ? "check_circle" : "brightness_1"}
        </i>
        <span>{numberText}</span>
      </li>
    </ul>
  );
};

export default PasswordValidator;
