import React from "react";
import "./guideFQA.less";
const FQAQuestion = ({ Question, Answer, Index, classname }) => {
  const ShowAnswer = () => {
    let QuestionClass = document.getElementById("Ques" + Index).className;
    if (QuestionClass === "Path-FQA Path-FQA-right") {
      document.getElementById("Ans" + Index).className =
        "Nulla-vitae-elit-lib display-block";
      document.getElementById("Ques" + Index).className =
        "Path-FQA Path-FQA-down";
      document.getElementById("maindiv" + Index).className =
        "Rectangle-FQA " + classname;
    } else {
      document.getElementById("Ans" + Index).className =
        "Nulla-vitae-elit-lib display-none";
      document.getElementById("Ques" + Index).className =
        "Path-FQA Path-FQA-right";
      document.getElementById("maindiv" + Index).className =
        "Rectangle-FQA " + classname;
    }
  };
  const buildAnswer = () => {
    var Highlight = [
      "In Phase IV trials",
      "In Phase I trials",
      "In Phase II trials",
      "In Phase III trials",
    ];
    Answer = Answer.slice().sort((a, b) => a.position - b.position);
    return Answer.map((Answerdata, index) => {
      let COntainsTerms = false;
      let startindex = 0;
      let TextHighlight = "";
      if (Index === 12) {
        Highlight.map((Highlightdata, index) => {
          if (COntainsTerms === false) {
            COntainsTerms = Answerdata.text.includes(Highlightdata);
          }
          if (COntainsTerms === true && TextHighlight === "") {
            startindex = Answerdata.text.lastIndexOf(Highlightdata);
            TextHighlight = Highlightdata;
          }
          return 0;
        });
      }
      return (
        <React.Fragment key={"FAQ" + index + Index}>
          {Answerdata.type === "paragraph" ? (
            <div className="BreakLine-Div">{Answerdata.text}</div>
          ) : Answerdata.type === "list_header" ? (
            <ul className="Answer-ul">{Answerdata.text}</ul>
          ) : Answerdata.type === "list_item" ? (
            Index === 12 && COntainsTerms === true ? (
              <li className="Answer-li ">
                <span className="TextHightLight">
                  {Answerdata.text.slice(
                    startindex,
                    startindex + TextHighlight.length
                  )}
                </span>
                {Answerdata.text.slice(startindex + TextHighlight.length)}
              </li>
            ) : (
              <li>{Answerdata.text}</li>
            )
          ) : null}
        </React.Fragment>
      );
    });
  };
  return (
    <React.Fragment>
      <div className={"Rectangle-FQA " + classname} id={"maindiv" + Index}>
        <div className="Section-Title-FQA" onClick={ShowAnswer}>
          {Question}
        </div>
        <div
          className="Path-FQA Path-FQA-right"
          id={"Ques" + Index}
          onClick={ShowAnswer}
        ></div>
        <div className="Nulla-vitae-elit-lib display-none" id={"Ans" + Index}>
          {buildAnswer()}
        </div>
      </div>
    </React.Fragment>
  );
};
export default FQAQuestion;
