import React from "react";
import Error from "../shared/error";

const NotFound = () => {
  window.gtag("event", "page_view", {
    page_title: "404",
    page_path: window.location.pathname,
  });
  return <Error error={{ errorMessage: "404" }} />;
};

export default NotFound;
