import React, { useState } from "react";
import ReactModal from "react-modal";
import CloseModalImage from "../../assets/icons/ic_close torch.png";
import "./modal.less";

export const Modal = ({
  isOpen,
  onRequestClose,
  className = "csl-modal",
  overlayClassName = "csl-modal-overlay",
  children,
}) => {
  ReactModal.setAppElement("body");

  return (
    <ReactModal
      isOpen={isOpen}
      className={className}
      overlayClassName={overlayClassName}
      onRequestClose={onRequestClose}
    >
      <div className="csl-modal-close-bar">
        <img onClick={onRequestClose} src={CloseModalImage} alt="Close Modal" />
      </div>
      <div className="csl-modal-content">{children}</div>
    </ReactModal>
  );
};

export const ModalVideo = ({
  link,
  isOpen,
  onRequestClose,
  className = "csl-modal",
  overlayClassName = "csl-modal-overlay",
}) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        className={className}
        overlayClassName={overlayClassName}
        onRequestClose={onRequestClose}
      >
        <iframe
          className="csl-modal-video"
          title="CSL"
          src={link}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal>
    </React.Fragment>
  );
};

export const ModalLink = ({ children, link, className = "primary" }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <button onClick={() => setIsOpen(true)} className={className}>
        {children}
      </button>
      <ModalVideo
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        link={link}
      />
    </React.Fragment>
  );
};
