import React, { useState } from "react";
import { Link } from "react-router-dom";
import ImageButton from "../shared/imageButton";
import GoogleMapReact from "google-map-react";
import { Modal } from "../shared/modal";
import "./results.less";

import FilterIcon from "../../assets/icons/ic_filter.png";
import MaleSelectedIcon from "../../assets/icons/ic_male white.png";
import MaleIcon from "../../assets/icons/ic_male.png";
import FemaleSelectedIcon from "../../assets/icons/ic_female white.png";
import FemaleIcon from "../../assets/icons/ic_female.png";
import MapSelectedIcon from "../../assets/icons/ic_map white.png";
import MapIcon from "../../assets/icons/ic_map default.png";
import ListSelectedIcon from "../../assets/icons/ic_list white.png";
import ListIcon from "../../assets/icons/ic_list.png";
import PinIcon from "../../assets/icons/ic_pin.png";

const FilterButton = ({
  selected = false,
  text = null,
  image = null,
  activeImage = null,
  showText = "Show",
  hideText = "Hide",
}) => {
  const [isActive, setIsActive] = useState(selected);

  return (
    <div className="participate-filter-button-wrap">
      <button
        onClick={() => setIsActive(!isActive)}
        className={`${isActive ? "active" : ""}`}
      >
        {image !== null && activeImage !== null && (
          <div
            style={{
              backgroundImage: `url('${
                isActive === true ? activeImage : image
              }')`,
            }}
          />
        )}
        {text !== null ? <span>{text}</span> : ""}
      </button>
      <div className="participate-filter-button-show-hide">
        {isActive === true ? showText : hideText}
      </div>
    </div>
  );
};

const SearchResults = ({ location }) => {
  const [showFilter, setShowFilter] = useState(false);
  let params = new URLSearchParams(location.search);

  const mapProps = {
    center: {
      lat: 40.46032,
      lng: -75.40962,
    },
    zoom: 1,
  };

  //todo: mock results for now
  let results = [
    {
      title: "Study Title 1",
      description: "Study Summary - clamp at 150 characters…",
      study: "Study#",
      phase: "Phase",
      type: "Type",
      filters: [
        "X Sites",
        "X Participants",
        "Status",
        "Parients Age Group",
        "Male",
        "Female",
      ],
    },
    {
      title: "Study Title 2",
      description: "Study Summary - clamp at 150 characters…",
      study: "Study#",
      phase: "Phase",
      type: "Type",
      filters: [
        "X Sites",
        "X Participants",
        "Status",
        "Parients Age Group",
        "Male",
        "Female",
      ],
    },
  ];

  return (
    <div className="content-edge content-ceiling">
      <ImageButton type="left" to="/participate" text="Back" />
      <h1 className="participate-results-header">
        {results.length} Clinical Trials for {params.get("search")}
      </h1>
      <button
        className="participate-filter"
        onClick={() => setShowFilter(true)}
        style={{ backgroundImage: `url('${FilterIcon}')` }}
      >
        Filters
      </button>
      <Modal
        isOpen={showFilter}
        onRequestClose={() => setShowFilter(false)}
        selected={false}
      >
        <div className="participate-filter-box">
          <div className="participate-filter-head">View</div>
          <div className="participate-filters">
            <FilterButton image={MapIcon} activeImage={MapSelectedIcon} />
            <FilterButton image={ListIcon} activeImage={ListSelectedIcon} />
          </div>
          <div className="participate-filter-head">Recruiting</div>
          <div className="participate-filters">
            <FilterButton text="Now" />
            <FilterButton text="Upcoming" />
          </div>
          <div className="participate-filter-head">Patients</div>
          <div className="participate-filters">
            <FilterButton text="Adult" />
            <FilterButton text="Pediatric" />
            <FilterButton image={MaleIcon} activeImage={MaleSelectedIcon} />
            <FilterButton image={FemaleIcon} activeImage={FemaleSelectedIcon} />
          </div>
          <div className="participate-filter-head">Location</div>
          <div className="participate-filters">
            <input
              type="text"
              style={{ backgroundImage: `url('${PinIcon}')` }}
              className="participate-loc-filter"
              placeholder="Filter by Zip Code, City, or State"
            />
          </div>
          <hr />
          <div className="participate-filter-update">
            <button className="plain">UPDATE</button>
          </div>
        </div>
      </Modal>
      <div className="participate-results-wrap">
        <div className="participate-results">
          {results.map((item, i) => {
            return (
              <Link
                to="/participate/details"
                className="participate-result-item"
                key={`participate-result-item-${i}`}
              >
                <div className="sub-title">
                  {item.study}&nbsp;|&nbsp;{item.phase}&nbsp;|&nbsp;{item.type}
                </div>
                <h3 className="title">{item.title}</h3>
                <p className="desc">{item.description}</p>
                <div className="filter-area">
                  {item.filters.map((filter, f) => {
                    return (
                      <div className="filter" key={`filter-item-${i}-${f}`}>
                        {filter}
                      </div>
                    );
                  })}
                </div>
              </Link>
            );
          })}
        </div>
        <div className="participate-search-map-wrap">
          <div className="participate-search-map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
              }}
              defaultCenter={mapProps.center}
              defaultZoom={mapProps.zoom}
            ></GoogleMapReact>
          </div>
          <div className="participate-search-map-loc">
            Trials Site Locations
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
