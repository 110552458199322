import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Toggle from "react-toggle";
import { IS_USER_PROFILE_EDITED } from "../../constants";
import { Input, Phone, Checkbox } from "../shared/formFields";
import { gql, useMutation } from "@apollo/client";
import { client as apiClient } from "../../api/apiClient";
import ButtonLoader from "../shared/buttonLoader";
import CheckMultiSession from "./checkMultiSession";
import "./settings.less";
export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword {
    cpex {
      updatePassword {
        data {
          success
        }
      }
    }
  }
`;
const ERROR_FIELDS = gql`
  {
    code
    message
    data
  }
`;
export const UPDATE_SETTINGS = gql`
  mutation UpdateSettings(
    $first_name: String!,
    $last_name: String!,
    $phone: String!,
    $contact_preference: Number!,
    $notifications_ct_info: Boolean!,
    $notifications_marketing: Boolean!,
    $notifications_news: Boolean!
  ) {
    cpex {
      updateSettings(
        first_name: $first_name,
        last_name: $last_name,
        phone: $phone,
        contact_preference: $contact_preference,
        notifications_ct_info: $notifications_ct_info,
        notifications_marketing: $notifications_marketing,
        notifications_news: $notifications_news
      ) {
        data {
          success
        }
        error ${ERROR_FIELDS}
      }
    }
  }
`;
const Settings = ({ cslData }) => {
  const [firstName, setFirstName] = useState(
    cslData !== undefined ? cslData.cpex.getStudy.data.settings.first_name : ""
  );
  const [firstNameValid, setFirstNameValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [lastName, setLastName] = useState(
    cslData !== undefined ? cslData.cpex.getStudy.data.settings.last_name : ""
  );
  const [lastNameValid, setLastNameValid] = useState(true);
  const [phone, setPhone] = useState(
    cslData !== undefined ? cslData.cpex.getStudy.data.settings.phone : ""
  );
  const [phoneValid, setPhoneValid] = useState(true);
  const [prefs, setPrefs] = useState(
    cslData !== undefined
      ? cslData.cpex.getStudy.data.settings.contact_preference
      : 0
  );
  const [nopref, setNoPref] = useState(
    cslData !== undefined
      ? cslData.cpex.getStudy.data.settings.contact_preference === 1
        ? true
        : false
      : false
  );
  const [phonePref, setPhonePref] = useState(
    cslData !== undefined
      ? cslData.cpex.getStudy.data.settings.contact_preference === 2
        ? true
        : false
      : false
  );
  const [emailPref, setEmailPref] = useState(
    cslData !== undefined
      ? cslData.cpex.getStudy.data.settings.contact_preference === 3
        ? true
        : false
      : false
  );
  const [unsubUpdate, setunsubUpdate] = useState(
    cslData !== undefined
      ? cslData.cpex.getStudy.data.settings.notifications_ct_info === false &&
        cslData.cpex.getStudy.data.settings.notifications_marketing === false &&
        cslData.cpex.getStudy.data.settings.notifications_news === false
        ? true
        : false
      : false
  );
  const [ctInfo, setctInfo] = useState(
    cslData !== undefined
      ? cslData.cpex.getStudy.data.settings.notifications_ct_info === true
        ? true
        : false
      : false
  );
  const [marketing, setmarketing] = useState(
    cslData !== undefined
      ? cslData.cpex.getStudy.data.settings.notifications_marketing === true
        ? true
        : false
      : false
  );
  const [news, setnews] = useState(
    cslData !== undefined
      ? cslData.cpex.getStudy.data.settings.notifications_news === true
        ? true
        : false
      : false
  );
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoggingInSave, setisLoggingInSave] = useState(false);
  const [isUpdate, setisUpdate] = useState(false);
  const history = useHistory();
  const onNoPrefChange = (e) => {
    setNoPref(true);
    setPhonePref(false);
    setEmailPref(false);
    setPrefs(1);
  };

  const onPhonePrefChange = (e) => {
    setNoPref(false);
    setPhonePref(true);
    setEmailPref(false);
    setPrefs(2);
  };

  const onEmailPrefChange = (e) => {
    setNoPref(false);
    setPhonePref(false);
    setEmailPref(true);
    setPrefs(3);
  };

  const onFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const onFirstNameValid = (e) => {
    setFirstNameValid(e);
  };

  const onLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const onLastNameValid = (e) => {
    setLastNameValid(e);
  };

  const onPhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const onPhoneValid = (e) => {
    setPhoneValid(e);
  };

  const onUnsubChange = (e) => {
    setunsubUpdate(!unsubUpdate);
    setctInfo(false);
    setmarketing(false);
    setnews(false);
  };

  const onSaveChanges = () => {
    if (firstName !== "" && lastName !== "" && phone !== "(___) ___-____") {
      setErrorMessage("");
      setisLoggingInSave(true);
      updatesettings();
    }
  };

  const onCancel = () => {
    history.push("/trial");
  };
  const onCommunicationsChange = (type, e, Option1, Option2) => {
    if (type === 1) {
      setctInfo(e.target.checked);
    } else if (type === 2) {
      setmarketing(e.target.checked);
    } else {
      setnews(e.target.checked);
    }
    if (e.target.checked !== true && Option1 !== true && Option2 !== true) {
      setunsubUpdate(true);
    } else {
      setunsubUpdate(false);
    }
  };
  window.gtag("event", "page_view", {
    page_title: "Settings",
    page_path: window.location.pathname,
  });
  const updatePassword = () => {
    createPassword();
    setisUpdate(true);
    setIsLoggingIn(true);
  };
  const [createPassword] = useMutation(UPDATE_PASSWORD, {
    client: apiClient,
    variables: {
      email: "jsmith@gmail.com",
    },
    onCompleted: (data) => {
      setisUpdate(false);
      setIsLoggingIn(false);
      if (data.cpex.updatePassword.data !== null) {
        if (data.cpex.updatePassword.data.success !== undefined) {
          if (data.cpex.updatePassword.data.success) {
          }
          return;
        }
      }
    },
  });
  const [updatesettings] = useMutation(UPDATE_SETTINGS, {
    client: apiClient,
    variables: {
      first_name: firstName,
      last_name: lastName,
      phone: phone,
      contact_preference: prefs,
      notifications_ct_info: ctInfo,
      notifications_marketing: marketing,
      notifications_news: news,
    },
    onCompleted: (data) => {
      setisLoggingInSave(false);
      if (
        data.cpex.updateSettings.data !== null &&
        data.cpex.updateSettings.data.success === true
      ) {
        setErrorMessage("Profile updated successfully");
        localStorage.setItem(IS_USER_PROFILE_EDITED, 1);
        //history.push("/trial/settings");
      } else {
        setErrorMessage("Profile update Failed");
        return "";
      }
    },
  });

  return (
    <React.Fragment>
      <h2>Profile</h2>
      <h3 className="profile-subhead">Patient Identification&nbsp;</h3>
      <div className="patient-field">
        <span>Patient Type</span>
        <p>Randomized</p>
      </div>
      <div className="email-field">
        <span>Email Address&nbsp; </span>
        <p>
          {cslData !== undefined
            ? cslData.cpex.getStudy.data.settings.email
            : ""}
        </p>
      </div>
      <div className="name-fields">
        <Input
          label="First Name"
          value={firstName}
          onChange={onFirstNameChange}
          onValidate={onFirstNameValid}
        />
        <Input
          label="Last Name"
          value={lastName}
          onChange={onLastNameChange}
          onValidate={onLastNameValid}
        />
      </div>
      <div className="pref-area">
        <div className="pref-fields">
          <Phone
            label="Phone Number"
            value={phone}
            onChange={onPhoneChange}
            onValidate={onPhoneValid}
          />

          <div className="section-header">Contact Preferences</div>
          <ul className="checkbox-list">
            <li>
              <Checkbox checked={nopref} onChange={onNoPrefChange}>
                No Preference
              </Checkbox>
            </li>
            <li>
              <Checkbox checked={phonePref} onChange={onPhonePrefChange}>
                Phone
              </Checkbox>
            </li>
            <li>
              <Checkbox checked={emailPref} onChange={onEmailPrefChange}>
                Email
              </Checkbox>
            </li>
          </ul>
          <div className="margin-top-24">Password</div>
          <p>Last update MM/DD/YYYY</p>
          <ButtonLoader
            className="tertiary margin-top-8 profile-upBtn"
            onClick={() => updatePassword()}
            loading={isLoggingIn}
            disabled={isUpdate}
          >
            UPDATE PASSWORD
          </ButtonLoader>
        </div>
        <div className="pref-fields filler"></div>
      </div>
      <hr className="margin-top-32 margin-bottom-32" />
      <h2>
        Email Notifications and <span className="no-wrap">Updates&nbsp;</span>
      </h2>
      <Checkbox
        className="unsub-comm"
        checked={unsubUpdate}
        onChange={() => onUnsubChange()}
      >
        Unsubscribe from all Marketing Communications
      </Checkbox>
      <div className="opt">
        <div>Clinical Trail Information</div>
        <Toggle
          checked={ctInfo}
          onChange={(e) => onCommunicationsChange(1, e, marketing, news)}
        />
      </div>
      <div className="opt">
        <div>Marketing</div>
        <Toggle
          checked={marketing}
          onChange={(e) => onCommunicationsChange(2, e, ctInfo, news)}
        />
      </div>
      <div className="opt">
        <div>News and Press Releases</div>
        <Toggle
          checked={news}
          onChange={(e) => onCommunicationsChange(3, e, marketing, ctInfo)}
        />
      </div>
      <hr className="margin-top-32 margin-bottom-32" />
      <h2>Your Privacy Rights</h2>
      <p className="privacy-rights">
        You have several rights related to the personal information we process
        about you. To understand these rights,&nbsp;
        <Link className="link" to="/privacy">
          click here
        </Link>
        . Please refer to the&nbsp;
        <Link className="link" to="/privacy">
          Privacy Notice
        </Link>
        &nbsp;for more information, or&nbsp;
        <a className="link" href="mailto:epexsupport@cslbehring.com">
          Contact Support
        </a>
        &nbsp;for assistance.
      </p>
      <div className="sign-in-options">
        <label className="login-error-message">{errorMessage}</label>
      </div>
      <div className="profile-save-buttons">
        <button className="secondary" onClick={() => onCancel()}>
          Cancel Changes
        </button>

        <ButtonLoader
          className="primary"
          onClick={() => onSaveChanges()}
          loading={isLoggingInSave}
          disabled={
            firstName !== "" && lastName !== "" && phone !== "(___) ___-____"
              ? false
              : true
          }
        >
          Save Changes
        </ButtonLoader>
      </div>
      <CheckMultiSession></CheckMultiSession>
    </React.Fragment>
  );
};

export default Settings;
