import React from "react";
import { Link } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import ImageButton from "../shared/imageButton";
import ScrollToTop from "../shared/scrollToTop";

const Confirm = () => {
  const mapProps = {
    center: {
      lat: 40.46032,
      lng: -75.40962,
    },
    zoom: 1,
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="content-edge content-ceiling">
        <ImageButton type="left" to="/participate/create-profile" text="Back" />
        <h1 className="prescreen-head">R19-D Systemic Sclerosis</h1>
        <div className="prescreen-subhead">
          NCT02172950 | Phase 1 | Interventional
        </div>
        <div className="screening-wrap">
          <div className="prescreen-head-box shadow-box-small">
            <div className="prescreen-head-box-details">
              <h3>Research Solutions of Philadelphia</h3>
              <div>Philadelphia, PA USA 85340</div>
            </div>
            <div className="prescreen-small-map">
              <GoogleMapReact
                draggable={false}
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                }}
                defaultCenter={mapProps.center}
                defaultZoom={mapProps.zoom}
              ></GoogleMapReact>
            </div>
          </div>

          <h2 className="prescreen-profile-head">
            Your information has been sent to the trail site.
          </h2>
          <h3 className="prescreen-profile-subhead">What happens next</h3>
          <ul className="prescreen-nextsteps">
            <li>
              A member of study staff will contact you by phone or email to
              discuss next steps and determine if you qualify
            </li>
            <li>
              If you qualify and want to take part a trial administrator will
              answer any questions you may have
            </li>
            <li>
              If you do not qualify, you may search&nbsp;
              <a
                href="https://clinicaltrials.gov"
                className="link"
                target="_blank"
                rel="noreferrer"
              >
                ClinicalTrials.gov
              </a>
              &nbsp;to find a trial that may match
            </li>
            <li>
              Learn more about&nbsp;
              <Link className="link" to="/learn-guide/how-clinical-trials-work">
                How Clinicla Trials Work
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Confirm;
