import React from "react";
import { Link } from "react-router-dom";
import "./index.less";
import { gql, useQuery } from "@apollo/client";
import Spinner from "../shared/spinner";
export const GET_CONTACT_TECHNICAL_SUPPORT = gql`
  query GetContactTechnicalSupport($pageId: String) {
    getContactTechnicalSupport(where: { pageId: $pageId }) {
      data {
        crumbs1
        crumbs2
        pageTitle
        content {
          type
          text
          content {
            type
            text
            position
          }
          position
        }
      }
    }
  }
`;
const TechnicalSupport = () => {
  const { data, loading, error } = useQuery(GET_CONTACT_TECHNICAL_SUPPORT, {
    variables: { pageId: "cpex_contact_technical_support" },
    errorPolicy: "all",
  });
  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;

  window.gtag("event", "page_view", {
    page_title: "Technical Support",
    page_path: window.location.pathname,
  });

  return (
    <React.Fragment>
      <div className="Guide-breabrumb BackButton-Margintop CSL-TechSup-BreadCrumb">
        <Link to={"/"} className="CSL-breadCrumb">
          {data.getContactTechnicalSupport.data.crumbs1}
        </Link>
        <span className="Path-breadCrumb">{">"}</span>
        <span className="What-is-a-Clinical-T-breadCrumb">
          {data.getContactTechnicalSupport.data.crumbs2}
        </span>
      </div>
      <div className="techSupport-head-box">
        <div className="CSL-TechSup-Title">
          {data.getContactTechnicalSupport.data.pageTitle}
        </div>

        <p className="CSL-TechSup-p">
          {data.getContactTechnicalSupport.data.content[1].text}
        </p>
        <p className="CSL-TechSup-Margin">
          {data.getContactTechnicalSupport.data.content[2].text}
        </p>

        <div className="CSL-TechSup-TimeDiv">
          <p>
            {data.getContactTechnicalSupport.data.content[3].content[2].text}
          </p>
          <p>
            {data.getContactTechnicalSupport.data.content[3].content[0].text}
          </p>
          <p>
            {data.getContactTechnicalSupport.data.content[3].content[1].text}
          </p>
        </div>
        <p className="CSL-TechSup-Margin">
          {data.getContactTechnicalSupport.data.content[0].text}
        </p>
      </div>
    </React.Fragment>
  );
};

export default TechnicalSupport;
