import React, { useState } from "react";
import "./expandList.less";

import DownArrow from "../../assets/icons/ic_caret-down.png";
import RightArrow from "../../assets/icons/ic_caret-right.png";

const ExpandedItem = ({ item }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="expanded-list-item">
      <div
        className="expanded-item-header"
        style={{
          backgroundImage: `url('${isExpanded ? DownArrow : RightArrow}')`,
        }}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <h4>{item.title}</h4>
      </div>
      <div className={`expanded-item-content ${isExpanded ? "active" : ""}`}>
        {item.content}
      </div>
    </div>
  );
};

const ExpandList = ({ items = [] }) => {
  return (
    <div className="expanded-list">
      {items.map((item, i) => {
        return <ExpandedItem item={item} key={`expanded-item-${i}`} />;
      })}
    </div>
  );
};

export default ExpandList;
