import React from "react";
import { Route, Switch } from "react-router-dom";
import "./index.less";
import ScrollToTop from "../shared/scrollToTop";
import Sidebar from "./sidebar";
import Home from "../home";
import Learn from "../learn";
import LearnGuide from "../learn-guide";
import LearnHowClinicalTrialWork from "../learn-guide/howClinicalTrialWork";
import Glossary from "../learn-guide/glossary";
import LeranPatientSupport from "../learn-guide/patientSupport";
import LearnGuideFQA from "../learn-guide/guideFQA";
import LearnCondition from "../learnCondition";
import ResearchDevelopment from "../learn-R&D";
import ParticipateRouter from "../participate";
import FeaturedArticles from "../learn-R&D-FA/featuredArticles";
import Signin from "../signin";
import Terms from "../terms";
import Privacy from "../privacy";
import CookiePolicy from "../cookiePolicy";
import TechnicalSupport from "../technicalsupport";
import Trial from "../trial";
import TrialAppointment from "../trial/appointment";
import { ProtectedRoute } from "../shared/auth";
import Header from "./header";
import Footer from "./footer";
import ForgotPassword from "../signin/forgotPassword";
import NotFound from "../notFound";
import ErrorBoundary from "./errorBoundary";
import QuickStart from "../participate/quickStart";
import { Offline, Online } from "react-detect-offline";
const App = () => {
  return (
    <React.Fragment>
      {/*</Online>*/}
      <React.Fragment>
        <ScrollToTop />
        <div id="outer-container">
          <Sidebar
            pageWrapId={"main-content"}
            outerContainerId={"outer-container"}
          />
          <ErrorBoundary>
            <div id="main-content">
              <Online polling={{ url: window.location.href }}>
                <Switch>
                  <Route exact path="(/|/learn|/participate)">
                    <Header />
                  </Route>
                  <Route path="(/participate/quick-start)">
                    <Header isLight={false} showMenu={false} />
                  </Route>

                  <Route path="(/signin)">
                    <Header isLight={true} showMenu={false} isSignIn={true} />
                  </Route>
                  <Route path="(/signup)">
                    <Header isLight={true} showMenu={false} isSignUp={true} />
                  </Route>
                  <Route path="(/invitation)">
                    <Header isLight={false} showMenu={false} isSignUp={true} />
                  </Route>
                  <Route path="(/forgotpassword)">
                    <Header isLight={true} showMenu={false} />
                  </Route>
                  <Route path="*">
                    <Header isLight="true" />
                  </Route>
                </Switch>

                <div id="page-wrap">
                  <Switch>
                    <Route exact path="/" component={Home}></Route>
                    <Route exact path="/learn" component={Learn}></Route>
                    <Route
                      exact
                      path="/learn-guide"
                      component={LearnGuide}
                    ></Route>
                    <Route
                      exact
                      path="/learn-guide/patient-support"
                      component={LeranPatientSupport}
                    ></Route>
                    <Route
                      exact
                      path="/learn-guide/how-clinical-trials-work"
                      component={LearnHowClinicalTrialWork}
                    ></Route>
                    <Route
                      exact
                      path="/learn-guide/glossary"
                      component={Glossary}
                    ></Route>
                    <Route
                      exact
                      path="/learn-guide/faq"
                      component={LearnGuideFQA}
                    ></Route>
                    <Route
                      exact
                      path="/learn-research-development/pipeline"
                      component={ResearchDevelopment}
                    ></Route>
                    <Route
                      exact
                      path="/learn-condition"
                      component={LearnCondition}
                    ></Route>
                    <Route
                      exact
                      path="/learn-research-development/featured-articles"
                      component={FeaturedArticles}
                    ></Route>

                    <Route
                      path="/participate"
                      component={ParticipateRouter}
                    ></Route>
                    <ProtectedRoute
                      exact
                      path="(/signin|/signup)"
                      component={Signin}
                    ></ProtectedRoute>
                    <ProtectedRoute
                      exact
                      path="/forgotpassword"
                      component={ForgotPassword}
                    ></ProtectedRoute>
                    <ProtectedRoute
                      path="/trial/appointment"
                      component={TrialAppointment}
                    ></ProtectedRoute>
                    <ProtectedRoute
                      path="/trial"
                      component={Trial}
                    ></ProtectedRoute>
                    <Route exact path="/terms" component={Terms}></Route>
                    <Route
                      exact
                      path="/cookie-policy"
                      component={CookiePolicy}
                    ></Route>

                    <ProtectedRoute
                      exact
                      path="/invitation"
                      component={QuickStart}
                    ></ProtectedRoute>
                    <Route
                      exact
                      path="/technical-support"
                      component={TechnicalSupport}
                    ></Route>
                    <Route exact path="/privacy" component={Privacy}></Route>
                    <Route component={NotFound} />
                  </Switch>
                </div>
              </Online>
              <Offline polling={{ url: window.location.href }}>
                <Header />
                <div className="Network-Issue Network-Issue-M1">
                  Oops! Something went wrong!
                </div>
              </Offline>
            </div>
          </ErrorBoundary>
          <Footer />
        </div>
      </React.Fragment>
      {/*</Online>*/}
    </React.Fragment>
  );
};

export default App;
