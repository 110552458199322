export const whatIsASickleCell = () => {
    return (<div class="Praesent-commodo-cur COndition1-Answer">
    <p>
    Sickle cell disease (SCD) is an inherited blood disorder that affects red blood cells. 
    SCD affects millions of people throughout the world and is particularly common among those whose ancestors came from sub-Saharan Africa; 
    Spanish-speaking regions in the Western Hemisphere (South America, the Caribbean, and Central America); Saudi Arabia; India; 
    and Mediterranean countries such as Turkey, Greece, and Italy. A child of parents who each have one affected gene has a 25 percent 
    chance of inheriting the disease. It occurs in both men and women. Hemoglobin is the main substance of the red blood cell. 
    It helps red blood cells carry oxygen from the air in our lungs to all parts of the body. Normal red blood cells contain hemoglobin A. 
    Normal red blood cells that contain hemoglobin A are soft and round and can squeeze through tiny blood tubes (vessels). 
    Normally, red blood cells live for about 120 days before new ones replace them. 
    Red blood cells in people with SCD change into the shape of a banana or a sickle-shaped (crescent-shaped), 
    the curved farming tool from which the disease takes its name.
    </p>              
  </div>)    
}

export const sickleCellSymptoms = () => {
  return (  <div class="Praesent-commodo-cur COndition1-Answer">
  <p>
    People who have sickle cell disease can experience the following
    symptoms:
  </p>
  <ul>
    <li>
      <p>
        <b>Anemia:</b> Sickle cells are fragile and can break apart
        easily. Additionally, they have a shorter lifespan than
        regular red blood cells. As sickle cells die or break apart,
        they can leave sickle cell sufferers with a shortage of red
        blood cells (anemia). Without enough red blood cells, the
        body can't get enough oxygen, causing fatigue.
      </p>
    </li>
    <li>
      <p>
        <b>Swelling:</b> Sickle-shaped red blood cells can create
        blockages that keep blood flowing freely to the hands and
        feet.
      </p>
    </li>
    <li>
      <p>
        <b>Infections:</b> Sickle cells can damage your spleen,
        leaving you more vulnerable to infections. Children with
        sickle cell anemia are often given vaccinations and
        antibiotics to prevent infections.
      </p>
    </li>
    <li>
      <p>
        <b>Impaired Vision:</b> Tiny blood vessels that supply your
        eyes can become plugged with sickle cells. This can damage
        the retina — the portion of the eye that processes visual
        images — and lead to vision problems.
      </p>
    </li>
    <li>
      <p>
        <b>Delayed growth or puberty:</b> A shortage of healthy red
        blood cells can deprive infants and children of the oxygen
        and nutrients they need for growth and development. Sickle
        cell disease can slow growth in infants and children and
        delay puberty in teenagers.
      </p>
    </li>
    <li>
      <p>
        <b>Episodes of pain:</b> Periodic episodes of pain, called
        Vaso-occlusive Crisis (VOC), are a major symptom of sickle
        cell anemia. Pain develops when sickle-shaped red blood
        cells create blockages that limit or stop blood flow through
        tiny blood vessels to your chest, abdomen and joints. Pain
        can also occur in your bones. The pain varies in intensity
        and can last for a few hours to a few weeks. Some people
        have only a few pain crises a year. Others have a dozen or
        more pain crises a year. A severe pain crisis requires a
        hospital stay. Some adolescents and adults with sickle cell
        anemia also have chronic pain, which can result from bone
        and joint damage, ulcers, and other causes.
      </p>
    </li>
  </ul>
  <p>
    <br></br>
    Because sickle cells have the potential to create blockages
    within blood vessels, people suffering from sickle cell disease
    are at risk for developing serious complications, such as
    stroke, pulmonary hypertension, acute chest syndrome, leg
    ulcers, gall stones, blindness and pregnancy complications.
  </p>
</div>)    
}

export const sickleCellTreatments = () => {
  return (<div class="Praesent-commodo-cur COndition1-Answer">
  <p>
  There is currently no universal cure for sickle cell disease; 
  however, recent advances in medicine -- including management of vaso-occlusive crises 
  and potential gene therapy treatments -- have provided sickle cell disease patients with reasons for hope.
  </p>              
</div>)    
}