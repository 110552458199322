import React from "react";
import "./buttonLoader.less";

const ButtonLoader = ({
  className = "primary",
  onClick = () => {},
  disabled = false,
  loading = false,
  children,
}) => {
  return (
    <button
      onClick={onClick}
      className={`button-loader ${className} ${loading ? "is-loading" : ""}`}
      disabled={loading === true || disabled === true}
    >
      {loading && (
        <div className="button-loading-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}

      {!loading && <React.Fragment>{children}</React.Fragment>}
    </button>
  );
};

export default ButtonLoader;
