import React, { useState } from "react";
import { Input, Email, Phone, Checkbox, Radio } from "../shared/formFields";
import { gql, useQuery, useMutation } from "@apollo/client";
import { client as apiClient } from "../../api/apiClient";
import { IS_USER_EMAILID } from "../../constants";
import Popover from "../shared/popover";
import ResponsiveBg from "../shared/responsiveBg";
import "./quickStart.less";
import { useHistory } from "react-router-dom";
import DownArrow from "../../assets/icons/ic_caret-down.png";
import Pin from "../../assets/icons/ic_pin.png";
import heroL_placeholder from "../../assets/participate-hero-l.jpg";
import heroM_placeholder from "../../assets/participate-hero-m.jpg";
import heroS_placeholder from "../../assets/participate-hero-s.jpg";
import Terms from "../terms";

export const SIGNUP_DATA = gql`
  query GetSignupData {
    cpex {
      getSignupData {
        data {
          condition
          nct_id
          current_phase
          study_type
          locations {
            state
            sites {
              id
              name
            }
          }
        }
        error {
          code
          message
          data
        }
      }
    }
  }
`;
export const SAVE_PROFILE = gql`
  mutation SaveProfile(
    $siteId: String!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $contactPreference: Number!
    $timeToReach: [Number]
    $acceptedDisclaimer: Boolean!
  ) {
    cpex {
      saveProfile(
        siteId: $siteId
        firstName: $firstName
        lastName: $lastName
        phone: $phone
        contactPreference: $contactPreference
        timeToReach: $timeToReach
        acceptedDisclaimer: $acceptedDisclaimer
      ) {
        data {
          success
        }
        error {
          code
          message
          data
        }
      }
    }
  }
`;
const QuickStart = () => {
  const history = useHistory();
  const [location, setLocation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [site, setSite] = useState("");
  const [contactPref, setContactPref] = useState("");
  const [bestTime, setBestTime] = useState([]);
  const [agreement, setAgreement] = useState(false);
  const { data } = useQuery(SIGNUP_DATA, {
    client: apiClient,
  });
  const filteredSites =
    data !== undefined
      ? data.cpex.getSignupData.data.locations.filter(
          (s) => s.state === location
        )
      : [];

  const onSendInfo = () => {
    saveProfile();
  };
  const [saveProfile] = useMutation(SAVE_PROFILE, {
    client: apiClient,
    variables: {
      siteId: site,
      firstName: firstName,
      lastName: lastName,
      phone: phoneNumber,
      contactPreference: contactPref,
      timeToReach: bestTime,
      acceptedDisclaimer: agreement,
    },
    onCompleted: (data) => {
      if (data.cpex.saveProfile.data.success !== false) {
        history.push("/signin");
        return;
      } else {
        return;
      }
    },
  });
  const onBestTimeChange = (timeOfDay) => {
    const newBestTime = [...bestTime];

    let index = newBestTime.indexOf(timeOfDay);
    if (index === -1) {
      newBestTime.push(timeOfDay);
    } else {
      newBestTime.splice(index, 1);
    }

    setBestTime(newBestTime);
  };
  return (
    <React.Fragment>
      <ResponsiveBg
        className="participate-hero"
        largeBg={heroL_placeholder}
        mediumBg={heroM_placeholder}
        smallBg={heroS_placeholder}
      />
      <div className="content-edge participate-wrap">
        <div className="shadow-box participate-head-box">
          <h2 className="participate-head-box-text">
            Participate in a Clinical Trial
          </h2>
        </div>
        <h1 className="prescreen-head">
          {data !== undefined ? data.cpex.getSignupData.data.condition : ""}
        </h1>
        <div className="prescreen-subhead">
          {data !== undefined ? data.cpex.getSignupData.data.nct_id : ""} |{" "}
          {data !== undefined ? data.cpex.getSignupData.data.current_phase : ""}{" "}
          | {data !== undefined ? data.cpex.getSignupData.data.study_type : ""}
        </div>
        <div className="screening-wrap">
          <h3 className="quickstart-location-head">Step 1: Select Location</h3>
          <div className="prescreen-create-profile-req">
            <span className="torch bold">*</span>&nbsp;All fields are required
            to procede
          </div>
          <select
            className="quickstart-location-select"
            style={{ backgroundImage: `url('${Pin}'), url('${DownArrow}')` }}
            onChange={(e) => {
              setSite("");
              setLocation(e.target.value);
            }}
          >
            <option>Select a Location</option>
            {data !== undefined
              ? data.cpex.getSignupData.data.locations
                  .slice()
                  .sort((a, b) => (a.state > b.state ? 1 : -1))
                  .map((item, i) => {
                    return (
                      <option key={i} value={item.state}>
                        {item.state}
                      </option>
                    );
                  })
              : null}
          </select>

          <div
            className="quickstart-location-results"
            style={{
              display: filteredSites.length === 0 ? "none" : "block",
            }}
          >
            <div className="location-results-header">
              Choose a Site&nbsp;<span className="torch bold">*</span>
            </div>
            {filteredSites.length !== 0
              ? filteredSites[0].sites !== undefined
                ? filteredSites[0].sites.map((l, i) => {
                    return (
                      <div
                        className="quickstart-location-item"
                        key={`location-item-${i}`}
                      >
                        <Radio
                          checked={l.id === site}
                          onChange={() => setSite(l.id)}
                        >
                          {l.name}
                        </Radio>
                      </div>
                    );
                  })
                : null
              : null}
          </div>

          <h3 className="quickstart-create-profile-head">
            Step 2: Create Profile&nbsp;
            <Popover title="Lorem ipsum dolor lorem ipsum dolor sit amet">
              Lorem ipsum dolorLorem ipsum dolor sit amet sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut
            </Popover>
          </h3>
          <div className="prescreen-create-profile-req">
            <span className="torch bold">*</span>&nbsp;All fields are required
            to procede
          </div>
          <div className="prescreen-profile-form-wrap">
            <div className="prescreen-form-section">
              <Input
                label="First Name"
                isRequired={true}
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <Input
                label="Last Name"
                isRequired={true}
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
            <div className="prescreen-form-section">
              <Email
                label="Email Address"
                isRequired={true}
                value={localStorage.getItem(IS_USER_EMAILID)}
                disable={
                  localStorage.getItem(IS_USER_EMAILID) === "" ? false : true
                }
              />
              <Phone
                label="Phone Number"
                isRequired={true}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="prescreen-contact-prefs">
            <div className="prescreen-contact-pref-head">
              Contact Preference&nbsp;<span className="torch bold">*</span>
            </div>
            <ul className="checkbox-list small">
              <li>
                <Radio
                  checked={contactPref === 1}
                  onChange={() => setContactPref(1)}
                >
                  No Preference
                </Radio>
              </li>
              <li>
                <Radio
                  checked={contactPref === 2}
                  onChange={() => setContactPref(2)}
                >
                  Phone
                </Radio>
              </li>
              <li>
                <Radio
                  checked={contactPref === 3}
                  onChange={() => setContactPref(3)}
                >
                  Email
                </Radio>
              </li>
            </ul>
            <div className="prescreen-contact-pref-head">
              What is the best time to reach you?&nbsp;
              <span className="torch bold">*</span>
            </div>
            <ul className="checkbox-list small">
              <li>
                <Checkbox
                  name="prescreen-time"
                  checked={bestTime.includes(1)}
                  onChange={() => onBestTimeChange(1)}
                >
                  Morning 8am to 12pm
                </Checkbox>
              </li>
              <li>
                <Checkbox
                  name="prescreen-time"
                  checked={bestTime.includes(2)}
                  onChange={() => onBestTimeChange(2)}
                >
                  Afternoon 12pm to 5pm
                </Checkbox>
              </li>
              <li>
                <Checkbox
                  name="prescreen-time"
                  checked={bestTime.includes(3)}
                  onChange={() => onBestTimeChange(3)}
                >
                  Evening 5pm to 7pm
                </Checkbox>
              </li>
              <li>
                <Checkbox
                  name="prescreen-time"
                  checked={bestTime.includes(4)}
                  onChange={() => onBestTimeChange(4)}
                >
                  Night 9pm to 4am
                </Checkbox>
              </li>
            </ul>
          </div>
          <h3 className="prescreen-review-terms-head">
            Review Terms&nbsp;
            <Popover title="Lorem ipsum dolor lorem ipsum dolor sit amet">
              Lorem ipsum dolorLorem ipsum dolor sit amet sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut
            </Popover>
          </h3>
          <div className="prescreen-review-box">
            <Terms Isinvitation={true}></Terms>
          </div>
          <Checkbox
            className="prescreen-review-agree"
            checked={agreement}
            onChange={() => setAgreement(!agreement)}
          >
            I have read and agree to the terms&nbsp;
            <span className="torch bold">*</span>
          </Checkbox>
          <div className="prescreen-review-button">
            <button
              className="primary"
              disabled={
                agreement !== false &&
                phoneNumber !== "(___) ___-____" &&
                lastName !== "" &&
                firstName !== "" &&
                site !== "" &&
                contactPref !== "" &&
                bestTime.length !== 0
                  ? false
                  : true
              }
              onClick={onSendInfo}
            >
              REGISTER FOR TRIAL
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuickStart;
