import React from "react";
import { Link } from "react-router-dom";
import "./imageButton.less";

import CloseIcon from "../../assets/icons/ic_close torch.png";
import LeftArrow from "../../assets/icons/ic_circle-arrow-left Torch.png";
import Trash from "../../assets/icons/ic_trash.png";

const ImageButton = ({ type = "close", to, className, text = "" }) => {
  var img = "";

  switch (type.toLowerCase()) {
    case "close":
      img = CloseIcon;
      break;
    case "left":
      img = LeftArrow;
      break;
    case "trash":
      img = Trash;
      break;
    default:
      img = CloseIcon;
      break;
  }

  return (
    <Link to={to} className={`img-button tertiary slim ${className}`}>
      <span style={{ backgroundImage: `url('${img}')` }}></span>
      {text}
    </Link>
  );
};

export default ImageButton;
