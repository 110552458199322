import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ResponsiveBg from "../shared/responsiveBg";
import GoogleMapReact from "google-map-react";
import "./participate.less";
import "./participate-alt.less";
import Pin from "../../assets/icons/ic_pin.png";
import Visit from "../../assets/icons/ic_visit.png";
import DownArrow from "../../assets/icons/ic_caret-down.png";
import Spinner from "../shared/spinner";

import heroL_placeholder from "../../assets/participate-hero-l.jpg";
import heroM_placeholder from "../../assets/participate-hero-m.jpg";
import heroS_placeholder from "../../assets/participate-hero-s.jpg";
import { gql, useQuery } from "@apollo/client";
import { client as apiClient } from "../../api/apiClient";
import MaleIcon from "../../assets/icons/ic_male.png";
import FemaleIcon from "../../assets/icons/ic_female.png";
const FEED_QUERY = gql`
  query GetParticipate {
    cpex {
      getParticipate {
        data {
          number_of_trials
          number_of_participants
          number_of_countries
          conditions_list
          regions_list
          studies {
            id
            nct_id
            current_phase
            study_type
            condition
            description
            number_of_locations
            enrollment
            status
            age_group
            gender
          }
        }
      }
    }
  }
`;
const ParticipateAlt = () => {
  const login = useQuery(FEED_QUERY, {
    client: apiClient,
  });
  const [FilterCondition, setFilterCondition] = useState("");
  const data =
    login.data !== undefined &&
    login.data.cpex !== undefined &&
    login.data.cpex.getParticipate !== undefined &&
    login.data.cpex.getParticipate.data !== undefined
      ? login.data.cpex.getParticipate.data
      : undefined;

  window.gtag("event", "page_view", {
    page_title: "Participate",
    page_path: window.location.pathname,
  });

  const history = useHistory();
  if (data === undefined) {
    return <Spinner />;
  }
  const mapProps = {
    center: {
      lat: 40.46032,
      lng: -75.40962,
    },
    zoom: 1,
  };
  const showDetails = (e, Details) => {
    e.preventDefault();
    history.push("/participate/details", Details);
  };
  return data !== undefined ? (
    <React.Fragment>
      <ResponsiveBg
        className="participate-hero"
        largeBg={heroL_placeholder}
        mediumBg={heroM_placeholder}
        smallBg={heroS_placeholder}
      />
      <div className="content-edge participate-wrap">
        <div className="shadow-box participate-head-box">
          <h2 className="participate-head-box-text">
            <span className="num-callout-bold">
              {data.number_of_participants}
            </span>{" "}
            participants are volunteering in{" "}
            <span className="num-callout-bold">{data.number_of_trials}</span>{" "}
            trials, across{" "}
            <span className="num-callout-bold">{data.number_of_countries}</span>{" "}
            {data.number_of_countries !== 1 ? "countries" : "country"}
          </h2>
        </div>
        <p className="participate-sub-desc">
          A clinical trial is a research study that is done to find out if
          medical treatments are safe and effective. A medical treatment can be
          a drug, medical device, medical procedure, or a change in a person’s
          behavior such as diet or exercise.
        </p>
        <h3 className="participate-journey-head">Start your journey</h3>
        <p className="participate-journey-desc">
          We have opportunities to help with studies of{" "}
          <span>{data.conditions_list.length}</span> rare conditons. View List
        </p>
        <div className="participate-journey-wrapper">
          <div className="participate-journey-area">
            <select
              className="participate-journey-selector"
              style={{
                backgroundImage: `url('${Visit}'), url('${DownArrow}')`,
              }}
              onChange={(e) => setFilterCondition(e.target.value)}
            >
              <option value="">All Conditions</option>
              {data.conditions_list
                .slice()
                .sort((a, b) => (a > b ? 1 : -1))
                .map((item, i) => {
                  return (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  );
                })}
            </select>
            <div className="participate-study-results">
              {data.studies
                .slice()
                .sort((a, b) => (a.condition > b.condition ? 1 : -1))
                .map((item, i) => {
                  if (
                    FilterCondition === "" ||
                    FilterCondition === item.condition
                  ) {
                    return (
                      <Link
                        to="#"
                        className="participate-result-item"
                        key={`participate-result-item-${i}`}
                        onClick={(e) => showDetails(e, item)}
                      >
                        <div className="sub-title">
                          {item.nct_id}&nbsp;
                          {item.current_phase !== "" ? (
                            <span>|&nbsp;{item.current_phase}&nbsp;</span>
                          ) : (
                            ""
                          )}
                          |&nbsp;
                          {item.study_type}
                        </div>
                        <h3 className="title">{item.condition}</h3>
                        <p className="desc">{item.description}</p>
                        <div className="filter-area">
                          {item.number_of_locations !== null &&
                          item.number_of_locations !== "" ? (
                            <div
                              className="filter"
                              key={`filter-item-Site-${i}`}
                            >
                              {item.number_of_locations + " Sites"}
                            </div>
                          ) : null}
                          {item.enrollment !== null &&
                          item.enrollment !== "" ? (
                            <div
                              className="filter"
                              key={`filter-item-Participants-${i}`}
                            >
                              {item.enrollment + " Participants"}
                            </div>
                          ) : null}
                          {item.status !== null && item.status !== "" ? (
                            <div
                              className="filter"
                              key={`filter-item-Status-${i}`}
                            >
                              {item.status}
                            </div>
                          ) : null}
                          {item.age_group !== null && item.age_group !== "" ? (
                            <div
                              className="filter"
                              key={`filter-item-Group-${i}`}
                            >
                              {item.age_group}
                            </div>
                          ) : null}
                          {item.gender === "Male" || item.gender === "All" ? (
                            <div
                              className="filter"
                              key={`filter-item-Male-${i}`}
                            >
                              <div
                                className="participate-trial-info-item-icon"
                                style={{
                                  backgroundImage: `url('${MaleIcon}')`,
                                }}
                              ></div>
                            </div>
                          ) : null}
                          {item.gender === "Female" || item.gender === "All" ? (
                            <div
                              className="filter"
                              key={`filter-item-Female-${i}`}
                            >
                              <div
                                className="participate-trial-info-item-icon"
                                style={{
                                  backgroundImage: `url('${FemaleIcon}')`,
                                }}
                              ></div>
                            </div>
                          ) : null}
                        </div>
                      </Link>
                    );
                  } else {
                    return "";
                  }
                })}
            </div>
          </div>
          <div className="participate-journey-area">
            <select
              className="participate-journey-selector"
              style={{
                backgroundImage: `url('${Pin}'), url('${DownArrow}')`,
              }}
            >
              <option>All Regions</option>
              {data.regions_list
                .slice()
                .sort((a, b) => (a > b ? 1 : -1))
                .map((item, i) => {
                  return <option key={i}>{item}</option>;
                })}
            </select>
            <div className="participate-regional-map">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                }}
                defaultCenter={mapProps.center}
                defaultZoom={mapProps.zoom}
              >
                <Marker
                  lat={41.7860603}
                  lng={-105.0853276}
                  count={data.number_of_trials}
                />
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment></React.Fragment>
  );
};
export const Marker = ({ count }) => {
  return (
    <div>
      <div className="GoogleMap-Point">{count}</div>
    </div>
  );
};

export default ParticipateAlt;
