import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ImageButton from "../shared/imageButton";
import { Input, Email, Phone, Checkbox, Radio } from "../shared/formFields";
import GoogleMapReact from "google-map-react";
import Popover from "../shared/popover";
import ScrollToTop from "../shared/scrollToTop";
import "./createProfile.less";

const CreateProfile = ({ isMatch = true }) => {
  const history = useHistory();
  const [contactPref, setContactPref] = useState("");
  const [bestTime, setBestTime] = useState([]);
  const [agreement, setAgreement] = useState(false);

  const mapProps = {
    center: {
      lat: 40.46032,
      lng: -75.40962,
    },
    zoom: 1,
  };

  const onSendInfo = () => {
    history.push("/participate/confirm");
  };

  const onBestTimeChange = (timeOfDay) => {
    const newBestTime = [...bestTime];

    let index = newBestTime.indexOf(timeOfDay);
    if (index === -1) {
      newBestTime.push(timeOfDay);
    } else {
      newBestTime.splice(index, 1);
    }

    setBestTime(newBestTime);
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="content-edge content-ceiling">
        <ImageButton type="left" to="/participate/prescreening" text="Back" />
        <h1 className="prescreen-head">R19-D Systemic Sclerosis</h1>
        <div className="prescreen-subhead">
          NCT02172950 | Phase 1 | Interventional
        </div>
        <div className="screening-wrap">
          <div className="prescreen-head-box shadow-box-small">
            <div className="prescreen-head-box-details">
              <h3>Research Solutions of Philadelphia</h3>
              <div>Philadelphia, PA USA 85340</div>
            </div>
            <div className="prescreen-small-map">
              <GoogleMapReact
                draggable={false}
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                }}
                defaultCenter={mapProps.center}
                defaultZoom={mapProps.zoom}
              ></GoogleMapReact>
            </div>
          </div>

          {isMatch && (
            <React.Fragment>
              <h2 className="prescreen-profile-head">
                Good News! This study could be right for you
              </h2>
              <h3 className="prescreen-profile-subhead">What happens next</h3>
              <ul className="prescreen-nextsteps">
                <li>
                  Create a profile to share information with your selected site
                  location
                </li>
                <li>
                  A member of study staff will contact you by phone or email to
                  discuss next step and determine if you qualify
                </li>
                <li>
                  If you are qualified and want to participate a trial
                  administrator will answer any questions you may have
                </li>
              </ul>
            </React.Fragment>
          )}

          {!isMatch && (
            <React.Fragment>
              <h2 className="prescreen-profile-head">
                This study is not a match
              </h2>
              <h3 className="prescreen-profile-subhead">Your options</h3>
              <ul className="prescreen-nextsteps">
                <li>
                  Search&nbsp;
                  <a href="https://clinicaltrials.gov" className="link">
                    ClinicalTrials.gov
                  </a>{" "}
                  to find a trial that may a match
                </li>
                <li>
                  Share your contact information and we will contact you for
                  future studies that may be a better match
                </li>
              </ul>
            </React.Fragment>
          )}

          <h3 className="prescreen-create-profile-head">
            Create Profile&nbsp;
            <Popover title="Lorem ipsum dolor lorem ipsum dolor sit amet">
              Lorem ipsum dolorLorem ipsum dolor sit amet sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut
            </Popover>
          </h3>
          <div className="prescreen-create-profile-req">
            All fields are required to procede
          </div>
          <div className="prescreen-profile-form-wrap">
            <div className="prescreen-form-section">
              <Input label="First Name" />
              <Input label="Last Name" />
            </div>
            <div className="prescreen-form-section">
              <Email label="Email Address" />
              <Phone label="Phone Number" />
            </div>
          </div>
          <div className="prescreen-contact-prefs">
            <div className="prescreen-contact-pref-head">
              Contact Preference
            </div>
            <ul className="checkbox-list small">
              <li>
                <Radio
                  checked={contactPref === ""}
                  onChange={() => setContactPref("")}
                >
                  No Preference
                </Radio>
              </li>
              <li>
                <Radio
                  checked={contactPref === "Phone"}
                  onChange={() => setContactPref("Phone")}
                >
                  Phone
                </Radio>
              </li>
              <li>
                <Radio
                  checked={contactPref === "Email"}
                  onChange={() => setContactPref("Email")}
                >
                  Email
                </Radio>
              </li>
            </ul>
            <div className="prescreen-contact-pref-head">
              What is the best time to reach you?
            </div>
            <ul className="checkbox-list small">
              <li>
                <Checkbox
                  name="prescreen-time"
                  checked={bestTime.includes("morning")}
                  onChange={() => onBestTimeChange("morning")}
                >
                  Morning 8am to 12pm
                </Checkbox>
              </li>
              <li>
                <Checkbox
                  name="prescreen-time"
                  checked={bestTime.includes("afternoon")}
                  onChange={() => onBestTimeChange("afternoon")}
                >
                  Afternoon 12pm to 5pm
                </Checkbox>
              </li>
              <li>
                <Checkbox
                  name="prescreen-time"
                  checked={bestTime.includes("evening")}
                  onChange={() => onBestTimeChange("evening")}
                >
                  Evening 5pm to 7pm
                </Checkbox>
              </li>
              <li>
                <Checkbox
                  name="prescreen-time"
                  checked={bestTime.includes("night")}
                  onChange={() => onBestTimeChange("night")}
                >
                  Night 9pm to 4am
                </Checkbox>
              </li>
            </ul>
          </div>
          <h3 className="prescreen-review-terms-head">
            Review Terms&nbsp;
            <Popover title="Lorem ipsum dolor lorem ipsum dolor sit amet">
              Lorem ipsum dolorLorem ipsum dolor sit amet sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incididunt ut Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut
            </Popover>
          </h3>
          <div className="prescreen-review-box">
            Nulla vitae elit libero, a pharetra augue. Aenean eu leo quam.
            Pellentesque ornare sem lacinia quam venenatis vestibulum. Cum
            sociis natoque penatibus et magnis dis parturient montes, nascetur
            ridiculus mus. Nullam id dolor id nibh ultricies vehicula ut id
            elit. Nulla vitae elit libero, a pharetra augue. Etiam porta sem
            malesuada magna mollis euismod. Nullam quis risus eget urna mollis
            ornare vel eu leo. Cum sociis natoque penatibus et magnis dis
            parturient montes, nascetur ridiculus mus. Maecenas sed diam eget
            risus varius blandit sit amet non magna. Aenean lacinia bibendum
            nulla sed consectetur. Nullam id dolor id nibh ultricies vehicula ut
            id elit. Fusce dapibus, tellus ac cursus commodo, tortor mauris
            condimentum nibh, ut fermentum massa justo sit amet risus. Donec id
            elit non mi porta gravida at eget metus. Curabitur blandit tempus
            porttitor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Maecenas faucibus mollis interdum. Fusce dapibus, tellus ac cursus
            commodo, tortor mauris condimentum nibh, ut fermentum massa justo
            sit amet risus. Morbi leo risus, porta ac consectetur ac, vestibulum
            at eros. Vestibulum id ligula porta felis euismod semper. Maecenas
            sed diam eget risus varius blandit sit amet non magna. Nulla vitae
            elit libero, a pharetra augue. Aenean eu leo quam. Pellentesque
            ornare sem lacinia quam venenatis vestibulum. Cum sociis natoque
            penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            Nullam id dolor id nibh ultricies vehicula ut id elit.
          </div>
          <Checkbox
            className="prescreen-review-agree"
            checked={agreement}
            onChange={() => setAgreement(!agreement)}
          >
            I have read and agree to the terms
          </Checkbox>
          <div className="prescreen-review-button">
            <button
              className="primary"
              disabled={!agreement}
              onClick={onSendInfo}
            >
              SEND TO TRIAL SITE
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CreateProfile;
