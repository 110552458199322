import { createReducer } from "@reduxjs/toolkit";

const initialState = { isOpen: false };

const sidebarReducer = createReducer(initialState, {
  SHOW_SIDEBAR: (state) => {
    return { ...state, isOpen: true };
  },
  HIDE_SIDEBAR: (state) => {
    return { ...state, isOpen: false };
  },
});

export default sidebarReducer;
