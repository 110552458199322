import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ImageButton from "../shared/imageButton";
import { Email } from "../shared/formFields";
import { gql, useMutation, useQuery } from "@apollo/client";
import Spinner from "../shared/spinner";
import { client as apiClient } from "../../api/apiClient";
import "./forgotPassword.less";
import ButtonLoader from "../shared/buttonLoader";
const GET_FORGOT_PASSWORD = gql`
  query GetForgotPassword($forgotPasswordId: String) {
    getForgotPassword(where: { forgotPasswordId: $forgotPasswordId }) {
      data {
        back
        email
        emailInvalid
        emailRequired
        forgotPassword
        description
        resetPassword
        completedDescription
        completedDescriptionTwo
        completedButton
      }
    }
  }
`;
const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    cpex {
      resetPassword(email: $email) {
        data {
          success
        }
        error {
          message
        }
      }
    }
  }
`;
const ForgotPassword = () => {
  const [completed, setCompleted] = useState(false);
  const [isRequest, setIsRequest] = useState(false);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [emailvalidDyn, setemailvalidDyn] = useState(false);
  const [emailvalidDynMsg, setemailvalidDynMsg] = useState("");
  const history = useHistory();
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onEmailValid = (e) => {
    setEmailValid(e);
    setemailvalidDyn(false);
    setemailvalidDynMsg("");
  };
  const [forgotPassword] = useMutation(RESET_PASSWORD, {
    client: apiClient,
    variables: {
      email: email,
    },
    onCompleted: (data) => {
      setIsRequest(false);
      if (
        data.cpex.resetPassword.data !== null &&
        data.cpex.resetPassword.data.success
      ) {
        history.push("/signin");
      } else if (data.cpex.resetPassword.error !== null) {
        setemailvalidDyn(true);
        setemailvalidDynMsg(data.cpex.resetPassword.error.message);
      }
    },
  });
  const onSubmit = (e) => {
    e.preventDefault();
    if (emailValid) {
      //todo: send email
      setIsRequest(true);
      // setCompleted(true);
      forgotPassword();
    }
  };

  const { data, loading, error } = useQuery(GET_FORGOT_PASSWORD, {
    variables: { pageId: "cpex_forgot_password" },
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;

  window.gtag("event", "page_view", {
    page_title: "Forgot Password",
    page_path: window.location.pathname,
  });

  return (
    <div className="content-edge content-ceiling">
      <ImageButton
        type="left"
        to="/signin"
        text="Back"
        className={completed ? "hidden" : ""}
      />

      <div className="forgot-block">
        <h1>{data.getForgotPassword.data.forgotPassword}</h1>

        {!completed && (
          <form className="forgot-sub-block" onSubmit={onSubmit}>
            <p>{data.getForgotPassword.data.description}</p>
            <Email
              label={data.getForgotPassword.data.email}
              value={email}
              invalidLabel={data.getForgotPassword.data.emailInvalid}
              requiredLabel={data.getForgotPassword.data.emailRequired}
              onChange={onEmailChange}
              onValidate={onEmailValid}
            />
            {emailvalidDyn === true ? (
              <div className="form-field-error-msg">
                <i className="material-icons">warning</i>
                {emailvalidDynMsg}
              </div>
            ) : (
              false
            )}
            <ButtonLoader
              onClick={onSubmit}
              className="primary reset-button"
              disabled={!emailValid}
              loading={isRequest}
            >
              {data.getForgotPassword.data.resetPassword}
            </ButtonLoader>
          </form>
        )}

        {completed && (
          <div className="forgot-sub-block">
            <p>
              {data.getForgotPassword.data.completedDescription}
              <br />
              <b>{email}</b>
              <br />
              <br />
              {data.getForgotPassword.data.completedDescriptionTwo}
              <br />
              <br />
              <Link to="/signin" className="plain">
                {data.getForgotPassword.data.completedButton}
              </Link>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
