import React, { useState } from "react";
import { Checkbox } from "../shared/formFields";
import "./index.less";

const CookiePolicy = () => {
  window.gtag("event", "page_view", {
    page_title: "Cookie Policy",
    page_path: window.location.pathname,
  });

  const [CookieUpdated, setCookieUpdated] = useState(false);
  const data = [
    {
      pageTitle: "CSL Behring Cookie Policy",
      subItems: [
        {
          itemTitle: "What are cookies?",
          content: [
            {
              type: "",
              content: [
                {
                  content:
                    "Cookies are a small piece of text created by websites and stored on your computer. They play an important role in the experience and functionality of a website. They can help make a website easier to use, gather statistics, enable transactions, provide security, and much more.",
                  position: 1,
                },
              ],
              position: 1,
            },
            {
              type: "",
              content: [
                {
                  content: "There are three basic types of cookies:",
                  position: 1,
                },
              ],
              position: 2,
            },
            {
              type: "list",
              content: [
                {
                  content:
                    "Session cookies are temporary cookies that are erased when you close your browser or remain inactive for a short period of time. These types of cookies are common on banking and transactional websites.",
                  position: 1,
                },
                {
                  content:
                    "Persistent cookies remain on your device for a longer period of time. These cookies can be used to remember things about your visit. For example: your last visit, your preferences or your favorite content.",
                  position: 1,
                },
                {
                  content:
                    "Retargeting cookies (also called “pixels”) also remain on your device for a longer period of time. These cookies are used to send information or advertisements to you in the future.",
                  position: 1,
                },
              ],
              position: 3,
            },
          ],
          position: 1,
        },
        {
          itemTitle: "How we use cookies on this website",
          content: [
            {
              type: "",
              content: [
                {
                  content:
                    "We use cookies to gather statistics on how this website is being used so that we can continue to make improvements, and we use cookies to remember your cookie settings. We do not collect or store any personal information about you in these cookies.",
                  position: 1,
                },
              ],
              position: 1,
            },
            {
              type: "",
              content: [
                {
                  content:
                    "The following describes the cookies used on this website:",
                  position: 1,
                },
              ],
              position: 2,
            },
            {
              type: "table",
              content: [
                {
                  tableHeader: [
                    {
                      column1: {
                        type: "",
                        content: "Cookie Name",
                        position: 1,
                      },
                      column2: { type: "", content: "Purpose", position: 1 },
                      column3: {
                        type: "",
                        content: "Additional Information",
                        position: 1,
                      },
                      position: 1,
                    },
                  ],
                  tableBody: [
                    {
                      column1: { type: "", content: "1P_JAR", position: 1 },
                      column2: {
                        type: "list",
                        content: [
                          {
                            content:
                              "Gathers information about how the end user uses the website and any advertising that the end user may have seen before visiting the said website.",
                            position: 1,
                          },
                          {
                            content:
                              "This website does not contain advertising and this cookie may not be relevant.",
                            position: 2,
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "Targeting/Advertising",
                            position: 1,
                          },
                          {
                            title: "Duration:",
                            content: "30 days",
                            position: 2,
                          },
                          {
                            title: "3rd Party:",
                            content: "google.com",
                            position: 3,
                          },
                        ],
                      },
                      position: 2,
                    },
                    {
                      column1: { type: "", content: "AID", position: 1 },
                      column2: {
                        type: "",
                        content: [{ content: "", position: 1 }],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "Targeting/Advertising",
                            position: 1,
                          },
                          {
                            title: "3rd Party:",
                            content: "google.com",
                            position: 2,
                          },
                        ],
                      },
                      position: 2,
                    },
                    {
                      column1: { type: "", content: "ANID", position: 1 },
                      column2: {
                        type: "list",
                        content: [
                          {
                            content:
                              "Gathers information about how the end user uses the website and any advertising that the end user may have seen before visiting the said website.",
                            position: 1,
                          },
                          {
                            content:
                              "This website does not contain advertising and this cookie may not be relevant.",
                            position: 2,
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "Targeting/Advertising",
                            position: 1,
                          },
                          {
                            title: "Duration:",
                            content: "2 years",
                            position: 2,
                          },
                          {
                            title: "3rd Party:",
                            content: "google.com",
                            position: 3,
                          },
                        ],
                      },
                      position: 3,
                    },
                    {
                      column1: { type: "", content: "APISID", position: 1 },
                      column2: {
                        type: "list",
                        content: [
                          {
                            content:
                              "Builds a profile of the website visitor's interests to show relevant ads on other sites",
                            position: 1,
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "Targeting/Advertising",
                            position: 1,
                          },
                          { title: "Duration:", content: "", position: 2 },
                          {
                            title: "3rd Party:",
                            content: "google.com",
                            position: 3,
                          },
                        ],
                      },
                      position: 4,
                    },
                    {
                      column1: {
                        type: "",
                        content: "ASP.NET_SessionId",
                        position: 1,
                      },
                      column2: {
                        type: "list",
                        content: [
                          {
                            content:
                              "This cookie is used by the system to maintain a connection to the website while you browse its content.",
                            position: 1,
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "System, Essential",
                            position: 1,
                          },
                          {
                            title: "Duration:",
                            content: "session",
                            position: 2,
                          },
                        ],
                      },
                      position: 5,
                    },
                    {
                      column1: {
                        type: "",
                        content: "CookiePrivacyBanner",
                        position: 1,
                      },
                      column2: {
                        type: "list",
                        content: [
                          {
                            content:
                              "Determines if the cookie banner should be displayed.",
                            position: 1,
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "System, Essential",
                            position: 1,
                          },
                          {
                            title: "Duration:",
                            content: "1 year",
                            position: 2,
                          },
                        ],
                      },
                      position: 6,
                    },
                    {
                      column1: {
                        type: "",
                        content: "DisableNonEssentialCookies",
                        position: 1,
                      },
                      column2: {
                        type: "list",
                        content: [
                          {
                            content:
                              "Stores your preference to disable non‐essential cookies.",
                            position: 1,
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "System, Essential",
                            position: 1,
                          },
                          {
                            title: "Duration:",
                            content: "10 year",
                            position: 2,
                          },
                        ],
                      },
                      position: 7,
                    },
                    {
                      column1: { type: "", content: "HSID", position: 1 },
                      column2: {
                        type: "list",
                        content: [
                          {
                            content:
                              "Builds a profile of the website visitor's interests to show relevant ads on other sites",
                            position: 1,
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "Targeting/Advertising",
                            position: 1,
                          },
                          {
                            title: "3rd Party:",
                            content: "google.com",
                            position: 2,
                          },
                        ],
                      },
                      position: 8,
                    },
                    {
                      column1: {
                        type: "",
                        content: "nearestcenter",
                        position: 1,
                      },
                      column2: {
                        type: "list",
                        content: [
                          {
                            content:
                              "Saves a user's preferred CSL Plasma donation center",
                            position: 1,
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "System",
                            position: 1,
                          },
                        ],
                      },
                      position: 9,
                    },
                    {
                      column1: { type: "", content: "NID", position: 1 },
                      column2: {
                        type: "list",
                        content: [
                          {
                            content:
                              "Builds a profile of your interests to show you relevant ads on other sites.",
                            position: 1,
                          },
                          {
                            content:
                              "This cookie is added when YouTube videos are displayed on web pages.",
                            position: 2,
                          },
                          {
                            content:
                              "This website does not contain advertising and this cookie may not be relevant.",
                            position: 3,
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "Targeting/Advertising",
                            position: 1,
                          },
                          {
                            title: "Duration:",
                            content: "6 months",
                            position: 2,
                          },
                          {
                            title: "3rd Party:",
                            content: "google.com",
                            position: 3,
                          },
                        ],
                      },
                      position: 10,
                    },
                    {
                      column1: {
                        type: "",
                        content: "NSC_mcwt_xxx.*",
                        position: 1,
                      },
                      column2: {
                        type: "list",
                        content: [
                          {
                            content:
                              "Used to ensure traffic and user data is routed to the correct locations where a site is hosted on multiple servers, so that the end user has a consistent experience",
                            position: 1,
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "System, Essential",
                            position: 1,
                          },
                          {
                            title: "Duration:",
                            content: "Session",
                            position: 2,
                          },
                        ],
                      },
                      position: 11,
                    },
                    {
                      column1: { type: "", content: "SAPISID", position: 1 },
                      column2: {
                        type: "list",
                        content: [
                          {
                            content:
                              "Builds a profile of the website visitor's interests to show relevant ads on other sites",
                            position: 1,
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "Targeting/Advertising",
                            position: 1,
                          },
                          {
                            title: "3rd Party:",
                            content: "google.com",
                            position: 2,
                          },
                        ],
                      },
                      position: 12,
                    },
                    {
                      column1: {
                        type: "",
                        content: "SC_ANALTICS_GLOBAL_COOKIE",
                        position: 1,
                      },
                      column2: {
                        type: "list",
                        content: [
                          {
                            content:
                              "Gathers information on the number of visits, pages viewed, the duration of time spent on pages, and other site statistics.",
                            position: 1,
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "Website Analytics",
                            position: 1,
                          },
                          {
                            title: "Duration:",
                            content: "10 years",
                            position: 2,
                          },
                        ],
                      },
                      position: 13,
                    },
                    {
                      column1: { type: "", content: "SID", position: 1 },
                      column2: {
                        type: "list",
                        content: [{ content: "", position: 1 }],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "Targeting/Advertising",
                            position: 1,
                          },
                          {
                            title: "3rd Party",
                            content: "google.com",
                            position: 2,
                          },
                        ],
                      },
                      position: 14,
                    },
                    {
                      column1: { type: "", content: "SIDCC", position: 1 },
                      column2: {
                        type: "list",
                        content: [
                          {
                            content:
                              "Gathers information about how the end user uses the website and any advertising that the end user may have seen before visiting the said website.",
                            position: 1,
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "Targeting/Advertising",
                            position: 1,
                          },
                          {
                            title: "3rd Party",
                            content: "google.com",
                            position: 2,
                          },
                        ],
                      },
                      position: 15,
                    },
                    {
                      column1: { type: "", content: "SSID", position: 1 },
                      column2: {
                        type: "list",
                        content: [
                          {
                            position: 1,
                            content:
                              "Gathers information about how the end user uses the website and any advertising that the end user may have seen before visiting the said website.",
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "Targeting/Advertising",
                            position: 1,
                          },
                          {
                            title: "3rd Party",
                            content: "google.com",
                            position: 2,
                          },
                        ],
                      },
                      position: 16,
                    },
                    {
                      column1: {
                        type: "list",
                        content: [
                          { content: "_ga", position: 1 },
                          { content: "_gat", position: 2 },
                          { content: "_gid", position: 3 },
                        ],
                      },
                      column2: {
                        type: "list with link",
                        content: [
                          {
                            content:
                              "Google Analytics cookies gather information on the number of visits, pages viewed, the duration of time spent on pages, and other site statistics.",
                            position: 1,
                            type: "",
                          },
                          {
                            content:
                              "You can disable all Google Analytics on your webbrowser using the ",
                            link: "Google Analytics Opt‐Out Browser Add‐on",
                            linkUrl: "https://tools.google.com/dlpage/gaoptout",
                            position: 2,
                            type: "list with link",
                          },
                        ],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "Website Analytics",
                            position: 1,
                          },
                          {
                            title: "Duration:",
                            content: "up to 2 years",
                            position: 2,
                          },
                          {
                            title: "3rd Party",
                            content: "google.com",
                            position: 3,
                          },
                        ],
                      },
                      position: 17,
                    },
                    {
                      column1: {
                        type: "",
                        content: " _Secure-3P* ",
                        position: 1,
                      },
                      column2: {
                        type: "",
                        content: { content: "", position: 1 },
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "Targeting/Advertising",
                            position: 1,
                          },
                          {
                            title: "3rd Party:",
                            content: "google.com",
                            position: 2,
                          },
                        ],
                      },
                      position: 18,
                    },
                    {
                      column1: {
                        type: "",
                        content: "__RequestVerificationToken",
                      },
                      column2: {
                        type: "list",
                        content: [{ content: "Website security", position: 1 }],
                      },
                      column3: {
                        type: "list",
                        content: [
                          {
                            title: "Category:",
                            content: "System, Essential",
                            position: 1,
                          },
                          {
                            title: "Duration:",
                            content: "session",
                            position: 2,
                          },
                        ],
                      },
                      position: 19,
                    },
                  ],
                },
              ],
              position: 3,
            },
          ],
          position: 2,
        },
        {
          itemTitle: "",
          content: [
            {
              type: "",
              content: [
                {
                  content:
                    "We may embed videos on some of our pages from our YouTube channel. If you play one of these videos YouTube will store cookies on your device. Learn more about how Google uses cookies and their privacy policy.",
                  position: 1,
                },
              ],
              position: 1,
            },
            {
              type: "",
              content: [
                {
                  content:
                    "You can disable non‐essential cookies on our website. This will also delete existing cookies.",
                  position: 1,
                },
              ],
              position: 2,
            },
            {
              type: "checkbox",
              content: [
                { content: "Disable non-essential cookies", position: 1 },
              ],
              position: 3,
            },
          ],
        },
      ],
      savebutton: "Save",
    },
  ];
  const buildcontent = () => {
    let subitems = data[0].subItems
      .slice()
      .sort((a, b) => a.position - b.position);
    return subitems.map((item, index) => {
      let subDataContent = item.content
        .slice()
        .sort((a, b) => a.position - b.position);
      return (
        <React.Fragment key={index}>
          <h2>{item.itemTitle}</h2>
          {buildSubContent(subDataContent)}
        </React.Fragment>
      );
    });
  };
  const buildSubContent = (subDataContent) => {
    return subDataContent.map((item, index) => {
      return (
        <React.Fragment key={index}>
          {item.type === "" ? (
            <p>{item.content[0].content}</p>
          ) : item.type === "list" ? (
            <ul> {buildlist(item.content)}</ul>
          ) : item.type === "checkbox" ? (
            <div className="cookie-csl-checkbox">
              <p className="table-p">{item.content[0].content}</p>
              <Checkbox
                onChange={(e) => onCookieUpdatedChange(e)}
                checked={CookieUpdated}
              ></Checkbox>
            </div>
          ) : (
            buildTable(item.content)
          )}
        </React.Fragment>
      );
    });
  };
  const buildlist = (content, type, listclass) => {
    if (content !== undefined && content !== null) {
      let subDataContent = content
        .slice()
        .sort((a, b) => a.position - b.position);
      return subDataContent.map((item, index) => {
        return (
          <React.Fragment key={index}>
            {type === 1 ? (
              <li className={listclass}>{item.content}</li>
            ) : type === 2 ? (
              item.type === "list with link" ? (
                <li className={listclass}>
                  {item.content}
                  <a
                    href={item.linkUrl}
                    className="table-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.link}
                  </a>
                </li>
              ) : (
                <li className={listclass}>{item.content}</li>
              )
            ) : (
              <li className={listclass}>
                <span className="">{item.title}</span>
                <span>{item.content}</span>
              </li>
            )}
          </React.Fragment>
        );
      });
    } else {
      return 0;
    }
  };
  const buildTable = (content) => {
    return (
      <table className="csl-table-table">
        <thead className="table-head">
          <tr>
            <th scope="col" className="csl-table-td csl-table-th">
              {content[0].tableHeader[0].column1.content}
            </th>
            <th scope="col" className="csl-table-td csl-table-th">
              {content[0].tableHeader[0].column2.content}
            </th>
            <th scope="col" className="csl-table-td csl-table-th">
              {content[0].tableHeader[0].column3.content}
            </th>
          </tr>
        </thead>
        <tbody>{buildTableRows(content[0].tableBody)}</tbody>
      </table>
    );
  };
  const buildTableRows = (tableBody) => {
    let Content = tableBody.slice().sort((a, b) => a.position - b.position);
    return Content.map((item, index) => {
      return (
        <tr key={index}>
          <td className="csl-table-td csl-table-title">
            {item.column1.type === "" ? (
              <p className="table-p">{item.column1.content}</p>
            ) : item.column1.type === "list" ? (
              <ul className="table-ul">
                {buildlist(item.column1.content, 0, "table-li table-p")}
              </ul>
            ) : (
              ""
            )}
          </td>
          <td className="csl-table-td">
            {item.column2.type === "" ? (
              <p>{item.column2.content.content}</p>
            ) : item.column2.type === "list" ? (
              <ul className="table-ul">
                {buildlist(item.column2.content, 1, "table-li")}
              </ul>
            ) : (
              <ul className="table-ul">
                {buildlist(item.column2.content, 2, "table-li")}
              </ul>
            )}
          </td>
          <td className="csl-table-td">
            {item.column3.type === "" ? (
              <div>
                <span>{item.column3.content.title}</span>
                <span>{item.column3.content.content}</span>
              </div>
            ) : item.column3.type === "list" ? (
              <ul className="table-ul">
                {buildlist(item.column3.content, 0, "table-li")}
              </ul>
            ) : (
              ""
            )}
          </td>
        </tr>
      );
    });
  };
  const onCookieUpdatedChange = (e) => {
    setCookieUpdated(e.target.checked);
  };
  return (
    <div className="regular-text-container PageSize-CookiePolicy">
      <h1>{data[0].pageTitle}</h1>
      {buildcontent()}
      <button type="submit" className="primary sign-in">
        {data[0].savebutton}
      </button>
    </div>
  );
};

export default CookiePolicy;
