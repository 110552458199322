import React from "react";
import { Route, Switch } from "react-router-dom";
import CreateProfile from "./createProfile";

import Details from "./detailsNoSurvey";
import ParticipateAlt from "./participate-alt";
import Prescreening from "./prescreening";
import QuickStart from "./quickStart";
import SearchResults from "./results";
import Confirm from "./confirm";

const ParticipateRouter = () => {
  return (
    <Switch>
      <Route exact path="/participate" component={ParticipateAlt} />
      <Route exact path="/participate/results" component={SearchResults} />
      <Route exact path="/participate/details" component={Details} />
      <Route exact path="/participate/prescreening" component={Prescreening} />
      <Route
        exact
        path="/participate/create-profile"
        component={CreateProfile}
      />
      <Route exact path="/participate/confirm" component={Confirm} />
      {/*<Route exact path="/participate/quick-start" component={QuickStart} />*/}
    </Switch>
  );
};

export default ParticipateRouter;
