import React, { useState } from "react";
import "./index.less";
import { useHistory } from "react-router-dom";
import Spinner from "../shared/spinner";
import BackButton from "../shared/backButton";
import { gql, useQuery } from "@apollo/client";
import ResponsiveBg from "../shared/responsiveBg";

import Arrow from "../../assets/icons/ic_caret-down.png";

const GET_RD_ONE = gql`
  query GetRdOne($pageId: String) {
    getRdOne(where: { pageId: $pageId }) {
      data {
        pageTitle
        crumbs1
        crumbs2
        crumbs3
        backButtonText
        circleArrowLeftTorch
        pageMainText
        coreCapabilitiesSectionTitle
        allProductsText
        noProductsText
        selectCategoryText
        categoriesList {
          name
          therapeuticAreas {
            image
            name
            products {
              name
              description
              position
            }
            position
          }
          position
        }
        therapeuticAreasList
      }
      error {
        code
        message
        data
      }
    }
  }
`;

const ResearchDevelop = () => {
  let history = useHistory();

  const { data, loading, error } = useQuery(GET_RD_ONE, {
    variables: { pageId: "cpex_rd_one" },
    errorPolicy: "all",
  });
  const [catagory, setcatagory] = useState("");
  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;

  const CatagoryArray = [];
  const BuildCatagory = () => {
    return data.getRdOne.data.categoriesList.map((catagory, index) => {
      return (
        <div className="RDPipelineSub" key={"RDPL" + index}>
          <div className="Product-Pipeline Core-Capabilities Research-Pre-Clini">
            {catagory.name}
          </div>
          <div className="No-Products FilterApplied" id={catagory.name}>
            {data.getRdOne.data.noProductsText}
          </div>
          <div id={catagory.therapeuticAreas.name}>
            {buildResearchCatagory(
              catagory.name,
              catagory.therapeuticAreas,
              catagory.name.slice(0, 5),
              "SectionMarginBtm"
            )}
          </div>
        </div>
      );
    });
  };
  const buildResearchCatagory = (
    Catagory,
    therapeuticAreas,
    QuesId,
    SectionMargin
  ) => {
    return therapeuticAreas.map((therapeutic, index) => {
      let bordercolor =
        therapeutic.name === "Outlicensed Programs"
          ? "OutlicensedColor"
          : therapeutic.name === "Immunology"
          ? "ImmunologyColor"
          : therapeutic.name === "Hematology"
          ? "darkpurple"
          : therapeutic.name === "Cardiovascular and Metabolic"
          ? "Cardiovascular-and-M"
          : therapeutic.name === "Transplant"
          ? "TransplantColor"
          : therapeutic.name === "Respiratory"
          ? "RespiratoryColor"
          : "CovidColor";
      let CatagoryArraylist = {
        Title: therapeutic.name + Catagory,
        Header: Catagory,
      };
      CatagoryArray.push(CatagoryArraylist);
      return (
        <div id={therapeutic.name + Catagory} key={"RFSItem" + index}>
          <div className="ImageDiv">
            <ResponsiveBg
              className={"Bitmap-ItemImage"}
              smallBg={therapeutic.image}
              mediumBg={therapeutic.image}
              largeBg={therapeutic.image}
            />
            <div className="HematologyDiv">{therapeutic.name}</div>
          </div>
          <div className={"ImageSUbDIv " + bordercolor}>
            {/*<div className={"Rectangle-ItemLine " + LineColor}></div>*/}
            {sectiontitle(
              therapeutic.products,
              SectionMargin,
              QuesId + therapeutic.name.slice(0, 5)
            )}
          </div>
        </div>
      );
    });
  };
  const sectiontitle = (SectionTitles, SectionMargin, Quesitem) => {
    return SectionTitles.map((Title, Index) => {
      return (
        <div
          className={
            Index < SectionTitles.length - 1
              ? "Rectangle-Section-Title " + SectionMargin
              : "Rectangle-Section-Title"
          }
          key={"RfSubItem" + Index}
        >
          <div
            id={"RDPLQues" + Quesitem + Index}
            className="RDCursor"
            onClick={() => ShowAnswer(Quesitem + Index)}
          >
            <div className="Section-TitleRDDiv">{Title.name}</div>
            <div
              className="Path-RD-down Path-Rd-Right"
              id={"RDPLArrow" + Quesitem + Index}
            ></div>
          </div>
          <div
            className="answerRD display-noneRD"
            id={"RDPLAns" + Quesitem + Index}
          >
            {Title.description}
          </div>
        </div>
      );
    });
  };

  const BuildDropDown = (Catagory) => {
    return Catagory.map((CatagoryItem, Index) => {
      return (
        <option value={CatagoryItem} key={"FRDD" + Index}>
          {CatagoryItem}
        </option>
      );
    });
  };

  const applyfilter = (e) => {
    e.preventDefault();
    let val = "";

    if (e.target.value !== undefined) val = e.target.value;

    setcatagory(val);
    if (val === "") {
      document.getElementById("Rectangle-FR").className = "Rectangle-FR";
    } else {
      document.getElementById("Rectangle-FR").className =
        "Rectangle-FR Filter-Name";
    }
    let HideElementId = [];
    CatagoryArray.map((CatagoryItem, Index) => {
      let Value = CatagoryItem.Title.includes(val);
      if (Value !== true) {
        document.getElementById(CatagoryItem.Title).className = "FilterApplied";
      } else {
        document.getElementById(CatagoryItem.Title).className = "";
        HideElementId.push(CatagoryItem.Header);
      }
      return 0;
    });
    HideElementId = data.getRdOne.data.categoriesList.filter(function (item) {
      return HideElementId.indexOf(item.name) === -1;
    });
    if (HideElementId !== null && HideElementId !== undefined) {
      data.getRdOne.data.categoriesList.map((RdOne, index) => {
        if (HideElementId.includes(RdOne)) {
          document.getElementById(RdOne.name).className = "No-Products";
        } else {
          document.getElementById(RdOne.name).className =
            "No-Products FilterApplied";
        }
        return 0;
      });
    }
  };
  const ShowAnswer = (Id) => {
    if (
      document.getElementById("RDPLArrow" + Id) !== null &&
      document.getElementById("RDPLArrow" + Id) !== undefined
    ) {
      let QuestionClass = document.getElementById("RDPLArrow" + Id).className;
      if (QuestionClass === "Path-RD-down Path-Rd-Right") {
        document.getElementById("RDPLAns" + Id).className =
          "answerRD display-blockRD";
        document.getElementById("RDPLArrow" + Id).className = "Path-RD-down";
      } else {
        document.getElementById("RDPLAns" + Id).className =
          "answerRD display-noneRD";
        document.getElementById("RDPLArrow" + Id).className =
          "Path-RD-down Path-Rd-Right";
      }
    }
  };

  return (
    <React.Fragment>
      <div className="Guides---Glossary---S">
        <BackButton
          ButtonText={data.getRdOne.data.backButtonText}
          Crumb1={data.getRdOne.data.crumbs1}
          Crumb2={data.getRdOne.data.crumbs2}
          Crumb3={data.getRdOne.data.crumbs3}
          Path1="/"
          Path2="/learn"
        ></BackButton>
        <div className="Product-Pipeline">{data.getRdOne.data.pageTitle}</div>
        <div className="CSL-Behring-is-a-glo">
          {data.getRdOne.data.pageMainText}
        </div>
        <div className="Product-Pipeline Core-Capabilities">
          {data.getRdOne.data.coreCapabilitiesSectionTitle}
        </div>
        <div
          className="Rectangle-FR"
          id="Rectangle-FR"
          onClick={(e) => applyfilter(e)}
        >
          {data.getRdOne.data.allProductsText}
        </div>
        <select
          className="Rectangle-Select"
          value={catagory}
          style={{ backgroundImage: `url('${Arrow}')` }}
          onChange={(e) => applyfilter(e)}
        >
          <option value={""}>{data.getRdOne.data.selectCategoryText}</option>
          {BuildDropDown(data.getRdOne.data.therapeuticAreasList)}
        </select>
        {BuildCatagory()}
      </div>
    </React.Fragment>
  );
};
export default ResearchDevelop;
