import React from "react";
import "./howClinicalTrialWork.less";
import ResponsiveBg from "../shared/responsiveBg";
const clinicalTrialPlay = ({
  mainText,
  medicineSectionTitle,
  stepsImages,
  classname,
  classname1,
  classname2,
  classname3,
}) => {
  return (
    <React.Fragment>
      <div className={"W-100 " + classname}>
        <ResponsiveBg
          className={"how-plan"}
          smallBg={stepsImages}
          mediumBg={stepsImages}
          largeBg={stepsImages}
        />
        <div className={"float-right " + classname2}>
          <div className={"Clinical-trials-play " + classname1}>{mainText}</div>
          <div className={"How-medicine-gets-fr " + classname3}>
            {medicineSectionTitle}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default clinicalTrialPlay;
