import React, { useState } from "react";
import GoogleMap from "./googleMap";
import BackButton from "../shared/backButton";
import ScrollToTop from "../shared/scrollToTop";
import ExpandList from "../shared/expandList";
import Spinner from "../shared/spinner";
import { gql, useQuery } from "@apollo/client";
import { client as apiClient } from "../../api/apiClient";
import "./detailsNoSurvey.less";
import ResponsiveBg from "../shared/responsiveBg";
import SearchIcon from "../../assets/icons/ic_search.png";
import MaleIcon from "../../assets/icons/ic_male.png";
import FemaleIcon from "../../assets/icons/ic_female.png";
import PhoneIcon from "../../assets/icons/01-icons-ic-phone.png";
import EmailIcon from "../../assets/icons/01-icons-ic-messages.png";
import PhoneIconM from "../../assets/icons/01-icons-ic-phone@2x.png";
import EmailIconM from "../../assets/icons/01-icons-ic-messages@2x.png";
import PhoneIconL from "../../assets/icons/01-icons-ic-phone@3x.png";
import EmailIconL from "../../assets/icons/01-icons-ic-messages@3x.png";
export const GET_CLINICAL_TRIAL = gql`
  query GetClinicalTrial($id: ID!) {
    cpex {
      getClinicalTrial(id: $id) {
        data {
          id
          condition
          description
          summary
          nct_id
          current_phase
          study_type
          number_of_locations
          enrollment
          status
          age_group
          gender
          inclusion_criteria
          exclusion_criteria
          locations {
            id
            name
            city
            state
            country
            zip_code
            latitude
            longitude
          }
        }
      }
    }
  }
`;
const DetailsNoSurvey = (props) => {
  //todo: replace content with real data
  const { data } = useQuery(GET_CLINICAL_TRIAL, {
    client: apiClient,
    variables: {
      id: props.location.state.id,
    },
  });
  const [searchResults, setSearchResults] = useState("");
  if (data === undefined) {
    return <Spinner />;
  }
  const InclusionContent = () => {
    if (
      data.cpex.getClinicalTrial.data.inclusion_criteria !== undefined &&
      data.cpex.getClinicalTrial.data.inclusion_criteria.length > 0
    ) {
      return (
        <ul>
          {data.cpex.getClinicalTrial.data.inclusion_criteria.map((item, i) => {
            return <li key={i}>{item}</li>;
          })}
        </ul>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const ExclusionContent = () => {
    if (
      data.cpex.getClinicalTrial.data.exclusion_criteria !== undefined &&
      data.cpex.getClinicalTrial.data.exclusion_criteria.length > 0
    ) {
      return (
        <ul>
          {data.cpex.getClinicalTrial.data.exclusion_criteria.map((item, i) => {
            return <li key={i}>{item}</li>;
          })}
        </ul>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  let criteriaItems =
    data.cpex.getClinicalTrial.data.inclusion_criteria.length > 0 &&
    data.cpex.getClinicalTrial.data.exclusion_criteria.length === 0
      ? [
          {
            title: "Inclusion Criteria",
            content: <InclusionContent />,
          },
        ]
      : data.cpex.getClinicalTrial.data.inclusion_criteria.length === 0 &&
        data.cpex.getClinicalTrial.data.exclusion_criteria.length > 0
      ? [
          {
            title: "Exclusion Criteria",
            content: <ExclusionContent />,
          },
        ]
      : data.cpex.getClinicalTrial.data.inclusion_criteria.length > 0 &&
        data.cpex.getClinicalTrial.data.exclusion_criteria.length > 0
      ? [
          {
            title: "Inclusion Criteria",
            content: <InclusionContent />,
          },
          {
            title: "Exclusion Criteria",
            content: <ExclusionContent />,
          },
        ]
      : [];

  return data !== undefined ? (
    <React.Fragment>
      <ScrollToTop />
      <div className="content-edge content-ceiling">
        <BackButton
          ButtonText={"Back"}
          Crumb1={"CSL"}
          Crumb2={"Participate"}
          Crumb3={data.cpex.getClinicalTrial.data.condition}
          Path1="/"
          Path2="/participate"
        ></BackButton>
        <h1 className="participate-details-headerNS">
          {data.cpex.getClinicalTrial.data.condition}
        </h1>
        <div className="participate-details-sub-1NS">
          {data.cpex.getClinicalTrial.data.description}
        </div>
        <div className="participate-details-sub-2NS">
          {data.cpex.getClinicalTrial.data.nct_id}
          {data.cpex.getClinicalTrial.data.current_phase !== "" ? (
            <span>
              |&nbsp;{data.cpex.getClinicalTrial.data.current_phase}&nbsp;
            </span>
          ) : (
            ""
          )}
          &nbsp;|&nbsp;
          {data.cpex.getClinicalTrial.data.study_type}
        </div>
        <div className="participate-details-descNS">
          {" "}
          {data.cpex.getClinicalTrial.data.summary}
        </div>
        <div className="trial-info-headNS">Trial Information</div>
        <div className="trial-info-wrapNS">
          <div className="trial-info-itemNS">
            {" "}
            {data.cpex.getClinicalTrial.data.number_of_locations + " Sites"}
          </div>
          <div className="trial-info-itemNS">
            {data.cpex.getClinicalTrial.data.enrollment + " Participants"}
          </div>
          <div className="trial-info-itemNS">
            {data.cpex.getClinicalTrial.data.status}
          </div>
          <div className="trial-info-itemNS">
            {" "}
            {data.cpex.getClinicalTrial.data.age_group}
          </div>
          {data.cpex.getClinicalTrial.data.gender === "Male" ||
          data.cpex.getClinicalTrial.data.gender === "All" ? (
            <div className="trial-info-itemNS">
              <div
                className="trial-info-item-iconNS"
                style={{ backgroundImage: `url('${MaleIcon}')` }}
              />
            </div>
          ) : null}
          {data.cpex.getClinicalTrial.data.gender === "Female" ||
          data.cpex.getClinicalTrial.data.gender === "All" ? (
            <div className="trial-info-itemNS">
              <div
                className="trial-info-item-iconNS"
                style={{ backgroundImage: `url('${FemaleIcon}')` }}
              />
            </div>
          ) : null}
        </div>
        <h2 className="participate-find-outNS">
          If interested, contact clinicaltrials@cslbering.com for more information        
        </h2>      
        <div className="participate-find-wrapNS">
          <div className="participate-find-itemsNS">
            <input
              type="text"
              placeholder="Search State, City, or Zip Code"
              className="participate-find-inputNS"
              style={{ backgroundImage: `url('${SearchIcon}')` }}
              value={searchResults}
              onChange={(e) => setSearchResults(e.target.value)}
            />
            <hr />
            <div className="participate-filter-resultsNS">
              {data.cpex.getClinicalTrial.data.locations.map((item, i) => {
                if (
                  searchResults === "" ||
                  item.city
                    .toLowerCase()
                    .startsWith(searchResults.toLowerCase()) ||
                  item.state
                    .toLowerCase()
                    .startsWith(searchResults.toLowerCase()) ||
                  item.zip_code
                    .toLowerCase()
                    .startsWith(searchResults.toLowerCase())
                ) {
                  return (
                    <div
                      className="participate-filter-itemNS"
                      key={`participate-filter-item-${i}`}
                    >
                      <div className="participate-result-boxNS">
                        <div className="loc-name">
                          {item.name.replace(/[^a-zA-Z ]/g, "")}
                        </div>
                        <div className="loc-area">{`${item.city}, ${item.state}, ${item.zip_code}, ${item.country}`}</div>
                      </div>
                    </div>
                  );
                } else {
                  return "";
                }
              })}
            </div>
          </div>
          <div className="google-map participate-filter-mapNS">
            <GoogleMap
              data={data.cpex.getClinicalTrial.data.locations}
            ></GoogleMap>
          </div>
          <div className="participate-filter-trial-site-locationsNS">
            Trials Site Locations
          </div>
        </div>
        <div className="participate-filter-itemNS part-details-box">
          <h2 className="part-Contact-Trial-Regist">
            Contact Trial Registration Coordinator
          </h2>
          <div>           
            <a
              href={"mailto:clinicaltrials@cslbehring.com"}
              className="part-email"
            >
              <ResponsiveBg
                className={"Iconsic_phone"}
                smallBg={EmailIcon}
                mediumBg={EmailIconM}
                largeBg={EmailIconL}
              />{" "}
              <p className="">clinicaltrials@cslbehring.com </p>
            </a>
          </div>
        </div>
        <hr className="part-filter-dividerNS" />
        <div className="Study-Eligibity-div">
          {criteriaItems.length > 0 ? (
            <React.Fragment>
              <h2 className="Study-Eligibility-Cr">
                Study Eligibility Criteria
              </h2>
              <ExpandList items={criteriaItems} />
            </React.Fragment>
          ) : null}
          <h4 className="part-additional-studiesNS">Additional Studies</h4>
          <p className="part-additional-studies-descNS">
            Additional studies can be found at{" "}
            <a
              className="link"
              href="https://clinicaltrials.gov"
              target="_blank"
              rel="noreferrer"
            >
              ClinicalTrials.gov
            </a>
          </p>
        </div>
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default DetailsNoSurvey;
