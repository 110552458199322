import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ImageButton from "../shared/imageButton";
import GoogleMapReact from "google-map-react";
import ScrollToTop from "../shared/scrollToTop";
import { Checkbox, Radio } from "../shared/formFields";
import "./prescreening.less";

const Prescreening = () => {
  const history = useHistory();
  const [questions, setQuestions] = useState([
    {
      question: "I am completing on behalf of",
      id: 1,
      multi: false,
      answers: [
        {
          id: 1,
          answer: "Myself (Adult 18+)",
        },
        {
          id: 2,
          answer: "Another adult (Adult 18+) as caregiver",
        },
        {
          id: 3,
          answer: "A child (0-17) as parent/guardian",
        },
      ],
    },
    {
      question: "Have you been diagnosed with Systemic Sclerosis before?",
      id: 2,
      multi: false,
      answers: [
        {
          id: 4,
          answer: "Yes",
        },
        {
          id: 5,
          answer: "No",
        },
      ],
    },
    {
      question:
        "Have you had any of the following Systemic Sclerosis disease symptoms in the past two years?",
      id: 3,
      multi: true,
      answers: [
        {
          id: 6,
          answer: "Hair Loss",
        },
        {
          id: 7,
          answer: "Calcium Deposits, or white lumps under the skin",
        },
        {
          id: 8,
          answer: "Small or dilated blood vessels unde the skin’s surface",
        },
        {
          id: 9,
          answer: "Joint pain",
        },
        {
          id: 10,
          answer: "A child (0-17) as parent/guardian",
        },
      ],
    },
    {
      question:
        "Have you had any type of treatments including medication, physiotherapy and surgery.",
      id: 4,
      multi: false,
      answers: [
        {
          id: 11,
          answer: "Yes",
        },
        {
          id: 12,
          answer: "No",
        },
      ],
    },
  ]);

  const mapProps = {
    center: {
      lat: 40.46032,
      lng: -75.40962,
    },
    zoom: 1,
  };

  const onCheckForMatch = () => {
    history.push("/participate/create-profile");
  };

  const onSelectAnswer = (qIndex, aIndex) => {
    const newQuestions = [...questions];

    let currentQuestion = newQuestions[qIndex];
    let currentAnswer = currentQuestion.answers[aIndex];

    //deselect old answers
    if (currentQuestion.multi !== true) {
      currentQuestion.answers
        .filter((a) => a.selected)
        .forEach((item) => {
          item.selected = false;
        });
    }

    if (currentQuestion.multi === true) {
      currentAnswer.selected = !currentAnswer.selected;
    } else {
      currentAnswer.selected = true;
    }

    setQuestions(newQuestions);
  };

  return (
    <React.Fragment>
      <ScrollToTop />
      <div className="content-edge content-ceiling">
        <ImageButton type="left" to="/participate/details" text="Back" />
        <h1 className="prescreen-head">R19-D Systemic Sclerosis</h1>
        <div className="prescreen-subhead">
          NCT02172950 | Phase 1 | Interventional
        </div>
        <div className="screening-wrap">
          <div className="prescreen-head-box shadow-box-small">
            <div className="prescreen-head-box-details">
              <h3>Research Solutions of Philadelphia</h3>
              <div>Philadelphia, PA USA 85340</div>
            </div>
            <div className="prescreen-small-map">
              <GoogleMapReact
                draggable={false}
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
                }}
                defaultCenter={mapProps.center}
                defaultZoom={mapProps.zoom}
              ></GoogleMapReact>
            </div>
          </div>
          <h2 className="prescreen-question-head">
            Answer four questions to see if this trial is right for you
          </h2>
          <p className="prescreen-question-desc">
            All questions are required to procede
          </p>
          <div className="prescreen-questions">
            {questions.map((q, i) => {
              return (
                <div className="prescreen-question-item" key={`question-${i}`}>
                  <div className="prescreen-question-item-head">
                    <h2 className="question-num force-desktop">{i + 1}</h2>
                    <h4 className="question-title">{q.question}</h4>
                  </div>
                  {q.answers.map((a, x) => {
                    return (
                      <div
                        className="prescreen-question-option"
                        key={`prescreen-question-option-${x}`}
                      >
                        {q.multi === true && (
                          <Checkbox
                            checked={a.selected === true}
                            onChange={() => onSelectAnswer(i, x)}
                          >
                            {a.answer}
                          </Checkbox>
                        )}
                        {q.multi === false && (
                          <Radio
                            checked={a.selected === true}
                            onChange={() => onSelectAnswer(i, x)}
                          >
                            {a.answer}
                          </Radio>
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="check-for-match-button">
            <button
              className="primary"
              onClick={onCheckForMatch}
              disabled={questions.some(
                (q) => !q.answers.some((a) => a.selected === true)
              )}
            >
              CHECK FOR MATCH
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Prescreening;
