import React from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { gql, useQuery } from "@apollo/client";
//import { client, isLoggedInVar } from "../../api/apiClient";
import { AuthorizedOnly, UnauthorizedOnly } from "../shared/auth";
import { showSidebar } from "../../actions/sidebar";
import { IS_FIRST_TIME } from "../../constants";
import Spinner from "../shared/spinner";
import "./header.less";
import lightLogo from "../../assets/icons/csl-logo-mobile.png";
import darkLogo from "../../assets/icons/csl-logo-header.png";
import darksidebarLogo from "../../assets/icons/menudark.png";
import sidebarLogo from "../../assets/icons/menulight.png";
const GET_HEADER = gql`
  query GetHeader($headerId: String) {
    getHeader(where: { headerId: $headerId }) {
      data {
        mainLogo
        mainLogoTag
        lightLogo
        sidebarLogo
        sidebarLogoTag
        menu
        menuImg
        darkMenuImg
        menuTag
        home
        learn
        myTrial
        participate
        signIn
        signOut
      }
      error {
        code
        message
        data
      }
    }
  }
`;

const Header = ({
  showSidebar,
  isLight = false,
  showMenu = true,
  isSignIn = false,
  isSignUp = false,
}) => {
  const isFirstTime =
    localStorage.getItem(IS_FIRST_TIME) === null
      ? true
      : localStorage.getItem(IS_FIRST_TIME);
  const history = useHistory();
  const { data, loading, error } = useQuery(GET_HEADER, {
    variables: { pageId: "cpex_header" },
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;

  /*const onSignOut = () => {
    sessionStorage.clear();
    client.clearStore();
    isLoggedInVar(false);
    history.push("/");
  };*/

  return (
    <div className={`header content-edge ${isLight ? "light" : ""}`}>
      <div className="gradient" />
      {(isFirstTime && isSignIn) || isSignUp === true ? (
        <img
          className="logo"
          src={isLight ? lightLogo : darkLogo}
          alt={data.getHeader.data.mainLogoTag}
        />
      ) : (
        <Link className="logo-container" to="/">
          <img
            className="logo"
            src={isLight ? lightLogo : darkLogo}
            alt={data.getHeader.data.mainLogoTag}
          />
        </Link>
      )}
      {showMenu && (
        <div className="menu">
          <div className="deskmenu">
            <NavLink to="/learn" className="nav">
              {data.getHeader.data.learn}
            </NavLink>
            <NavLink to="/participate" className="nav">
              {data.getHeader.data.participate}
            </NavLink>
            <AuthorizedOnly>
              <NavLink to="/trial" className="nav">
                {data.getHeader.data.myTrial}
              </NavLink>
            </AuthorizedOnly>
            {/*<UnauthorizedOnly>
              <NavLink to="/signin" className="nav">
                {data.getHeader.data.signIn}
              </NavLink>
            </UnauthorizedOnly>
            <AuthorizedOnly>
              <button className="nav top-sign-out" onClick={onSignOut}>
                {data.getHeader.data.signOut}
              </button>
            </AuthorizedOnly>*/}
          </div>
          <div onClick={showSidebar} className="ham">
            <img
              src={isLight ? darksidebarLogo : sidebarLogo}
              alt={data.getHeader.data.menuTag}
            />
            {data.getHeader.data.menu}
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(null, { showSidebar })(Header);
