import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../../api/apiClient";

export const ProtectedRoute = ({ ...rest }) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);

  if (isLoggedIn) {
    return <Route {...rest} />;
  }

  return <Redirect to={`/?redirect=${rest.path}`} />;
};

export const AuthorizedOnly = ({ children }) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  if (isLoggedIn) return children;

  return null;
};

export const UnauthorizedOnly = ({ children }) => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  if (!isLoggedIn) return children;

  return null;
};
