import React from "react";
import ImageButton from "../shared/imageButton";
import MessagesComponent from "../shared/messages";
import "./messages.less";

const Messages = ({ cslData }) => {
  return (
    <div className="message-wrap">
      <MessagesComponent />
      <div className="message-area">
        <p>
          Sed posuere consectetur est at lobortis. Vivamus sagittis lacus vel
          augue laoreet rutrum faucibus dolor auctor. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Etiam porta sem malesuada magna
          mollis euismod. Cras justo odio, dapibus ac facilisis in, egestas eget
          quam. Integer posuere erat a ante venenatis dapibus posuere velit
          aliquet.
          <br />
          <br />
          Cras justo odio, dapibus ac facilisis in, egestas eget quam. Nulla
          vitae elit libero, a pharetra augue. Aenean lacinia bibendum nulla sed
          consectetur. Curabitur blandit tempus porttitor. Vestibulum id ligula
          porta felis euismod semper.
        </p>
        <div className="del-wrap">
          <ImageButton text="DELETE" type="trash" />
        </div>
      </div>
    </div>
  );
};

export default Messages;
