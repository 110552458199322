import React from "react";
import "./index.less";
import { gql, useQuery } from "@apollo/client";
import ResponsiveBg from "../shared/responsiveBg";
import Guide from "./guide";
import Spinner from "../shared/spinner";

const GET_LEARN = gql`
  query GetLearn($pageId: String) {
    getLearn(where: { pageId: $pageId }) {
      data {
        pageTitle
        imageSm
        imageMd
        imageLg
        circleArrowRightTorch
        seeMore
        sections {
          title
          imageSm
          imageMd
          imageLg
          description
          links {
            description
            link
            pageId
            position
          }
          position
        }
      }
      error {
        code
        message
        data
      }
    }
  }
`;

const Learn = () => {
  const { data, loading, error } = useQuery(GET_LEARN, {
    variables: { pageId: "cpex_learn" },
    errorPolicy: "all",
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;

  window.gtag("event", "page_view", {
    page_title: "Learn",
    page_path: window.location.pathname,
  });
  const buildGuide = () => {
    let Section = data.getLearn.data.sections
      .slice()
      .sort((a, b) => a.position - b.position);
    return Section.map((SectionData, Index) => {
      return (
        <React.Fragment key={"guide" + Index}>
          <Guide
            className={
              SectionData.links.length > 5
                ? "marginright conditiondiv"
                : SectionData.title === "Conditions"
                ? "marginright conditiondivheight"
                : Index === data.getLearn.data.sections.length - 1
                ? "rightdiv"
                : "marginright rightdivguide"
            }
            SectionID={SectionData.title + "1"}
            GuideData={SectionData}
            ShowMore={
              SectionData.links.length > 5 ? data.getLearn.data.seeMore : null
            }
          />
        </React.Fragment>
      );
    });
  };
  return (
    <React.Fragment>
      <div className="PageSize">
        <ResponsiveBg
          className="Bitmap"
          smallBg={data.getLearn.data.imageSm}
          mediumBg={data.getLearn.data.imageMd}
          largeBg={data.getLearn.data.imageLg}
        />
        <div className="content-edge xxlResponsive">
          <div className="shadow-box Rectangle">
            <div className="Learn-about-clinical">
              {data.getLearn.data.pageTitle}
            </div>
          </div>
          {buildGuide()}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Learn;
