import React from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { connect } from "react-redux";
import { gql, useQuery } from "@apollo/client";
import { client, isLoggedInVar } from "../../api/apiClient";
import { AuthorizedOnly, UnauthorizedOnly } from "../shared/auth";
import { hideSidebar } from "../../actions/sidebar";
import Spinner from "../shared/spinner";
import "./sidebar.less";
import sidebarLogo from "../../assets/icons/menulight.png";

const GET_HEADER = gql`
  query GetHeader($headerId: String) {
    getHeader(where: { headerId: $headerId }) {
      data {
        mainLogo
        mainLogoTag
        lightLogo
        sidebarLogo
        sidebarLogoTag
        menu
        menuImg
        darkMenuImg
        menuTag
        home
        learn
        myTrial
        participate
        signIn
        signOut
      }
      error {
        code
        message
        data
      }
    }
  }
`;

const Sidebar = ({ isOpen, hideSidebar }) => {
  const history = useHistory();

  const { data, loading, error } = useQuery(GET_HEADER, {
    variables: { pageId: "cpex_header" },
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;

  const onSignOut = () => {
    localStorage.clear();
    client.clearStore();
    isLoggedInVar(false);
    history.push("/");
    hideSidebar();
  };

  return (
    <React.Fragment>
      <Menu isOpen={isOpen} onClose={hideSidebar}>
        <div className={`sidebar-wrap ${isOpen ? "sidebar-shadow" : ""}`}>
          <Link to="/" onClick={hideSidebar}>
            <img
              className="sidebar-logo"
              src={sidebarLogo}
              alt={data.getHeader.data.sidebarLogoTag}
            />
          </Link>
          <ul className="sidemenu-list">
            <li>
              <h1 className="light">{data.getHeader.data.menu}</h1>
            </li>
            <li>
              <NavLink
                to="/learn"
                onClick={hideSidebar}
                className="nav"
                activeClassName="active"
              >
                {data.getHeader.data.learn}
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/participate"
                onClick={hideSidebar}
                className="nav"
                activeClassName="active"
              >
                {data.getHeader.data.participate}
              </NavLink>
            </li>
            <AuthorizedOnly>
              <li>
                <NavLink
                  to="/trial"
                  onClick={hideSidebar}
                  className="nav"
                  activeClassName="active"
                >
                  {data.getHeader.data.myTrial}
                </NavLink>
              </li>
            </AuthorizedOnly>
            {/*<li>
              <UnauthorizedOnly>
                <NavLink
                  to="/signin"
                  onClick={hideSidebar}
                  className="nav"
                  activeClassName="active"
                >
                  {data.getHeader.data.signIn}
                </NavLink>
              </UnauthorizedOnly>
              <AuthorizedOnly>
                <button onClick={onSignOut} className="nav sidebar-sign-out">
                  {data.getHeader.data.signOut}
                </button>
              </AuthorizedOnly>
            </li>*/}
          </ul>
        </div>
      </Menu>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return { isOpen: state.sidebar.isOpen };
};

const mapDispatchToProps = (dispatch) => {
  return {
    hideSidebar: () => dispatch(hideSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
