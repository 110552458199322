import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./appointment.less";

import InputMask from "react-input-mask";
import { Checkbox } from "../shared/formFields";
import ImageButton from "../shared/imageButton";
import CalendarIcon from "../../assets/icons/ic_calendar.png";
import LeftArrow from "../../assets/icons/ic_circle-arrow-left Torch.png";
import RightArrow from "../../assets/icons/ic_circle-arrow-right Torch.png";
import CloseIcon from "../../assets/icons/ic_close.png";

const Appointment = () => {
  const [date, setDate] = useState(null);
  const [showShedule, setShowSchedule] = useState(false);
  const [time, setTime] = useState("");
  const [ampm, setAMPM] = useState(null);
  const [visit, setVisit] = useState(false);
  const [exam, setExam] = useState(false);
  const [blood, setBlood] = useState(false);
  const [finished, setFinished] = useState(false);

  const onDateSelected = (d) => {
    setVisit(false);
    setBlood(false);
    setExam(false);
    setTime("");
    setAMPM(null);
    setDate(d);
    setShowSchedule(true);
    setFinished(false);
  };

  const onClose = () => {
    setDate(null);
    setShowSchedule(false);
    setFinished(false);
  };

  const onSave = () => {
    setFinished(true);
  };

  window.gtag("event", "page_view", {
    page_title: "Appointment",
    page_path: window.location.pathname,
  });

  return (
    <div className="content-edge content-ceiling">
      <ImageButton type="close" text="Cancel" to="/trial" />
      <h1 className="appt-header">Add My Appointment Date</h1>
      <p className="appt-notice">
        Make appointments or changes through your site coordinator.
        <br />
        <br />
        Calendar is for your reference only.
        <br />
        <br />
        For help call 555-555-1212.
      </p>

      <div className="schedule-area">
        <div className="calendar-wrap">
          <Calendar
            nextLabel={
              <span
                className="cal-arrow"
                style={{ backgroundImage: `url('${RightArrow}')` }}
              />
            }
            prevLabel={
              <span
                className="cal-arrow"
                style={{ backgroundImage: `url('${LeftArrow}')` }}
              />
            }
            defaultView={"month"}
            next2Label={null}
            prev2Label={null}
            showNeighboringMonth={false}
            onChange={onDateSelected}
            value={date}
          />
        </div>

        {showShedule === false && (
          <div className="select-a-day">Select a day to add your treaments</div>
        )}

        {showShedule === true && (
          <div className="schedule-time-wrap">
            <div className="schedule-time shadow-box-small box-padding">
              <div className="schedule-head">
                <div className="schedule-day">
                  <div>
                    {date.toLocaleDateString("default", { weekday: "long" })}
                  </div>
                  <div className="schedule-date">
                    {`${date.toLocaleString("default", {
                      month: "long",
                    })} ${date.getDate()}`}
                  </div>
                </div>
                <div className="schedule-close">
                  <button
                    onClick={onClose}
                    style={{ backgroundImage: `url('${CloseIcon}')` }}
                  />
                </div>
              </div>
              <hr />

              {finished === false && (
                <React.Fragment>
                  <div className="schedule-options">
                    <Checkbox
                      checked={visit}
                      onChange={(e) => setVisit(!visit)}
                    >
                      Site Visit
                    </Checkbox>
                    <Checkbox checked={exam} onChange={(e) => setExam(!exam)}>
                      Exam and/or Assessments
                    </Checkbox>
                    <Checkbox
                      checked={blood}
                      onChange={(e) => setBlood(!blood)}
                    >
                      Blood and/or Urine Samples
                    </Checkbox>
                  </div>
                  <hr />
                  <span className="schedule-hours-title">Start Time</span>
                  <div className="schedule-hours">
                    <InputMask
                      type="text"
                      mask="99:99"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      className="hours-entry"
                      placeholder="Optional"
                    />
                    <button
                      className={`time-am-pm ${ampm === "AM" ? "active" : ""}`}
                      onClick={() => setAMPM("AM")}
                    >
                      AM
                    </button>
                    <button
                      className={`time-am-pm ${ampm === "PM" ? "active" : ""}`}
                      onClick={() => setAMPM("PM")}
                    >
                      PM
                    </button>
                  </div>
                </React.Fragment>
              )}

              {finished === true && (
                <div className="finished-content">Site Visit</div>
              )}
            </div>

            {finished === false && (
              <button onClick={onSave} className="primary save-appt-button">
                SAVE
              </button>
            )}

            {finished === true && (
              <React.Fragment>
                <button className="tertiary save-appt-button">DONE</button>
                <div
                  className="add-to-calendar"
                  style={{ backgroundImage: `url('${CalendarIcon}')` }}
                >
                  Add to My Calendar
                </div>
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Appointment;
