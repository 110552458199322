import React, { useState, useEffect, useCallback } from "react";
import InputMask from "react-input-mask";
import shortid from "shortid";
import "./formFields.less";

//images
import EyeShow from "../../assets/icons/eye-show.svg";
import EyeHide from "../../assets/icons/eye-hide.svg";

const FormField = ({
  label,
  errorMessage = "",
  className = "",
  children,
  isRequired = false,
  validate = false,
}) => {
  return (
    <div
      className={`form-field ${className} ${
        errorMessage.length > 0 && validate ? "error" : ""
      }`}
    >
      <span className="form-field-label">
        {label}
        {isRequired === true && (
          <React.Fragment>
            &nbsp;<span className="torch bold">*</span>
          </React.Fragment>
        )}
      </span>
      {children}
      {errorMessage.length > 0 && validate && (
        <div className="form-field-error-msg">
          <i className="material-icons">warning</i>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export const Password = ({
  label = "Password",
  value,
  className,
  onChange = () => {},
  hidden = true,
  isRequired = true,
  requiredLabel = "Input required",
  onValidate = () => {},
}) => {
  const [validate, setValidate] = useState(false);
  const [hiddenValue, setHiddenValue] = useState(hidden);
  const [error, setError] = useState("");

  const handleChange = useCallback(
    (e) => {
      onChange(e);
      setValidate(true);

      //pass
      onValidate(true);
      setError("");
    },
    [onValidate, onChange]
  );

  const onToggle = () => {
    setHiddenValue(!hiddenValue);
  };

  useEffect(() => {
    //require check
    if (isRequired === true && value === "") {
      setError(requiredLabel);
      onValidate(false);
      return;
    }
  }, [value, isRequired, requiredLabel, onValidate]);

  return (
    <FormField
      label={label}
      validate={validate}
      isRequired={isRequired}
      errorMessage={error}
      className={className}
    >
      <div className="password-field">
        <input
          type={hiddenValue ? "password" : "text"}
          value={value}
          onChange={handleChange}
          autoComplete="off"
        />

        <div className="password-icon" onClick={onToggle}>
          <span
            style={{
              backgroundImage: `url(${hiddenValue ? EyeHide : EyeShow})`,
            }}
            className="pass-eye"
          ></span>
        </div>
      </div>
    </FormField>
  );
};

export const Phone = ({
  label = "Phone",
  value,
  className,
  onChange = () => {},
  isRequired = true,
  requiredLabel = "Input required",
  invalidLabel = "Invalid input specified",
  onValidate = () => {},
}) => {
  const [validate, setValidate] = useState(false);
  const [error, setError] = useState("");

  const handleChange = useCallback(
    (e) => {
      const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

      onChange(e);
      setValidate(true);

      //validate
      if (e.target.value.length > 0 && !re.test(e.target.value)) {
        setError(invalidLabel);
        onValidate(false);
        return;
      }

      onValidate(true);
      setError("");
    },
    [onValidate, invalidLabel, onChange]
  );

  useEffect(() => {
    //require check
    if (isRequired === true && value === "") {
      setError(requiredLabel);
      onValidate(false);
      return;
    }
  }, [value, isRequired, requiredLabel, onValidate]);

  return (
    <FormField
      label={label}
      validate={validate}
      isRequired={isRequired}
      errorMessage={error}
      className={className}
    >
      <InputMask
        type="text"
        mask="(999) 999-9999"
        value={value}
        onChange={handleChange}
      />
    </FormField>
  );
};

export const Email = ({
  label = "Email",
  value,
  className,
  onChange = () => {},
  isRequired = true,
  requiredLabel = "Input required",
  invalidLabel = "Invalid input specified",
  disable = false,
  onValidate = () => {},
}) => {
  const [validate, setValidate] = useState(false);
  const [error, setError] = useState("");

  const handleChange = useCallback(
    (e) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      onChange(e);
      setValidate(true);

      //validate
      if (e.target.value.length > 0 && !re.test(e.target.value)) {
        setError(invalidLabel);
        onValidate(false);
        return;
      }

      onValidate(true);
      setError("");
    },
    [onValidate, invalidLabel, onChange]
  );

  useEffect(() => {
    //require check
    if (isRequired === true && value === "") {
      setError(requiredLabel);
      onValidate(false);
      return;
    }
  }, [value, isRequired, requiredLabel, onValidate]);

  return (
    <FormField
      label={label}
      validate={validate}
      isRequired={isRequired}
      errorMessage={error}
      className={className}
    >
      <input
        type="text"
        value={value}
        onChange={handleChange}
        disabled={disable}
      />
    </FormField>
  );
};

export const Input = ({
  label = "",
  value,
  className,
  onChange = () => {},
  isRequired = true,
  requiredLabel = "Input required",
  onValidate = () => {},
}) => {
  const [validate, setValidate] = useState(false);
  const [error, setError] = useState("");

  const handleChange = useCallback(
    (e) => {
      onChange(e);
      setValidate(true);
      setError("");
    },
    [onChange]
  );

  useEffect(() => {
    //require check
    if (isRequired === true && value === "") {
      setError(requiredLabel);
      onValidate(false);
      return;
    }
  }, [value, isRequired, requiredLabel, onValidate]);

  return (
    <FormField
      label={label}
      validate={validate}
      isRequired={isRequired}
      errorMessage={error}
      className={className}
    >
      <input type="text" value={value} onChange={handleChange} />
    </FormField>
  );
};

export const Checkbox = ({
  id = "",
  name = "",
  className = "",
  checked = false,
  value = "",
  onChange = () => {},
  children,
}) => {
  if (id.length === 0) id = shortid.generate();

  return (
    <div className={`checkbox ${className}`}>
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value}
        checked={checked === true ? "checked" : ""}
        onChange={onChange}
      />
      <label htmlFor={id}>{children}</label>
    </div>
  );
};

export const Radio = ({
  id = "",
  name = "",
  className = "",
  radioGroup = "",
  checked = false,
  value = "",
  onChange = () => {},
  children,
}) => {
  if (id.length === 0) id = shortid.generate();

  return (
    <label className={`radio ${className}`}>
      {children}
      <input
        type="radio"
        id={id}
        radioGroup={radioGroup}
        name={name}
        value={value}
        checked={checked === true ? "checked" : ""}
        onChange={onChange}
      />
      <span className="radiocheck" />
    </label>
  );
};
