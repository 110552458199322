import React from "react";
import { NavLink } from "react-router-dom";
import "./linkList.less";

import RightArrow from "../../assets/icons/ic_circle-arrow-right Torch.png";

const LinkList = ({ items }) => {
  const list = items.map((item) => (
    <li key={item.text}>
      <NavLink to={item.to} style={{ backgroundImage: `url('${RightArrow}')` }}>
        {item.text}
      </NavLink>
    </li>
  ));

  return <ul className="linked-list">{list}</ul>;
};

export default LinkList;
