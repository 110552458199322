import React from "react";
import "./index.less";
import { Link } from "react-router-dom";
const Terms = ({ Isinvitation = false }) => {
  window.gtag("event", "page_view", {
    page_title: "Terms of Use",
    page_path: window.location.pathname,
  });
  return (
    <div
      className={
        Isinvitation ? "regular-text-container-Int" : "regular-text-container"
      }
    >
      <h1 className={Isinvitation ? "margin-Bottom-Invitation" : ""}>
        Terms of Use
      </h1>
      <h3
        className={
          Isinvitation ? "Inv-H3 regular-Center-Inti regular-margin-Inti" : ""
        }
      >
        {Isinvitation
          ? "PLEASE READ CAREFULLY THE FOLLOWING TERMS OF USE, WHICH APPLY TO EVERYONE WHO VISITS THE WEBSITE."
          : "Please read carefully the following terms of use, which apply to everyone who visits the website."}
      </h3>
      <h3
        className={
          Isinvitation
            ? "margin-Bottom-Invitation regular-Center-Inti regular-margin-Inti"
            : ""
        }
      >
        {Isinvitation
          ? "THIS WEBSITE IS INTENDED SPECIFICALLY FOR U.S. RESIDENTS ONLY"
          : " This website is intended specifically for u.s. Residents only"}
      </h3>
      <h2>Using the Service</h2>

      <p>
        CSL Behring works with clinical trial sites who may provide you with
        information relating to clinical trials or your participation in
        clinical trials. You may use this website to access information from
        your clinical trial site relating to the clinical study in which you are
        a participant. CSL Behring is not responsible for the accuracy of any of
        the information provided by a clinical trial site found on this website.
        By accessing information on this website, you agree that any information
        you provide to, or access through this website is either yours, or that
        you have the authority to access it or use it. You may not use this
        website to gain unauthorized access to information, including anyone
        else's information, information from any clinical trial you are not
        participating in, or information relating to any other clinical trial
        participant.
      </p>
      <p>
        You will need to use a user name and password to access the information
        on this website related to the clinical trial you in which you are a
        participant. If you give your user name and/or password to anyone else,
        that person could be able to view or access your personal information
        through this website. "Personal Information" is any information provided
        to the website that either by itself or with other information
        personally identifies you. Your Personal Information would include
        information like your name, address, information about your health,
        diagnoses, including information considered “personally identifiable
        information” or “protected health information” under applicable laws,
        rules or regulations.
      </p>
      <p>
        CSL Behring may revise this website from time to time with new or
        different features or information. Any changes that CSL Behring makes
        will be considered part of this website. You acknowledge that there may
        also be events that are beyond CSL Behring’s control, including failures
        of telecommunications lines, which may cause disruptions in this
        website. By using this website, you agree that CSL Behring will not be
        liable for or responsible to you for any disruptions or failures of this
        website.{" "}
      </p>

      <h2>Description of Service</h2>

      <p>
        This website platform is developed by CSL Behring, the sponsor of the
        clinical study, and hosted by Anexinet Corporation CSL Behring’s hosting
        service provider. The information specific to your clinical trial
        appearing on this website is offered to you by your clinical trial site
        through a link provided to you. This website platform is designed to
        allow you to access information provided by your clinical trial site,
        your study doctor, and/or the clinical trial site staff, related to your
        participation in a clinical trial sponsored by CSL Behring. Any
        information relating to a clinical trial you are participating in must
        be approved by both your clinical trial site, and the institutional
        review board (IRB), who is responsible for assuring the rights and
        protection of study subjects in clinical research, prior to it being
        uploaded by your clinical trial site onto this website.
      </p>
      <p>
        CSL Behring makes no warranty concerning the quality, accuracy, or
        completeness of the information provided to you by your clinical trial
        site, your study doctor, or the study staff through this website, and
        you agree that CSL Behring has no responsibility or liability for the
        information uploaded or maintained by your clinical trial site, your
        study doctor, or the study staff. Nothing contained herein precludes you
        from seeking to collect compensation for injury related to malpractice,
        fault, or blame on the part of those involved in clinical trial in which
        you participate. If you have any questions or concerns about your
        personal information or the information on this website relating to your
        clinical trial, please contact your study doctor or study staff at your
        clinical trial site. If you have any questions relating to your
        participation as a clinical trial research subject, please contact the
        IRB assigned to your clinical trial site.
      </p>
      <p>
        <span className="CSl-Terms-float">
          By using this website, you agree to the terms of the most recent
          version of these Terms of Use. Please read our
        </span>
        <Link
          to={"/privacy"}
          className="CSL-Terms-link CSl-Terms-float"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy{" "}
        </Link>
        in order to understand how your personal information may be used and
        disclosed by CSL Behring. Except as written in any other disclaimers,
        policies, terms of use, consents, or other notices on this website,
        these Terms of Use and the Privacy Policy are the complete agreement
        between you and CSL Behring with respect to your use of this website.
        You may, however, be subject to additional terms that may apply when you
        access particular services or materials on certain areas in this
        website, or by following a link from this website to other websites
        (which websites' terms of use and privacy policies you should review and
        consider separate and apart from CSL Behring's Terms of Use and Privacy
        Policy).
      </p>
      <p>
        CSL Behring reserves the right to make additions, deletions, or
        modifications to these Terms of Use at any time without prior
        notification. Your use of this website constitutes your agreement to
        this right. Indicated at the bottom of these Terms of Use is the date
        when they were last updated.
      </p>
      <h2>Website Information</h2>

      <p>
        As a leading innovation-driven Pharmaceutical company, CSL Behring is
        committed to playing a major role in improving the quality of life for
        patients with rare and serious disorders. To facilitate the use of this
        website, we provide you with tailored information dependent upon the
        purpose of your respective visit, be it as a member of the general
        public, a potential or enrolled study subject, a healthcare
        professional, or a caregiver.
      </p>
      <p>
        If you are participating in a clinical trial sponsored by CSL Behring,
        you may receive information and communication from your clinical trial
        site related to your participation in the clinical trial through this
        website. For information related to your participation in the clinical
        trial, you should consult your informed consent form, as well as other
        documents provided by your clinical trial site.
      </p>
      <p>
        Any content available on this website is not a substitute for the
        professional judgment of a health care professional in diagnosing and
        treating patients or clinical trial participants. Neither content nor
        any other service offered through the website is intended to be
        substituted for medical diagnosis or treatment. CSL Behring is not
        responsible for the accuracy of any information or content provided or
        sent to you by your clinical trial site, your study doctor, or the
        clinical trial site staff. You are responsible for verifying the
        accuracy of any information you send or receive through this website,
        including any of your Personal Information, data, or records.
      </p>
      <h3>
        Please note that no information in this website is provided with the
        intention of nor should it be viewed as csl behring giving medical
        advice or instruction on the correct use of products manufactured or
        distributed by csl behring.
      </h3>

      <p>
        Any healthcare-related questions should be immediately reported to your
        study doctor, physician or other healthcare provider. Never disregard or
        delay seeking or following medical advice because of something you have
        read here. It is important to follow the advice of your study doctor
        and/or study staff, physician, and other health care professionals
        regarding your individual medical and health care needs. Please consult
        with your study doctor, physician, or other health care professional
        before using any drug product, including any investigational drug
        product, discussed within this website. CSL Behring is not engaged in
        rendering medical advice or services.
      </p>

      <h2>Scope of Use</h2>

      <p>
        Except for our website visitors’ downloading of a single copy of this
        website for personal, informational, non-commercial purposes, no content
        on this website is to be copied, downloaded, or stored for any other
        purpose, nor is such content to be disseminated to any party or for any
        purpose, without the express, written permission of CSL Behring. CSL
        Behring retains the right, at any time, without prior notice, and with
        or without cause, to restrict or terminate a visitor’s use of or access
        to this website.
      </p>

      <h3>No Guarantee or Warranty</h3>

      <p>
        Csl behring endeavors to keep this website up-to-date. However, the
        content on this website is provided without warranty of any kind either
        express or implied, including, but not limited to, any implied
        warranties of merchantability, fitness for a particular purpose,
        accuracy, completeness, and non-infringement. Content on this website is
        provided on an "as is" and "as available" basis. Csl behring makes no
        representations or warranties regarding this website and its content
        regarding accuracy, completeness, currency, or reliability, or that the
        use of this website will be uninterrupted or error-free. Visitors are
        responsible for the verification of any information before acting in
        reliance upon such information, and use of this website is at the sole
        risk of visitors to it. Visitors are also responsible for taking all
        precautions necessary to ensure that any content that is obtained is
        virus-free. To the extent that applicable state law restricts or limits
        implied warranties, one or more of the above limitations may not apply
        in particular cases.
      </p>
      <h3>Limitation of Liability</h3>
      <p>
        Except as otherwise in writing or as required by applicable law or
        regulation, csl behring specifically disclaims any liability, whether
        based in contract, tort, strict liability or otherwise, for any damages,
        whether direct, indirect, incidental, consequential, or special, arising
        out of or in any way connected with access to or use of this website and
        its content, regardless of whether or not csl behring has been advised
        of the possibility of such damages. This limitation of liability
        includes, but is not limited to, any reliance by any party on any
        content that is obtained directly or indirectly through the use of this
        website, as well as any damages that arise as a result of viruses,
        interruptions in telecommunications connections to the website, or
        delays, mistakes, or omissions in transmissions to or from the visitor,
        whether caused by theft, destruction, negligence, war, terrorism, acts
        of god, telecommunications failure, or any unauthorized access to the
        website or its information. Visitors use this website and its contents
        at their own risk. Nothing contained herein precludes you from seeking
        to collect compensation for any injury related to malpractice, fault, or
        blame on the part of those involved in research in which you
        participate.
      </p>
      <h3>Links to Other Websites</h3>
      <p>
        For the convenience of our visitors, CSL Behring provides links to other
        websites. However, certain links on this website lead to resources
        located on servers maintained by third parties over whom CSL Behring has
        no control. CSL Behring is not responsible for third party content or
        the consequences of one of our visitor’s use thereof; consequently,
        access to and use of such websites is at the risk of visitors to such
        websites. Such links do not and should not imply CSL Behring’s
        endorsement of material on any other website. CSL Behring expressly
        disclaims any liability with regard to access to such websites.
      </p>
      <h3>Hold Harmless</h3>
      <p>
        Visitors who violate any of the terms of these Terms of Use or otherwise
        misuse this website shall indemnify and hold harmless CSL Behring
        (including its officers, directors, employees, agents, and licensees)
        from any liability that arises from or is otherwise related to such
        violation and/or misuse, including but not limited to expenses, costs,
        and attorneys’ fees.
      </p>
      <h3>Expert Advice or Opinion</h3>
      <p>
        As a useful resource to our visitors, this website may contain expert
        opinions. Information in this website identified as expert opinion, or
        accessed from this website by hyperlink, represents the opinions of
        these respective experts, which are not necessarily those of CSL
        Behring. Such experts are not employees of CSL Behring and do not
        receive any compensation from CSL Behring for the use of their opinions.
        CSL Behring does not endorse, and is not responsible for, the accuracy
        or completeness of any information or opinions set forth in such
        materials. Please note that expert advice reflects only the personal
        view of that expert -- in no case shall it be regarded as the opinion or
        responsibility of CSL Behring.{" "}
      </p>
      <h3>Country-specific Product Information</h3>
      <p>
        CSL Behring provides information with respect to its global and local
        pharmaceutical business that might not be relevant to every visitor
        around the world. For example, trademarks used for certain products may
        differ from country to country. Furthermore, in some countries
        regulatory authorities may approve products for sale with different
        indications, dosages, and restrictions. If CSL Behring's products,
        programs and services mentioned in this website are not available in
        your country, any such reference does not imply that CSL Behring intends
        to announce these products, programs, or services in your country.
        Consult your local CSL Behring contact or your physician for further
        information as to the availability of our products in your country. Due
        to laws specific to different jurisdictions, we may not be able to
        provide all visitors with information on our prescription drugs. CSL
        Behring intends to disclose country-specific product information
        primarily to healthcare professionals and, only where permissible, to
        the general public.
      </p>
      <h3>Communities</h3>
      <p>
        In any forum for discussion on this website, CSL Behring expects the
        participants to observe common rules for communication, such as mutual
        respect for others. Visitors must not distribute or publish any
        offensive, abusive, indecent, defamatory, or obscene material or any
        material that breaches a third party’s intellectual property rights.
        <a
          href="https://www.cslbehring.com/social-guidelines"
          target="_blank"
          className="CSL-Terms-link"
          rel="noreferrer"
        >
          Read our Social Media Community Guidelines.
        </a>
      </p>
      <h3>Information Deemed Non-Confidential</h3>
      <p>
        Unless stated otherwise in our Privacy Policy or your informed consent
        forms, any communication from you to CSL Behring or its hosting
        provider, Anexinet Corporation via this website shall be deemed to be
        non-confidential and non-proprietary. Subject to applicable laws, CSL
        Behring shall be free to use any such information, including but not
        limited to ideas, concepts, know-how, or techniques, contained in such
        communication for any purpose, without compensation to you and without
        identifying the source, and you grant to CSL Behring the irrevocable
        right to copy, modify, publish, perform, transmit, and display such
        information via any media in accordance with these Terms of Use. You
        agree that any communication from you to CSL Behring will be submitted
        or otherwise provided to CSL Behring in accordance with applicable law,
        including but not limited to, obtaining any necessary consents or
        authorizations from individuals prior to the submission of health or
        other personal information. You will not submit anything to this website
        that contains any viruses or other software that may adversely affect
        the operation of this website, or another party’s computer or
        information system.
      </p>
      <h3>Intellectual Property</h3>
      <p>
        Please note that it is CSL Behring's policy to enforce its intellectual
        property rights to the utmost extent as set forth in applicable laws,
        including criminal sanctions. The trademarks, logos, and service marks
        (collectively the "Trademarks") displayed on this website are Trademarks
        owned or licensed by CSL Behring. Nothing on this website should be
        construed as granting, by implication or otherwise, any license or right
        in or to the Trademarks without the express written permission of CSL
        Behring or, if not CSL Behring, the owner of the Trademark. In addition,
        any reproduction, retransmission, modification or use of this website's
        content for other than personal, non-commercial purposes requires the
        prior written consent of CSL Behring. The following copyright notice
        must appear on every copy of the contents of this website or a portion
        thereof: Copyright © 2021 CSL Behring.
      </p>
      <h3>News Releases</h3>
      <p>
        This website may link to CSL Behring websites that contain press
        releases and other news regarding CSL Behring, which CSL Behring
        believed to be accurate as of the date that it was prepared and/or
        posted on this website. Nevertheless, CSL Behring hereby disclaims any
        obligation to update such press releases and other news, and/or the
        information contained therein. In no event should any information about
        companies, other than CSL Behring, be relied upon as having been
        provided by CSL Behring, nor does the appearance of such information
        about other companies on this website constitute an endorsement by CSL
        Behring of the accuracy or completeness of such information.
      </p>
      <h3>Security Obligations of Visitors</h3>
      <p>
        In some cases, visitors to this website may be required to register or
        to obtain a password prior to gaining access to certain provisions of
        the website or to certain services provided by the website. If so, it is
        the responsibility of the visitor to ensure that the confidentiality of
        the registration information and password is maintained. The visitor
        shall be responsible for all uses of such password, whether or not the
        visitor has authorized such use.
      </p>
      <h3>Applicable Laws</h3>
      <p>
        These terms of use and the resolution of any dispute related to these
        terms of use shall be construed in accordance with the laws of the
        Commonwealth of Pennsylvania. Any dispute between CSL Behring and you
        related to these terms of use or this website shall be resolved
        exclusively by the state and federal courts of the Commonwealth of
        Pennsylvania.
      </p>
      <p>
        This website can be accessed from the United Stated and other countries
        worldwide. Since the laws of each State or country may differ, you agree
        that the statutes and laws of the Commonwealth of Pennsylvania, without
        regard to any principles of conflicts of law, will apply to all matters
        relating to the use of this website.
      </p>
      <p>Last updated: February 2021</p>
    </div>
  );
};

export default Terms;
