import React from "react";

const GoogleMarker = () => {
  return (
    <div>
      <div className="pin bounce" />
    </div>
  );
};
export default GoogleMarker;
