export const whatIsDKD = () => {
    return (<div class="Praesent-commodo-cur COndition1-Answer">
    <p>
      Diabetic kidney disease is the most common cause of end-stage renal disease, 
      and its prevalence continues to grow worldwide. Diabetic kidney disease is first 
      detected by the abnormal loss of small amounts of Protein (Albumin) in the urine 
      (microalbuminuria). As DKD progresses, as seen with more protein found in the urine, 
      caused by loss of kidney function. Eventually, renal replacement therapy is needed. 
      The standard of care therapy is to slow the decline in kidney function in patients 
      with DKD consists of glycemic control, to use drugs such as renin-angiotensin system 
      (RAS) inhibitors, and manage hypertension and hyperlipidemia.The standard of care therapy
      is to slow the decline in kidney function in patients with DKD consists of glycemic control,
      to use drugs such as renin-angiotensin system (RAS) inhibitors, and manage hypertension 
      and hyperlipidemia.
    </p>              
  </div>)    
}