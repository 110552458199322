import React from "react";
import "./howClinicalTrialWork.less";
import { gql, useQuery } from "@apollo/client";
import ClinicalTrialPlay from "./clinicalTrialPlay";
import Spinner from "../shared/spinner";
import BackButton from "../shared/backButton";

const GET_GUIDES_TWO = gql`
  query GetGuidesTwo($pageId: String) {
    getGuidesTwo(where: { pageId: $pageId }) {
      data {
        pageTitle
        crumbs1
        crumbs2
        crumbs3
        backButtonText
        circleArrowLeftTorch
        mainText
        medicineSectionTitle
        stepsTitles
        stepsImages
        stepsTexts
      }
    }
  }
`;

const LearnGuide2 = () => {
  const { data, loading, error } = useQuery(GET_GUIDES_TWO, {
    variables: { pageId: "cpex_guides_two" },
    errorPolicy: "all",
  });
  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;

  window.gtag("event", "page_view", {
    page_title: "How Clinical Trials Work",
    page_path: window.location.pathname,
  });

  return (
    <React.Fragment>
      <div className="Guides---What-is-a-Clinical-Trial---S">
        <div className="Div-How-CL-Work-cann2">
          <BackButton
            ButtonText={data.getGuidesTwo.data.backButtonText}
            Crumb1={data.getGuidesTwo.data.crumbs1}
            Crumb2={data.getGuidesTwo.data.crumbs2}
            Crumb3={data.getGuidesTwo.data.crumbs3}
            Path1="/"
            Path2="/learn"
          ></BackButton>
        </div>
        <div className="How-Clinical-Trials">
          {data.getGuidesTwo.data.pageTitle}
        </div>
        <div className="Clinical-trials-play">
          {data.getGuidesTwo.data.mainText}
        </div>
        <div className="How-medicine-gets-fr-header">
          {data.getGuidesTwo.data.medicineSectionTitle}
        </div>
        <ClinicalTrialPlay
          mainText={data.getGuidesTwo.data.stepsTitles[0]}
          medicineSectionTitle={data.getGuidesTwo.data.stepsTexts[0]}
          stepsImages={data.getGuidesTwo.data.stepsImages[0]}
          classname1="font-20"
        ></ClinicalTrialPlay>
        <ClinicalTrialPlay
          mainText={data.getGuidesTwo.data.stepsTitles[1]}
          medicineSectionTitle={data.getGuidesTwo.data.stepsTexts[1]}
          stepsImages={data.getGuidesTwo.data.stepsImages[1]}
          classname1="font-20"
        ></ClinicalTrialPlay>
        <ClinicalTrialPlay
          mainText={data.getGuidesTwo.data.stepsTitles[2]}
          medicineSectionTitle={data.getGuidesTwo.data.stepsTexts[2]}
          stepsImages={data.getGuidesTwo.data.stepsImages[2]}
          classname1="font-20"
        ></ClinicalTrialPlay>
        <ClinicalTrialPlay
          mainText={data.getGuidesTwo.data.stepsTitles[3]}
          medicineSectionTitle={data.getGuidesTwo.data.stepsTexts[3]}
          stepsImages={data.getGuidesTwo.data.stepsImages[3]}
          classname1="font-20"
          classname2="phase3"
        ></ClinicalTrialPlay>
        <ClinicalTrialPlay
          mainText={data.getGuidesTwo.data.stepsTitles[4]}
          medicineSectionTitle={data.getGuidesTwo.data.stepsTexts[4]}
          stepsImages={data.getGuidesTwo.data.stepsImages[4]}
          classname1="font-20 margin-btm24"
          classname2="margintop0"
          classname3="width-100"
        ></ClinicalTrialPlay>
        <ClinicalTrialPlay
          mainText={data.getGuidesTwo.data.stepsTitles[5]}
          medicineSectionTitle={data.getGuidesTwo.data.stepsTexts[5]}
          stepsImages={data.getGuidesTwo.data.stepsImages[7]}
          classname1="font-20"
          classname2="Results-Evaluated"
        ></ClinicalTrialPlay>
        <ClinicalTrialPlay
          mainText={data.getGuidesTwo.data.stepsTitles[6]}
          medicineSectionTitle={data.getGuidesTwo.data.stepsTexts[6]}
          stepsImages={data.getGuidesTwo.data.stepsImages[5]}
          classname1="font-20"
        ></ClinicalTrialPlay>
        <ClinicalTrialPlay
          mainText={data.getGuidesTwo.data.stepsTitles[7]}
          medicineSectionTitle={data.getGuidesTwo.data.stepsTexts[7]}
          stepsImages={data.getGuidesTwo.data.stepsImages[6]}
          classname=""
          classname1="font-20"
        ></ClinicalTrialPlay>
        <div class="Praesent-commodo-cur COndition1-Answer">
          <p>Source: CISCRP (Center for Information and Study on 
            Clinical Research Participation)
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};
export default LearnGuide2;
