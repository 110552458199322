import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
//import ImageButton from "../shared/imageButton";
import { Email, Password, Checkbox } from "../shared/formFields";
import PasswordValidator from "../shared/passwordValidator";
import { gql, useMutation, useQuery } from "@apollo/client";
import ButtonLoader from "../shared/buttonLoader";
import {
  AUTH_TOKEN,
  AUTH_EXPIRES,
  IS_FIRST_TIME,
  IS_USER_EMAILID,
} from "../../constants";
import { isLoggedInVar, client as apiClient } from "../../api/apiClient";
import Spinner from "../shared/spinner";
import "./index.less";

const GET_LOGIN = gql`
  query GetLogin($loginId: String) {
    getLogin(where: { loginId: $loginId }) {
      data {
        header
        cancel
        email
        emailRequired
        emailInvalid
        accessCode
        accessCodeRequired
        accessCodeDescription
        password
        createPassword
        passwordRequired
        passwordRequirement
        forgotPassword
        agreement
        readDisclaimer
        readDisclaimerLink
        signIn
        passwordLength
        passwordUpper
        passwordLower
        passwordSpecial
        passwordNumber
      }
    }
  }
`;

const LOGIN_MUTATION = gql`
  mutation Login(
    $email: String!
    $accessCode: String!
    $password: String!
    $termsOfUseAcceptance: Boolean!
    $keepMeUpdatedAcceptance: Boolean!
  ) {
    cpex {
      login(
        email: $email
        accessCode: $accessCode
        password: $password
        termsOfUseAcceptance: $termsOfUseAcceptance
        keepMeUpdatedAcceptance: $keepMeUpdatedAcceptance
      ) {
        data {
          token
          expires_on
        }
        error {
          code
          message
          data
        }
      }
    }
  }
`;
export const SIGN_UP = gql`
  mutation Signup(
    $email: String!
    $accessCode: String!
    $password: String!
    $terms: Boolean!
    $keepMeUpdated: Boolean!
  ) {
    cpex {
      signup(
        email: $email
        accessCode: $accessCode
        password: $password
        termsOfUseAcceptance: $terms
        keepMeUpdatedAcceptance: $keepMeUpdated
      ) {
        data {
          token
          expires_on
        }
        error {
          code
          message
          data
        }
      }
    }
  }
`;

const Signin = () => {
  const isFirstTime =
    localStorage.getItem(IS_FIRST_TIME) === null
      ? true
      : !localStorage.getItem(IS_FIRST_TIME);

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [accessCodeValid, setAccessCodeValid] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);
  const [agree, setAgree] = useState(!isFirstTime);
  const [keepMeUpdated, setKeepMeUpdated] = useState(!isFirstTime);
  const [emailInvalid, setemailInvalid] = useState("");
  const [accesscodeInvalid, setaccesscodeInvalid] = useState("");
  const [passwordInvalid, setpasswordInvalid] = useState("");

  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const onAccessCodeChange = (e) => {
    setAccessCode(e.target.value);
  };

  const onAccessCodeValid = (e) => {
    setAccessCodeValid(e);
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onEmailValid = (e) => {
    setEmailValid(e);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onPasswordValid = (e) => {
    setPasswordValid(e);
  };

  const onPasswordValidationChange = (e) => {
    setPasswordValid(e);
  };

  const onAgreeChange = (e) => {
    setAgree(e.target.checked);
  };

  const onKeepMeUpdatedChange = (e) => {
    setKeepMeUpdated(e.target.checked);
  };

  const [login] = useMutation(LOGIN_MUTATION, {
    client: apiClient,
    variables: {
      email: email,
      accessCode: accessCode,
      password: password,
      termsOfUseAcceptance: agree,
      keepMeUpdatedAcceptance: keepMeUpdated,
    },
    onCompleted: (data) => {
      setIsLoggingIn(false);
      if (data.cpex.login.error !== null) {
        // setErrorMessage(data.cpex.login.error.message);
        if (data.cpex.login.error.code === "INVALID_EMAIL") {
          setemailInvalid(
            "The email entered is incorrect.  Please enter the email address again.  If you have received this message before, click the Contact Technical Support link at the bottom of the page."
          );
        } else {
          setemailInvalid("");
        }
        if (data.cpex.login.error.code === "INVALID_PASSWORD") {
          setpasswordInvalid(
            "The password entered is incorrect.  Please enter the password again.  If you have received this message before, click the Contact Technical Support link at the bottom of the page."
          );
        } else {
          setpasswordInvalid("");
        }
        if (data.cpex.login.error.code === "INVALID_ACCESS_CODE") {
          setaccesscodeInvalid(
            "Invalid code, not an exact match. Use the access code provided by site coordinator."
          );
        } else if (data.cpex.login.error.code === "ACCESS_CODE_INVALID_CASE") {
          setaccesscodeInvalid(
            "You have entered the correct code, but the case sensitivity is incorrect.  Please re-enter the code using the correct upper and lower case letters. If you have received this message before, please contact your Site Coordinator."
          );
        } else {
          setaccesscodeInvalid("");
        }
        return;
      }

      sessionStorage.setItem(AUTH_TOKEN, data.cpex.login.data.token);
      sessionStorage.setItem(AUTH_EXPIRES, data.cpex.login.data.expires_on);
      localStorage.setItem(IS_USER_EMAILID, email);
      localStorage.setItem(IS_FIRST_TIME, false);
      isLoggedInVar(true);

      let redirect = query.get("redirect");

      if (redirect !== null) {
        history.push(redirect);
        return;
      }

      history.push("/trial");
    },
  });

  const [signup] = useMutation(SIGN_UP, {
    client: apiClient,
    variables: {
      email: email,
      accessCode: accessCode,
      password: password,
      terms: agree,
      keepMeUpdated: keepMeUpdated,
    },
    onCompleted: (data) => {
      setIsLoggingIn(false);
      if (data.cpex.signup.error !== null) {
        setErrorMessage(data.cpex.signup.error.message);
        return;
      }

      sessionStorage.setItem(AUTH_TOKEN, data.cpex.signup.data.token);
      sessionStorage.setItem(AUTH_EXPIRES, data.cpex.signup.data.expires_on);
      localStorage.setItem(IS_USER_EMAILID, email);
      //localStorage.setItem(IS_FIRST_TIME, false);
      isLoggedInVar(true);

      let redirect = query.get("redirect");

      if (redirect !== null) {
        history.push(redirect);
        return;
      }

      history.push("/invitation");
    },
  });

  const onSubmit = () => {
    if (emailValid && accessCodeValid) {
      setIsLoggingIn(true);
      if (window.location.pathname === "/signin") {
        window.gtag("event", "login");
        login();
      } else if (window.location.pathname === "/signup") {
        window.gtag("event", "signup");
        signup();
      }
    }
  };

  const { data, loading, error } = useQuery(GET_LOGIN, {
    variables: { pageId: "cpex_login" },
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;

  window.gtag("event", "page_view", {
    page_title: "Signin",
    page_path: window.location.pathname,
  });

  return (
    <div className="content-edge content-ceiling login-container">
      {/*!isFirstTime ? (
        <ImageButton type="close" text={data.getLogin.data.cancel} to="/" />
      ) : null*/}
      <div className="login-area">
        <h1>
          {window.location.pathname === "/signin"
            ? data.getLogin.data.header
            : "Sign Up"}
        </h1>
        <Email
          label={data.getLogin.data.email}
          value={email}
          onChange={onEmailChange}
          onValidate={onEmailValid}
          invalidLabel={data.getLogin.data.emailInvalid}
          requiredLabel={data.getLogin.data.emailRequired}
          className={emailInvalid === "" ? "margin-bottom-32" : ""}
        />
        {emailInvalid !== "" ? (
          <div className="form-field-error-msg margin-bottom-32">
            <i className="material-icons">warning</i>
            {emailInvalid}
          </div>
        ) : null}
        <Password
          label={data.getLogin.data.accessCode}
          value={accessCode}
          requiredLabel={data.getLogin.data.accessCodeRequired}
          onChange={onAccessCodeChange}
          onValidate={onAccessCodeValid}
        />
        <div className="form-field-error-msg">
          {accesscodeInvalid !== "" ? (
            <i className="material-icons">warning</i>
          ) : null}
          {accesscodeInvalid === ""
            ? "The access code is case sensitive"
            : accesscodeInvalid}
        </div>
        <p className="field-desc margin-bottom-32">
          {data.getLogin.data.accessCodeDescription}
        </p>

        <Password
          label={
            isFirstTime
              ? data.getLogin.data.createPassword
              : data.getLogin.data.password
          }
          value={password}
          requiredLabel={data.getLogin.data.passwordRequired}
          onChange={onPasswordChange}
          onValidate={onPasswordValid}
        />
        {passwordInvalid !== "" ? (
          <div className="form-field-error-msg margin-bottom-32">
            <i className="material-icons">warning</i>
            {passwordInvalid}
          </div>
        ) : null}
        {isFirstTime && (
          <React.Fragment>
            <p className="placeholder">
              {data.getLogin.data.passwordRequirement}
            </p>
            <PasswordValidator
              password={password}
              lengthText={data.getLogin.data.passwordLength}
              lowerText={data.getLogin.data.passwordLower}
              numberText={data.getLogin.data.passwordNumber}
              specialText={data.getLogin.data.passwordSpecial}
              upperText={data.getLogin.data.passwordUpper}
              onValidChange={onPasswordValidationChange}
            />
          </React.Fragment>
        )}

        {!isFirstTime && (
          <Link to="/forgotpassword" className="forgot-password link">
            {data.getLogin.data.forgotPassword}
          </Link>
        )}

        {isFirstTime && (
          <React.Fragment>
            <Checkbox
              onChange={onAgreeChange}
              checked={agree}
              className="margin-top-32"
            >
              By signing in, I accept the Terms of Use and Privacy Policy&nbsp;
            </Checkbox>

            <Checkbox
              onChange={onKeepMeUpdatedChange}
              checked={keepMeUpdated}
              className="margin-top-24"
            >
              Keep me updated on news and events&nbsp;
            </Checkbox>
          </React.Fragment>
        )}

        <div className="sign-in-options">
          <label className="login-error-message">{errorMessage}</label>
          <ButtonLoader
            onClick={onSubmit}
            loading={isLoggingIn}
            disabled={
              !emailValid || !accessCodeValid || !passwordValid || !agree
            }
          >
            {window.location.pathname === "/signin"
              ? data.getLogin.data.signIn
              : "SIGN UP"}
          </ButtonLoader>
        </div>
      </div>
    </div>
  );
};

export default Signin;
