import React, { useState } from "react";
import TooltipTrigger from "react-popper-tooltip";
import "./popover.less";
import "react-popper-tooltip/dist/styles.css";

import InfoIco from "../../assets/icons/ic_info.svg";

const Popover = ({ title = "", children }) => {
  //state
  const [popoverVisible, setPopoverVisible] = useState(false);

  const Tooltip = ({
    arrowRef,
    tooltipRef,
    getArrowProps,
    getTooltipProps,
    placement,
  }) => (
    <div
      {...getTooltipProps({
        ref: tooltipRef,
        className: "shadow-box popover-box",
      })}
    >
      <div className="popover-content">
        {title.length > 0 && (
          <React.Fragment>
            <span className="popover-title">
              <b>{title}</b>
            </span>
            <hr />
          </React.Fragment>
        )}
        {children}
      </div>

      <button onClick={onCloseClick} className="tertiary popover-close slim">
        Close
      </button>

      <div
        {...getArrowProps({
          ref: arrowRef,
          className: "tooltip-arrow",
          "data-placement": placement,
        })}
      />
    </div>
  );

  const Trigger = ({ getTriggerProps, triggerRef }) => (
    <span
      onClick={onTriggerClick}
      {...getTriggerProps({
        ref: triggerRef,
        className: "trigger info-icon popover-info-ico",
        style: { backgroundImage: `url('${InfoIco}')` },
      })}
    ></span>
  );

  const onPopoverVisibleChange = (e) => {
    setPopoverVisible(e);
  };

  const onTriggerClick = () => {
    setPopoverVisible(true);
  };

  const onCloseClick = () => {
    setPopoverVisible(false);
  };

  return (
    <TooltipTrigger
      placement="auto-end"
      trigger="click"
      tooltip={Tooltip}
      onVisibilityChange={onPopoverVisibleChange}
      tooltipShown={popoverVisible}
    >
      {Trigger}
    </TooltipTrigger>
  );
};

export default Popover;
