import React, { useState } from "react";
import { Link } from "react-router-dom";
import LinkList from "../shared/linkList";
import { ModalVideo } from "../shared/modal";
import "./dashboard.less";

import VisitOverview from "./visitOverview";
import VisitDetailed from "./visitDetailed";

import WatchImage from "../../assets/placeholder.png";
import PDFIcon from "../../assets/icons/ic_pdf.png";
import Banner from "../../assets/placeholder-banner.png";

const Dashboard = ({ cslData }) => {
  const showVideoBanner = false;
  const showVisits = false;
  const showStudyGuide = false;
  const showOverviewAndStudyGuide = true;
  const showVideoCallout = false;

  //state
  const [detailIndex, setDetailIndex] = React.useState(0);
  const [storyModalIsOpen, setStoryIsOpen] = React.useState(false);
  const [showOverview, setShowOverview] = React.useState(false);
  const [data, setData] = useState([
    {
      id: 1,
      title: "Visit 1",
      treatments: [
        {
          title: "Site Visit",
          type: "visit",
          description: "15-2 hours, plus infusion time",
        },
        {
          title: "Exam and/or Assessments",
          type: "vitals",
          description: "",
        },
        {
          title: "Blood and/or Urine Samples",
          type: "samples",
          description: "",
        },
      ],
      checked: false,
    },
    {
      id: 2,
      title: "Visit 2",
      treatments: [
        {
          title: "Site Visit",
          type: "visit",
          description: "15-2 hours, plus infusion time",
        },
        {
          title: "Exam and/or Assessments",
          type: "vitals",
          description: "",
        },
        {
          title: "Blood and/or Urine Samples",
          type: "samples",
          description: "",
        },
      ],
      checked: false,
    },
    {
      id: 3,
      title: "Visit 3",
      treatments: [
        {
          title: "Site Visit",
          type: "visit",
          description: "15-2 hours, plus infusion time",
        },
        {
          title: "Exam and/or Assessments",
          type: "vitals",
          description: "",
        },
        {
          title: "Blood and/or Urine Samples",
          type: "samples",
          description: "",
        },
      ],
      checked: false,
    },
  ]);
  const studyGuides = [
    {
      to: "/trial/study-guide/welcome",
      text: "Welcome",
    },
    {
      to: "/trial/study-guide/overview",
      text: "Overview",
    },
    {
      to: "/trial/study-guide/participation",
      text: "Your Participation",
    },
    {
      to: "/trial/study-guide/expectations",
      text: "What to Expect",
    },
    {
      to: "/trial/study-guide/study-tests-and-procedures",
      text: "Study Test and Procedures",
    },
    {
      to: "/trial/study-guide/other-tests-and-procedures",
      text: "Other Tests and Procedures",
    },
    {
      to: "/trial/study-guide/faq",
      text: "Frequently Asked Questions",
    },
    {
      to: "/trial/study-guide/study-visits",
      text: "Study Visits",
    },
    {
      to: "/trial/study-guide/your-privacy",
      text: "Your Privacy",
    },
  ];

  //layout settings

  const openStoryModal = (e) => {
    e.preventDefault();
    setStoryIsOpen(true);
  };

  const closeStoryModal = () => {
    setStoryIsOpen(false);
  };

  const onNext = () => {
    if (detailIndex + 1 >= data.length) return;
    setDetailIndex(detailIndex + 1);
  };
  const onPrevious = () => {
    if (detailIndex <= 0) return;
    setDetailIndex(detailIndex - 1);
  };

  const onCompletedChange = (v) => {
    let newData = data.map((item) => {
      if (item === v) {
        const updatedItem = { ...item, checked: !v.checked };
        return updatedItem;
      }

      return item;
    });

    setData(newData);
  };

  window.gtag("event", "page_view", {
    page_title: "Screened",
    page_path: window.location.pathname,
  });

  return (
    <div className="screened-content">
      <div className="dash-head-wrap">
        {showVideoBanner && (
          <div className="shadow-box-small meet-story callout-box box-spacing">
            <div className="callout-box-area callout-box">
              <div
                className="watch-img"
                onClick={openStoryModal}
                style={{ backgroundImage: `url(${WatchImage})` }}
              />
            </div>
            <div className="callout-box-area box-padding">
              <h2 className="force-mobile">Meet Sussane and her story</h2>
              <span className="watch-sub">Video | 2:45</span>
              <p>
                Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit
                tempus porttitor.
              </p>
              <Link
                to="/test"
                onClick={openStoryModal}
                className="primary watch-link"
              >
                WATCH HER STORY
              </Link>
              <ModalVideo
                isOpen={storyModalIsOpen}
                onRequestClose={closeStoryModal}
                link="https://www.youtube.com/embed/si4UOjPTThw?autoplay=1"
              />
            </div>
          </div>
        )}

        {showVisits && (
          <React.Fragment>
            <div className="visit-options">
              <button
                className={showOverview === false ? "active" : ""}
                onClick={() => setShowOverview(false)}
              >
                Detail by Visit
              </button>
              <button
                className={showOverview === true ? "active" : ""}
                onClick={() => setShowOverview(true)}
              >
                Overview
              </button>
            </div>
            <div className="visit-area-wrap box-spacing">
              <div className="shadow-box-small callout-box visit-content box-padding">
                {showOverview === true && (
                  <VisitOverview
                    Visits={data}
                    onCompletedChange={onCompletedChange}
                  />
                )}
                {showOverview === false && (
                  <VisitDetailed
                    Index={detailIndex}
                    onNext={onNext}
                    onPrevious={onPrevious}
                    Visits={data}
                    onCompletedChange={onCompletedChange}
                  />
                )}
              </div>
              <div className="dash-add-dates-wrap">
                <div className="shadow-box-small dash-add-dates-box callout-box box-padding">
                  <div className="add-dates-box-head">
                    Have you scheduled this vist with your site coordinator?
                  </div>
                  <Link className="tertiary slim" to="/trial/appointment">
                    Add Dates
                  </Link>
                  <div className="add-dates-box-footer">
                    Questions or to schedule your visit call{" "}
                    <span className="nowrap">(800) 555-1212</span>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>

      <div
        className="screened-banner"
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <h4>
          {cslData !== undefined &&
          cslData.cpex.getStudy.data !== null &&
          cslData.cpex.getStudy.data.dashboard !== undefined
            ? cslData.cpex.getStudy.data.dashboard.description
            : null}
        </h4>
      </div>

      {showStudyGuide && (
        <div className="trial-study-guide shadow-box-small callout-box box-padding">
          <div>
            <h3>Study Guide</h3>
            <p className="trial-study-desc">
              {cslData !== undefined &&
              cslData.cpex.getStudy.data !== null &&
              cslData.cpex.getStudy.data.dashboard.study_guide.content !==
                undefined
                ? cslData.cpex.getStudy.data.dashboard.study_guide.content
                : null}
            </p>
            <a
              href={
                cslData !== undefined &&
                cslData.cpex.getStudy.data !== null &&
                cslData.cpex.getStudy.data.dashboard.study_guide.pdf_link !==
                  undefined
                  ? cslData.cpex.getStudy.data.dashboard.study_guide.pdf_link
                  : null
              }
              className="plain"
              download
              target="_blank"
              rel="noreferrer"
            >
              <span
                className="pdf-img"
                style={{ backgroundImage: `url(${PDFIcon})` }}
              />
              Download Study Guide
            </a>
          </div>
          <LinkList items={studyGuides} />
        </div>
      )}

      {showOverviewAndStudyGuide && (
        <React.Fragment>
          <h2 className="overview-header">Overview and Study Guide</h2>
          <div className="study-area">
            <div className="shadow-box-small callout-box study-overview box-padding">
              <div className="study-content">
                <h3>
                  {cslData !== undefined &&
                  cslData.cpex.getStudy.data !== null &&
                  cslData.cpex.getStudy.data.study_name !== undefined
                    ? cslData.cpex.getStudy.data.study_name
                    : ""}{" "}
                  Study Overview
                </h3>
                <ul>
                  {cslData !== undefined &&
                  cslData.cpex.getStudy.data !== null &&
                  cslData.cpex.getStudy.data.dashboard.overview.content !==
                    undefined
                    ? cslData.cpex.getStudy.data.dashboard.overview.content.map(
                        (Data, Index) => {
                          return <li key={Index}>{Data}</li>;
                        }
                      )
                    : null}
                </ul>
                <span className="download-study-guide">
                  <a
                    href={
                      cslData !== undefined &&
                      cslData.cpex.getStudy.data !== null &&
                      cslData.cpex.getStudy.data.dashboard.overview.pdf_link !==
                        undefined
                        ? cslData.cpex.getStudy.data.dashboard.overview.pdf_link
                        : null
                    }
                    className="plain"
                    download
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span
                      className="pdf-img"
                      style={{ backgroundImage: `url(${PDFIcon})` }}
                    />
                    View Study Overview
                  </a>
                </span>
              </div>
            </div>

            <div className="shadow-box-small callout-box study-guide box-padding">
              <div className="study-content">
                <h3>
                  {cslData !== undefined &&
                  cslData.cpex.getStudy.data !== null &&
                  cslData.cpex.getStudy.data.study_name !== undefined
                    ? cslData.cpex.getStudy.data.study_name
                    : ""}{" "}
                  Study Guide
                </h3>
                <ul>
                  <li>
                    {cslData !== undefined &&
                    cslData.cpex.getStudy.data !== null &&
                    cslData.cpex.getStudy.data.dashboard.study_guide.content !==
                      undefined
                      ? cslData.cpex.getStudy.data.dashboard.study_guide.content
                      : null}
                  </li>
                </ul>
                <span className="download-study-guide">
                  <a
                    href={
                      cslData !== undefined &&
                      cslData.cpex.getStudy.data !== null &&
                      cslData.cpex.getStudy.data.dashboard.study_guide
                        .pdf_link !== undefined
                        ? cslData.cpex.getStudy.data.dashboard.study_guide
                            .pdf_link
                        : null
                    }
                    className="plain"
                    download
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span
                      className="pdf-img"
                      style={{ backgroundImage: `url(${PDFIcon})` }}
                    />
                    View Study Guide
                  </a>
                </span>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      {showVideoCallout && (
        <div className="trial-video-banner shadow-box-small">
          <div
            className="trial-video-preview"
            onClick={openStoryModal}
            style={{ backgroundImage: `url(${WatchImage})` }}
          />
          <div className="trial-video-content">
            <div className="inner">
              <h2 className="force-mobile">Meet Sussane and her story</h2>
              <div className="video-length">Video | 2:45</div>
              <p className="desc">
                Aenean lacinia bibendum nulla sed consectetur. Curabitur blandit
                tempus porttitor.
              </p>
            </div>
            <div className="watch-button-wrap">
              <button className="primary watch-button" onClick={openStoryModal}>
                WATCH HER STORY
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
