import React from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import Spinner from "../shared/spinner";

import "./footer.less";

const GET_FOOTER = gql`
  query GetFooter($footerId: String) {
    getFooter(where: { footerId: $footerId }) {
      data {
        copyright
        footerLogo
        footerLogoTag
        termsOfUse
        privacyPolicy
        podcast
        podcastImg
        podcastTag
        twitter
        twitterImg
        twitterTag
        facebook
        facebookImg
        facebookTag
        youTube
        youTubeImg
        youTubeTag
        instagram
        instagramImg
        instagramTag
      }
    }
  }
`;

const Footer = () => {
  const { data, loading, error } = useQuery(GET_FOOTER, {
    variables: { pageId: "cpex_footer" },
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;

  return (
    <footer>
      <div className="footerbox">
        <div className="logo">
          <Link to="/">
            <img
              src={data.getFooter.data.footerLogo}
              alt={data.getFooter.data.footerLogoTag}
            />
          </Link>
        </div>
        <div className="social">
          <a
            href={data.getFooter.data.podcast}
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={data.getFooter.data.podcastImg}
              alt={data.getFooter.data.podcastTag}
            />
          </a>
          <a
            href={data.getFooter.data.twitter}
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={data.getFooter.data.twitterImg}
              alt={data.getFooter.data.twitterTag}
            />
          </a>
          <a
            href={data.getFooter.data.facebook}
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={data.getFooter.data.facebookImg}
              alt={data.getFooter.data.facebookTag}
            />
          </a>
          <a
            href={data.getFooter.data.youTube}
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={data.getFooter.data.youTubeImg}
              alt={data.getFooter.data.youTubeTag}
            />
          </a>
          <a
            href={data.getFooter.data.instagram}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={data.getFooter.data.instagramImg}
              alt={data.getFooter.data.instagramTag}
            />
          </a>
        </div>
      </div>
      <div className="divider" />
      <div className="termsbox">
        <div className="links">
          <Link to="/terms">{data.getFooter.data.termsOfUse}</Link>
          <Link to="/cookie-policy">{"Cookie Policy"}</Link>
          <Link to="/privacy">{data.getFooter.data.privacyPolicy}</Link>
          <Link to="/technical-support">{"Technical Support"}</Link>
        </div>
        <span className="copyright">{data.getFooter.data.copyright}</span>
      </div>
    </footer>
  );
};

export default Footer;
