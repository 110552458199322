import React from "react";
import "./index.less";
import { gql, useQuery } from "@apollo/client";
import Spinner from "../shared/spinner";
export const GET_PRIVACY_POLICY = gql`
  query GetPrivacyPolicy($pageId: String) {
    getPrivacyPolicy(where: { pageId: $pageId }) {
      data {
        pageTitle
        mainText {
          type
          text
          position
        }
        subtitles {
          text
          position
        }
        complementaryText {
          type
          text
          content {
            type
            text
            text2
            position
          }
          position
        }
        sections {
          title
          content {
            type
            title
            text
            text2
            content {
              type
              title
              text
              text2
              cell1
              cell2 {
                type
                text
                position
              }
              position
            }
            position
          }
          position
        }
        effectiveAs
      }
    }
  }
`;
const Privacy = () => {
  const { data, loading, error } = useQuery(GET_PRIVACY_POLICY, {
    variables: { pageId: "cpex_privacy_policy_main" },
    errorPolicy: "all",
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;
  window.gtag("event", "page_view", {
    page_title: "Privacy",
    page_path: window.location.pathname,
  });
  const ImportantContent = [
    { data: `"we", "us", "CSL Behring"` },
    { data: "personal data" },
    { data: "Biometric Information" },
  ];
  const buildcontent = (content, type, Classname) => {
    let subitems = content.slice().sort((a, b) => a.position - b.position);
    let ListItem = subitems.filter((val, idx) => val.type === "list_item");
    return subitems.map((item, index) => {
      return (
        <React.Fragment key={index}>
          {item.type === "paragraph" && item.text !== null ? (
            <p className={Classname}>{item.text}</p>
          ) : item.type === "paragraph" && item.text === null ? (
            <div className={"contact-div " + Classname}>
              {buildlist(item.content, type)}
            </div>
          ) : item.type === "list_header" ? (
            <React.Fragment>
              <p className={Classname}>{item.text}</p>
              <ul className={"CSl-Ul-List " + Classname}>
                {buildlist(ListItem, "")}
              </ul>
            </React.Fragment>
          ) : item.type === "table" ? (
            buildTable(item.content)
          ) : item.type === "link" ? (
            <p key={index} className="contact-list CSL-p-black">
              {item.text}
              <a
                href={item.text2}
                className="table-link CSL-email"
                target="_self"
                rel="noreferrer"
              >
                {item.text2}
              </a>
            </p>
          ) : null}
        </React.Fragment>
      );
    });
  };
  const buildTable = (content) => {
    let BodyContent = content.filter((val, idx) => val.type === "table_row");
    let TableHeader = content.filter((val, idx) => val.type === "table_header");
    return (
      <table className="csl-table-table">
        <thead className="table-head">
          <tr>
            <th scope="col" className="csl-table-td csl-table-th">
              {TableHeader[0].text}
            </th>
            <th scope="col" className="csl-table-td csl-table-th">
              {TableHeader[0].text2}
            </th>
          </tr>
        </thead>
        <tbody>{buildTableRows(BodyContent)}</tbody>
      </table>
    );
  };
  const buildTableRows = (tableBody) => {
    let Content = tableBody.slice().sort((a, b) => a.position - b.position);
    return Content.map((item, index) => {
      return (
        <tr key={index}>
          <td className="csl-table-td csl-table-title">
            <p className="table-p">{item.cell1}</p>
          </td>
          <td className="csl-table-td">
            <ul className="CSl-Table-ul">{buildlist(item.cell2, "table")}</ul>
          </td>
        </tr>
      );
    });
  };
  const buildlist = (content, type) => {
    if (content !== undefined && content !== null) {
      let subDataContent = content
        .slice()
        .sort((a, b) => a.position - b.position);
      return subDataContent.map((item, index) => {
        return type === "" && item.type === "list_item" ? (
          <li key={index} className="table-li">
            {item.text2 !== null && item.text2 !== undefined ? (
              <span className="table-Add-Inf-title">
                {item.text !== null ? item.text : null}
              </span>
            ) : null}
            {item.text2 !== null && item.text2 !== undefined
              ? item.text2
              : item.text}
            {item.content !== null && item.content !== undefined ? (
              <ul className={"CSl-Ul-List"}>{buildlist(item.content, "")}</ul>
            ) : null}
          </li>
        ) : item.type === "link" ? (
          <p key={index} className="contact-list">
            {item.text}
            <a href={"mailto:" + item.text2} className="table-link CSL-email">
              {item.text2}
            </a>
          </p>
        ) : type === "table" &&
          (item.type === "table_row" || item.type === "list_item") ? (
          <li key={index} className="CSl-Table-li">
            {item.text}
          </li>
        ) : (
          <p key={index} className="contact-list">
            {item.text}
          </p>
        );
      });
    } else {
      return 0;
    }
  };
  const buildSubContent = (Content, link) => {
    let subDataContent = Content.slice().sort(
      (a, b) => a.position - b.position
    );
    return subDataContent.map((item, index) => {
      let itemId =
        link === false
          ? item.title.replaceAll("?", "")
          : item.text.replaceAll("?", "");

      return link === false ? (
        <div
          key={index}
          id={itemId.replaceAll(" ", "").slice(0, 10)}
          className="table-link table-Add-Inf-title CSL-Title-p"
        >
          {item.title}
          {buildcontent(item.content, "", "CSL-p")}
        </div>
      ) : (
        <div
          key={index}
          className="table-link table-Add-Inf-title scroll-link CSL-Title-p"
          onClick={() =>
            ScrollToElement(itemId.replaceAll(" ", "").slice(0, 10))
          }
        >
          {item.text}
        </div>
      );
    });
  };
  const ScrollToElement = (Id) => {
    var elmnt = document.getElementById(Id);
    if (elmnt !== null) {
      elmnt.scrollIntoView();
    }
  };
  return (
    <div className="regular-text-container PageSize-Privacy">
      <h1>{data.getPrivacyPolicy.data.pageTitle}</h1>
      {buildcontent(data.getPrivacyPolicy.data.mainText, "", "")}

      {buildSubContent(data.getPrivacyPolicy.data.subtitles, true)}
      {buildcontent(
        data.getPrivacyPolicy.data.complementaryText,
        "contact",
        ""
      )}
      {buildSubContent(data.getPrivacyPolicy.data.sections, false)}
      <p>{data.getPrivacyPolicy.data.effectiveAs}</p>
    </div>
  );
};

export default Privacy;
