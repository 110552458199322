import React from "react";
import "./carouselItem.less";
import ReadMore from "../../assets/icons/ic_circle-arrow-right Torch.png";

const CarouselItem = ({ headerImage, title, posted, description, link }) => {
  return (
    <div className="carousel item">
      <div
        className="pic"
        style={{ backgroundImage: `url('${headerImage}')` }}
      />
      <div className="content">
        <h3 className="heading mobile-only">{title}</h3>
        <h4 className="heading l-and-up">{title}</h4>
        <span className="posted">{posted}</span>
        <p>{description}</p>
        <a
          className="more"
          href={link}
          target="_blank"
          rel="noreferrer"
          style={{ backgroundImage: `url('${ReadMore}')` }}
        >
          &nbsp;
        </a>
      </div>
    </div>
  );
};

export default CarouselItem;
