import React from "react";
import "./patientSupport.less";
import { Link } from "react-router-dom";
import Spinner from "../shared/spinner";
import BackButton from "../shared/backButton";
import { gql, useQuery } from "@apollo/client";
import ResponsiveBg from "../shared/responsiveBg";

const GET_GUIDES_FIVE = gql`
  query GetGuidesFive($pageId: String) {
    getGuidesFive(where: { pageId: $pageId }) {
      data {
        pageTitle
        crumbs1
        crumbs2
        crumbs3
        backButtonText
        circleArrowLeftTorch
        mainPicture
        mainPictureFooter
        mainText
        kidneyDiseaseSupportTitle
        nationalSupportSubtitle
        nationalSupportImages
        nationalSupportTexts
        nationalSupportUrls
        regionalSupportSubtitle
        regionalSupportTexts
        regionalSupportUrls
      }
    }
  }
`;


const PatientSupport = () => {
  const { data, loading, error } = useQuery(GET_GUIDES_FIVE, {
    variables: { pageId: "cpex_guides_five" },
    errorPolicy: "all",
  });
  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;

  window.gtag("event", "page_view", {
    page_title: "Patient Support",
    page_path: window.location.pathname,
  });

 
  return (
    <React.Fragment>
      <div className="Guides---Patient---S">
        <BackButton
          ButtonText={data.getGuidesFive.data.backButtonText}
          Crumb1={data.getGuidesFive.data.crumbs1}
          Crumb2={data.getGuidesFive.data.crumbs2}
          Crumb3={data.getGuidesFive.data.crumbs3}
          Path1="/"
          Path2="/learn"
        ></BackButton>

        <div className="Clinical-Trial-Frequ-Patient">
          {data.getGuidesFive.data.pageTitle}
        </div>
        <div className="Patient-Support-ImageDIv">
          <div className="Patient-LeftDiv">
            <ResponsiveBg
              className="Bitmap-Patient-Support"
              smallBg={data.getGuidesFive.data.mainPicture}
              mediumBg={data.getGuidesFive.data.mainPicture}
              largeBg={data.getGuidesFive.data.mainPicture}
            />
            <div className="Emily-Clinical-Trial">
              {data.getGuidesFive.data.mainPictureFooter}
            </div>
          </div>
          <div className="Youve-likely-had-a Patient-RightDiv">
            <span>{data.getGuidesFive.data.mainText[0]}</span>
            <span>{data.getGuidesFive.data.mainText[1]}</span>
          </div>
        </div>
        <div className="Clinical-Trial-Frequ-Patient Kidney-Disease-Suppo Kidney-Div-Font">
          {data.getGuidesFive.data.kidneyDiseaseSupportTitle}
        </div>

    

        <div className="wig_dev">
            <div className="Rectanglediv" >
              <div className=" ">
                 <ResponsiveBg
            className="image005 "
            smallBg={data.getGuidesFive.data.nationalSupportImages[29]}
            mediumBg={data.getGuidesFive.data.nationalSupportImages[30]}
            largeBg={data.getGuidesFive.data.nationalSupportImages[31]}
                 />
              </div>
            </div>

            <div className={`  American-text-Fund Eastern-title National-title `}>
          <p>Hemophilia A</p>
          </div>          <Link
            to={"#"}
            className={`American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[1],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[2]}
          </Link>
          <Link
            to={"#"}
            className={` American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[2],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[3]}
          </Link>
        </div>

        <div className="wig_dev">
            <div className="Rectanglediv" >
              <div className=" ">
                 <ResponsiveBg
            className="image005 "
            smallBg={data.getGuidesFive.data.nationalSupportImages[29]}
            mediumBg={data.getGuidesFive.data.nationalSupportImages[30]}
            largeBg={data.getGuidesFive.data.nationalSupportImages[31]}
                 />
              </div>
            </div>
      
        
          <div className={`  American-text-Fund Eastern-title National-title `}>
          <p>Hemophilia B</p>
          </div>
          <Link
            to={"#"}
            className={`  American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[3],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[2]}
          </Link>
          <Link
            to={"#"}
            className={` American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[4],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[3]}
          </Link>
         
        </div>

        <div className="wig_dev">
            <div className="Rectanglediv" >
              <div className=" ">
                 <ResponsiveBg
            className="image005 "
            smallBg={data.getGuidesFive.data.nationalSupportImages[29]}
            mediumBg={data.getGuidesFive.data.nationalSupportImages[30]}
            largeBg={data.getGuidesFive.data.nationalSupportImages[31]}
                 />
              </div>
            </div>
      
      
          <div className={`  American-text-Fund Eastern-title National-title `}>
          <p>Factor XIII Deficiency</p>
          </div>
          <Link
            to={"#"}
            className={`  American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[5],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[2]}
          </Link>
          <Link
            to={"#"}
            className={` American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[6],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[3]}
          </Link>
        </div>

        <div className="wig_dev">
            <div className="Rectanglediv1" >
              <div className=" ">
                 <ResponsiveBg
            className="image010 "
            smallBg={data.getGuidesFive.data.nationalSupportImages[26]}
            mediumBg={data.getGuidesFive.data.nationalSupportImages[27]}
            largeBg={data.getGuidesFive.data.nationalSupportImages[28]}
                 />
              </div>
            </div>
      
        
          <div className={`  American-text-Fund Eastern-title National-title `}>
          <p>Fibrinogen Deficiency</p>
          </div>
          <Link
            to={"#"}
            className={`  American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[7],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[2]}
          </Link>

          <Link
            to={"#"}
            className={`  American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[18],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[10]}
          </Link>
         
        </div>
        <div className="wig_dev">
            <div className="Rectanglediv" >
              <div className=" ">
                 <ResponsiveBg
            className="image003 "
            smallBg={data.getGuidesFive.data.nationalSupportImages[23]}
            mediumBg={data.getGuidesFive.data.nationalSupportImages[24]}
            largeBg={data.getGuidesFive.data.nationalSupportImages[25]}
                 />
              </div>
            </div>
      
        
          <div className={`  American-text-Fund Eastern-title National-title `}>
          <p>Hereditary Angiodema(HAE)</p>
          </div>
          <Link
            to={"#"}
            className={`  American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[8],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[2]}
          </Link>
          <Link
            to={"#"}
            className={` American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[9],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[4]}
          </Link>
        </div>
        <div className="wig_dev">
            <div className="Rectanglediv" >
              <div className=" ">
                 <ResponsiveBg
            className="image004 "
            smallBg={data.getGuidesFive.data.nationalSupportImages[20]}
            mediumBg={data.getGuidesFive.data.nationalSupportImages[21]}
            largeBg={data.getGuidesFive.data.nationalSupportImages[22]}
                 />
              </div>
            </div>
      
        
          <div className={`  American-text-Fund Eastern-title National-title `}>
          <p>Alpha 1 Antitrypsin Deficiency </p>
          </div>
          <Link
            to={"#"}
            className={`  American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[10],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[2]}
          </Link>
          <Link
            to={"#"}
            className={` American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[11],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[5]}
          </Link>
        </div>
        <div className="wig_dev">
            <div className="Rectanglediv" >
              <div className=" ">
                 <ResponsiveBg
            className="image006 "
            smallBg={data.getGuidesFive.data.nationalSupportImages[14]}
            mediumBg={data.getGuidesFive.data.nationalSupportImages[15]}
            largeBg={data.getGuidesFive.data.nationalSupportImages[16]}
                 />
              </div>
            </div>
      
        
          <div className={`  American-text-Fund Eastern-title National-title `}>
          <p>Primary Immunodeficiency (PI)</p>
          </div>
          <Link
            to={"#"}
            className={`  American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[12],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[2]}
          </Link>
          <Link
            to={"#"}
            className={` American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[13],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[6]}
          </Link>
        </div>

        <div className="wig_dev">
            <div className="Rectanglediv" >
              <div className=" ">
                 <ResponsiveBg
            className="image009 "
            smallBg={data.getGuidesFive.data.nationalSupportImages[17]}
            mediumBg={data.getGuidesFive.data.nationalSupportImages[18]}
            largeBg={data.getGuidesFive.data.nationalSupportImages[19]}
                 />
              </div>
            </div>
      
        
          <div className={`  American-text-Fund Eastern-title National-title `}>
          <p>Chronic Inflammatory Demylinating Polyneuropathy(CIDP)</p>
          </div>
          <Link
            to={"#"}
            className={`  American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[14],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[2]}
          </Link>
          <Link
            to={"#"}
            className={` American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[15],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[7]}
          </Link>
        </div>


        <div className="wig_dev">
            <div className="Rectangle1" >
              <div className=" ">
                 <ResponsiveBg
            className="image007"
            smallBg={data.getGuidesFive.data.nationalSupportImages[11]}
            mediumBg={data.getGuidesFive.data.nationalSupportImages[12]}
            largeBg={data.getGuidesFive.data.nationalSupportImages[13]}
                 />
              </div>
            </div>
      
        
          <div className={`  American-text-Fund Eastern-title National-title `}>
          <p>Idiopathic Thrombocytopenic Purpura(ITP)</p>
          </div>
          <Link
            to={"#"}
            className={`  American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[16],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[2]}
          </Link>
          <Link 
            to={"#"}
            className={` American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[17],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[8]}
          </Link>
        </div>
        
        <div className="wig_dev">
            <div className="Rectanglediv" >
              <div className=" ">
                 <ResponsiveBg
            className="image008"
            smallBg={data.getGuidesFive.data.nationalSupportImages[8]}
            mediumBg={data.getGuidesFive.data.nationalSupportImages[9]}
            largeBg={data.getGuidesFive.data.nationalSupportImages[10]}
                 />
              </div>
            </div>
      
        
          <div className={`  American-text-Fund Eastern-title National-title `}>
          <p>Sickle Cell Disease</p>
          </div>
          <Link
            to={"#"}
            className={`  American-Kidney-Fund Eastern-title National-title`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[19],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[2]}
          </Link>
          <Link
            to={"#"}
            className={` American-Kidney-Fund Eastern-title National-title Size`}
            onClick={() =>
              window.open(
                data.getGuidesFive.data.nationalSupportUrls[20],
                "_blank"
              )
            }
          >
            {data.getGuidesFive.data.nationalSupportTexts[9]}
          </Link>
        </div>

        


      

      </div>
    </React.Fragment>
  );
};
export default PatientSupport;
