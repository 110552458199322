import React, { useState } from "react";
import "./location.less";
import GoogleMapReact from "google-map-react";
import { useHistory } from "react-router-dom";
import Marker from "../shared/marked";
import { IS_USER_PROFILE_EDITED } from "../../constants";
import CheckMultiSession from "./checkMultiSession";
const mapProps = {
  center: {
    lat: 40.46032,
    lng: -75.40962,
  },
  zoom: 1,
};
let CurrentLag = 0;
let CurrentLit = 0;
navigator.geolocation.getCurrentPosition(function (position) {
  CurrentLit = position.coords.latitude;
  CurrentLag = position.coords.longitude;
});
const Location = ({ cslData }) => {
  window.gtag("event", "page_view", {
    page_title: "Location",
    page_path: window.location.pathname,
  });
  const [showDirection, setshowDirection] = useState(false);
  const history = useHistory();
  if (
    localStorage.getItem(IS_USER_PROFILE_EDITED) === 1 ||
    localStorage.getItem(IS_USER_PROFILE_EDITED) === "1"
  ) {
    localStorage.setItem(IS_USER_PROFILE_EDITED, 2);
    history.push("/trial");
  } else if (
    localStorage.getItem(IS_USER_PROFILE_EDITED) === 2 ||
    localStorage.getItem(IS_USER_PROFILE_EDITED) === "2"
  ) {
    localStorage.setItem(IS_USER_PROFILE_EDITED, "");
  }
  let destinationLat = 0;
  let destinationlong = 0;
  if (
    cslData !== undefined &&
    cslData.cpex.getStudy.data !== null &&
    cslData.cpex.getStudy.data.locations !== undefined &&
    destinationLat === 0
  ) {
    cslData.cpex.getStudy.data.locations
      .filter((v, i, a) => a.findIndex((t) => t === v) === i)
      .map((item, Index) => {
        if (Index === 0) {
          destinationLat = item.latitude;
          destinationlong = item.longitude;
        }
      });
  }
  const handleGetDirections = () => {
    setshowDirection(true);
  };
  return (
    <React.Fragment>
      <h2>Location</h2>
      <div className="location-wrap">
        <div className="location-map">
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
            defaultCenter={mapProps.center}
            defaultZoom={mapProps.zoom}
          >
            <Marker lat={CurrentLit} lng={CurrentLag} />
            {cslData !== undefined &&
            cslData.cpex.getStudy.data !== null &&
            cslData.cpex.getStudy.data.locations !== undefined
              ? cslData.cpex.getStudy.data.locations
                  .filter((v, i, a) => a.findIndex((t) => t === v) === i)
                  .map((item, Index) => {
                    return (
                      <Marker
                        lat={item.latitude}
                        lng={item.longitude}
                        key={Index}
                      />
                    );
                  })
              : null}
          </GoogleMapReact>
        </div>
        <div className="location-details">
          <p className="font-15 location-details-sub">Address</p>
          {cslData !== undefined &&
          cslData.cpex.getStudy.data !== null &&
          cslData.cpex.getStudy.data.locations !== undefined
            ? cslData.cpex.getStudy.data.locations
                .filter((v, i, a) => a.findIndex((t) => t === v) === i)
                .map((item, Index) => {
                  return (
                    <React.Fragment key={Index}>
                      <h3>{item.name}</h3>
                      <p className="location-address">
                        {`${item.state}, ${item.country}, ${item.zip_code}`}
                        <br />
                        {item.city}
                      </p>
                      <span
                        className="link directions"
                        onClick={() => handleGetDirections()}
                      >
                        Get Directions
                      </span>
                    </React.Fragment>
                  );
                })
            : null}
        </div>
      </div>
      <div>
        <hr className="margin-bottom-32" />
        <h2 className="margin-bottom-16">Contact</h2>
        <p>Contact your site coordinator</p>
        <div className="contact-details">
          <div className="email">
            <span>Email</span>
            <p>
              {" "}
              <a
                href={"mailto:clinicaltrials@cslbehring.com"}
                className="CSL-Trial-part-email"
              >
                clinicaltrials@cslbehring.com
              </a>
            </p>
          </div>
          <div className="phone">
            <span>Phone</span>
            <p>
              <a
                href={"callto:(610) 878-4000"}
                className="CSL-Trial-part-email"
              >
                (610) 878-4000
              </a>
            </p>
          </div>
        </div>
      </div>
      <CheckMultiSession></CheckMultiSession>
    </React.Fragment>
  );
};

export default Location;
