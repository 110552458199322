import React from "react";
import { Route, Switch } from "react-router-dom";
import Trial from "./trial";
import StudyGuide from "./studyGuide";

const TrialRouter = () => {
  return (
    <Switch>
      <Route path="/trial/study-guide/:guide" component={StudyGuide} />
      <Route path="/trial" component={Trial} />
    </Switch>
  );
};

export default TrialRouter;
