import React from "react";
import { Link } from "react-router-dom";
import Flicking from "@egjs/react-flicking";
import Slider from "react-slick";
import ResponsiveBg from "../shared/responsiveBg";
import { useMediaQuery } from "react-responsive";
import CarouselItem from "./carouselItem";
import { ModalLink } from "../shared/modal";
import { gql, useQuery } from "@apollo/client";
import Spinner from "../shared/spinner";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./index.less";

import RightArrow from "../../assets/icons/ic_circle-arrow-right dark.svg";

export const GET_HOME_PAGE = gql`
  query GetHomePage($id: ID) {
    getHomePage(where: { id: $id }) {
      data {
        bannerImageSm
        bannerImageMd
        bannerImageLg
        headerTitle
        headerSubtitle
        bannerVideoButtonText
        bannerVideoLink
        exploreOurGuidesSectionImgSm
        exploreOurGuidesSectionImgMd
        exploreOurGuidesSectionImgLg
        exploreOurGuidesSectionTitle
        exploreOurGuidesSectionText
        exploreOurGuidesSectionButtonText
        exploreOurGuidesSectionButtonLink
        seeOurSpecialitiesSectionImgSm
        seeOurSpecialitiesSectionImgMd
        seeOurSpecialitiesSectionImgLg
        seeOurSpecialitiesSectionTitle
        seeOurSpecialitiesSectionText
        seeOurSpecialitiesSectionButtonText
        seeOurSpecialitiesSectionButtonLink
        meetOurPatientsSectionTitle
        meetOurPatientsSectionSubtitle
        meetOurPatientsSectionPicture1
        meetOurPatientsSectionPicture2
        meetOurPatientsSectionPicture3
        meetOurPatientsSectionPicture4
        meetOurPatientsSectionButtonText
        meetOurPatientsSectionVideoLink
        participateInATrialSectionTitle
        participateInATrialSectionText
        participateInATrialSectionButtonText
        participateInATrialButtonLink
        vitaSectionLogo
        vitaSectionTitle
        vitaSectionLinkText
        vitaSectionLink
        articles {
          image
          title
          date
          content
          link
          position
        }
        meetOurPatientsSectionPicture1Alt
        meetOurPatientsSectionPicture2Alt
        meetOurPatientsSectionPicture3Alt
        meetOurPatientsSectionPicture4Alt
        vitaSectionLogoAlt
        closeAltTag
        homePageHeader {
          mainLogo
          home
          learn
          participate
          signIn
        }
        homePageFooter {
          footerLogo
          termsOfUse
          privacyPolicy
          podcast
          twitter
          facebook
          youTube
          instagram
        }
        carousel {
          title
          buttonText
          image
          videoLink
          position
        }
      }
      error {
        code
        message
        data
      }
    }
  }
`;

const Home = () => {
  const isMediumAndUp = useMediaQuery({
    query: "(min-width: 768px)",
  });

  const { data, loading, error } = useQuery(GET_HOME_PAGE, {
    variables: { pageId: "cpex_homepage" },
    errorPolicy: "all",
  });
  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;

  const NextArrow = ({ onClick }) => {
    return (
      <div
        className="next-arr"
        style={{ backgroundImage: `url('${RightArrow}')` }}
        onClick={onClick}
      ></div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div
        className="prev-arr"
        style={{ backgroundImage: `url('${RightArrow}')` }}
        onClick={onClick}
      ></div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: isMediumAndUp ? 1.13 : 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <React.Fragment>
      <ResponsiveBg
        className="hero"
        smallBg={data.getHomePage.data.bannerImageSm}
        mediumBg={data.getHomePage.data.bannerImageMd}
        largeBg={data.getHomePage.data.bannerImageLg}
      />
      <div className="content-edge">
        <div className="shadow-box hero-callout">
          <h1 className="hero-h1">{data.getHomePage.data.headerTitle}</h1>
          <h3 className="hero-h2">{data.getHomePage.data.headerSubtitle}</h3>
          <ModalLink link={data.getHomePage.data.bannerVideoLink}>
            {data.getHomePage.data.bannerVideoButtonText}
          </ModalLink>
        </div>

        <div className="explore eguide eright">
          <ResponsiveBg
            className="eimg"
            smallBg={data.getHomePage.data.exploreOurGuidesSectionImgSm}
            mediumBg={data.getHomePage.data.exploreOurGuidesSectionImgMd}
            largeBg={data.getHomePage.data.exploreOurGuidesSectionImgLg}
          />
          <div className="ebox">
            <h2 className="s-only margin-bottom-16">
              {data.getHomePage.data.exploreOurGuidesSectionTitle}
            </h2>
            <h4 className="m-and-up margin-bottom-16">
              {data.getHomePage.data.exploreOurGuidesSectionTitle}
            </h4>
            <p>{data.getHomePage.data.exploreOurGuidesSectionText}</p>
            <Link className="tertiary slim" to="/learn">
              {data.getHomePage.data.exploreOurGuidesSectionButtonText}
            </Link>
          </div>
        </div>

        <div className="explore especialties eleft">
          <ResponsiveBg
            className="eimg"
            smallBg={data.getHomePage.data.seeOurSpecialitiesSectionImgSm}
            mediumBg={data.getHomePage.data.seeOurSpecialitiesSectionImgMd}
            largeBg={data.getHomePage.data.seeOurSpecialitiesSectionImgLg}
          />
          <div className="ebox">
            <h2 className="s-only margin-bottom-16">
              {data.getHomePage.data.seeOurSpecialitiesSectionTitle}
            </h2>
            <h4 className="m-and-up margin-bottom-16">
              {data.getHomePage.data.seeOurSpecialitiesSectionTitle}
            </h4>
            <p>{data.getHomePage.data.seeOurSpecialitiesSectionText}</p>
            <Link
              className="tertiary slim"
              to="/learn-research-development/pipeline"
            >
              {data.getHomePage.data.seeOurSpecialitiesSectionButtonText}
            </Link>
          </div>
        </div>

        <div className="meet-our-patients">
          <h1 className="sign-painter">
            {data.getHomePage.data.meetOurPatientsSectionTitle}
          </h1>
          <div className="meet-area">
            <div className="meet-carousel">
              <Slider {...settings}>
                {data.getHomePage.data.carousel
                  .slice()
                  .sort((a, b) => (a.position > b.position ? 1 : -1))
                  .map((story, i) => {
                    return (
                      <div className="slider-box" key={`meet-story-${i}`}>
                        <div
                          className="slider-box-img"
                          style={{ backgroundImage: `url('${story.image}')` }}
                        ></div>
                        <div className="slider-box-content">
                          <div className="slider-box-content-shape" />
                          <h4>{story.title}</h4>
                          <ModalLink link={story.videoLink}>
                            {story.buttonText}
                          </ModalLink>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>

        <div className="get-involved-callout m-and-up">
          <div className="involved-content">
            <h4 className="margin-bottom-16">
              {data.getHomePage.data.participateInATrialSectionTitle}
            </h4>
            <p>{data.getHomePage.data.participateInATrialSectionText}</p>
          </div>
          <div className="involved-link">
            <Link className="tertiary" to="/participate">
              {data.getHomePage.data.participateInATrialSectionButtonText}
            </Link>
          </div>
        </div>
        <img
          className="vita-tag"
          src={data.getHomePage.data.vitaSectionLogo}
          alt={data.getHomePage.data.vitaSectionLogoAlt}
        />

        <h2 className="vita-news-head">
          {data.getHomePage.data.vitaSectionTitle}
        </h2>

        <div className="flicking-wrap">
          <Flicking
            className="flicking"
            gap={0}
            duration={650}
            bound={true}
            autoResize={true}
          >
            {data.getHomePage.data.articles
              .slice()
              .sort((a, b) => (a.position > b.position ? 1 : -1))
              .map((article, i) => {
                return (
                  <div className="panel" key={`vita-article-${i}`}>
                    <CarouselItem
                      headerImage={article.image}
                      title={article.title}
                      posted={article.date}
                      description={article.content}
                      link={article.link}
                    />
                  </div>
                );
              })}
          </Flicking>
        </div>
        <div className="see-more-stories l-and-up">
          <a
            className="plain"
            href={data.getHomePage.data.vitaSectionLink}
            target="_blank"
            rel="noreferrer"
          >
            {data.getHomePage.data.vitaSectionLinkText}
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
