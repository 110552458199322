import React from "react";
import ResponsiveBg from "../shared/responsiveBg";
import Link from "./link";
import "./guide.less";

const guide = ({ className, GuideData, SectionID, ShowMore }) => {
  const Titleclass =
    GuideData.title === "Research & Development"
      ? "Guides researchTitle"
      : "Guides";
  const RightdivClass =
    GuideData.title === "Research & Development"
      ? "margin-top ResearchRightdiv"
      : "margin-top";
  const BuildLink = () => {
    let LinksArray = GuideData.links
      .slice()
      .sort((a, b) => a.position - b.position);
    return LinksArray.map((Links, index) => {
      return (
        <React.Fragment key={index}>
          <Link
            LinkText={Links.description}
            RedirectURL={
              Links.pageId !== null
                ? Links.link + `?pageid=` + Links.pageId
                : Links.link
            }
          />
        </React.Fragment>
      );
    });
  };
  return (
    <React.Fragment>
      <div className={"Rectangle-guide " + className} id={SectionID}>
        <div className="leftdiv">
          <ResponsiveBg
            className="Bitmap-img"
            smallBg={GuideData.imageSm}
            mediumBg={GuideData.imageMd}
            largeBg={GuideData.imageLg}
          />
          <div className={Titleclass}>{GuideData.title}</div>
          <div className="Lean-what-to-expect">{GuideData.description}</div>
        </div>
        <div className={RightdivClass}>
          {BuildLink()}
          {ShowMore != null ? (
            <div className="See-moreDiv">
              <a href="/" className="See-more">
                {ShowMore}
              </a>{" "}
            </div>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default guide;
