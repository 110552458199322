export const whatIsGVHD = () => {
    return (<div class="Praesent-commodo-cur COndition1-Answer">
    <p>
    Graft-versus-Host Disease (GvHD) is a common and potentially serious and life-threatening complication 
    of hematopoietic stem cell transplant or bone marrow transplant. The risk of GvHD is higher in allogeneic 
    transplants when stem cells from another person are donated to the patient. When your immune system is working normally, 
    it helps you stay healthy by defending against foreign invaders like viruses or bacteria. 
    In GvHD, the cells from the donor (graft) see your body (host) as foreign, so they attack and can damage 
    your organs and tissues. There are two main categories of GvHD: acute graft-versus-host disease (acute GvHD) 
    typically develops within the first 100 days after the transplant, and chronic graft-versus-host disease (chronic GvHD) 
    typically occurs later. The different types of GvHD may affect different organs and vary greatly in 
    symptoms and signs of disease. As an allogeneic transplant recipient, you might experience either type of GvHD, both types, or neither.
    </p>              
  </div>)    
}

export const GVHDSymptoms = () => {
  return (<div class="Praesent-commodo-cur COndition1-Answer">
    <p>
    <ul>
      <li>
        <p>
          Skin rash
        </p>
      </li>
      <li>
        <p>
          Burning
        </p>
      </li>
      <li>
        <p>
          Itchy or reddened areas that may blister
        </p>
      </li>
      <li>
        <p>
          Nausea
        </p>
      </li>
      <li>
        <p>
          Vomiting
        </p>
      </li>
      <li>
        <p>
        Diarrhea
        </p>
      </li>
      <li>
        <p>
          Abdominal cramps suggesting involvement of the GI system
        </p>
      </li>
      <li>
        <p>
          Yellow discoloration of the skin and/or eyes
        </p>
      </li>
      <li>
        <p>
          Abnormal blood test results which can indicate an involvement of the liver
        </p>
      </li>
    </ul>
    <p>
    <br></br>
      Acute GvHD can affect the skin, the gastrointestinal (GI) system, or the liver. 
      The symptoms can include skin rash, burning, itchy or reddened areas that may blister; nausea, 
      vomiting, diarrhea, or abdominal cramps suggesting involvement of the GI system; yellow discoloration 
      of the skin and/or eyes, and abnormal blood test results which can indicate an involvement of the liver. 
      Chronic GvHD can affect a single organ or multiple organs. The skin, GI system, and liver may be affected, but
       it may also involve the eyes, lungs, genitals, muscles, joints, and other tissues. GvHD Diagnosis is
        based on your clinical symptoms as well as biopsies and laboratory tests.
    </p>
    </p>              
  </div>)    
}