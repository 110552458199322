import React from "react";
import "./featuredArticles.less";
import Spinner from "../shared/spinner";
import Flicking from "@egjs/react-flicking";
import CarouselItem from "../home/carouselItem";
import BackButton from "../shared/backButton";
import { gql, useQuery } from "@apollo/client";
import ResponsiveBg from "../shared/responsiveBg";
import { Link } from "react-router-dom";

const GET_RD_FEATURED_ARTICLES = gql`
  query GetRdTwo($pageId: String) {
    getRdTwo(where: { pageId: $pageId }) {
      data {
        pageTitle
        crumbs1
        crumbs2
        crumbs3
        backButtonText
        circleArrowLeftTorch
        vitaLogo
        newsTitle
        articles {
          position
          image
          title
          date
          content
          link
        }
        seeMoreStoriesText
        seeMoreStoriesLink
        podcastTitle
        listenOnText
        appleLinkText
        appleLink
        spotifyLinkText
        spotifyLink
        rssLinkText
        rssLink
      }
      error {
        code
        message
        data
      }
    }
  }
`;

const FeaturedArticles = () => {
  const { data, loading, error } = useQuery(GET_RD_FEATURED_ARTICLES, {
    variables: { pageId: "cpex_rd_featured_articles" },
    errorPolicy: "all",
  });
  const [storyModalIsOpen, setStoryIsOpen] = React.useState(false);
  if (loading) {
    return <Spinner />;
  }
  if (error) throw error;

  window.gtag("event", "page_view", {
    page_title: "FeaturedArticles",
    page_path: window.location.pathname,
  });

  const MoreConditions = () => {
    return data.getRdTwo.data.articles
      .slice()
      .sort((a, b) => a.position - b.position)
      .map((articlesItem, articlesIndex) => {
        return (
          <React.Fragment key={"articles" + articlesIndex}>
            <div className="panel">
              <CarouselItem
                headerImage={articlesItem.image}
                title={articlesItem.title}
                posted={articlesItem.date}
                description={articlesItem.text}
                link={articlesItem.link}
              />
            </div>
          </React.Fragment>
        );
      });
  };
  return (
    <React.Fragment>
      <div className="Featured-Page-S">
        <div className="Featured-BackButton">
          <BackButton
            ButtonText={data.getRdTwo.data.backButtonText}
            Crumb1={data.getRdTwo.data.crumbs1}
            Crumb2={data.getRdTwo.data.crumbs2}
            Crumb3={data.getRdTwo.data.crumbs3}
            Path1="/"
            Path2="/learn"
          ></BackButton>
        </div>
        <div className="Fetured-Page-Title">{data.getRdTwo.data.pageTitle}</div>
        <ResponsiveBg
          className="Bitmap-Condition"
          smallBg={data.getRdTwo.data.vitaLogo}
          mediumBg={data.getRdTwo.data.vitaLogo}
          largeBg={data.getRdTwo.data.vitaLogo}
        />
        <div className="Fetured-Page-Title CSL-Behring-News-U-FA">
          {data.getRdTwo.data.newsTitle}
        </div>
        <div className="flicking-wrap">
          <Flicking
            className="flicking"
            gap={0}
            duration={650}
            bound={true}
            autoResize={true}
          >
            {MoreConditions()}
          </Flicking>
        </div>
        <Link
          to={"#"}
          className={`LinkSeeMore-FA`}
          onClick={() =>
            window.open(data.getRdTwo.data.seeMoreStoriesLink, "_blank")
          }
        >
          {data.getRdTwo.data.seeMoreStoriesText}
        </Link>
        <div className="Fetured-Page-Title CSL-Behring-News-U">
          {"CSL Behring World of Promise Podcast"}
        </div>
        <div className="csl-modal-content">
          {storyModalIsOpen ? (
            <iframe
              src="https://open.spotify.com/embed-podcast/episode/0VVoDyMpYYQvDtoMRlWZvd"
              width="100%"
              height="160"
              title=" "
              frameBorder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          ) : (
            <iframe
              src="https://html5-player.libsyn.com/embed/episode/id/17436395/height/90/theme/custom/thumbnail/yes/direction/forward/render-playlist/no/custom-color/fc1921/"
              height="90"
              width="100%"
              title=" "
              scrolling="no"
              allowFullScreen
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              oallowfullscreen="true"
              msallowfullscreen="true"
            ></iframe>
          )}
        </div>
        <div className="Listen-on-div">
          <div className="Listen-on-Span Listen-on-item">
            {data.getRdTwo.data.listenOnText}
          </div>
          <Link
            to={"#"}
            className={`LinkSeeMore-FA Listen-on-item`}
            onClick={() => setStoryIsOpen(!storyModalIsOpen)}
          >
            {data.getRdTwo.data.appleLinkText}
          </Link>
          <Link
            to={"#"}
            className={`LinkSeeMore-FA Apple-Podcasts`}
            onClick={() => setStoryIsOpen(!storyModalIsOpen)}
          >
            {data.getRdTwo.data.spotifyLinkText}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};
export default FeaturedArticles;
