import React from "react";
import { Link } from "react-router-dom";
import "./backButton.less";
import ImageButton from "../shared/imageButton";
const BackButton = ({ ButtonText, Crumb1, Crumb2, Crumb3, Path1, Path2 }) => {
  return (
    <React.Fragment>
      <ImageButton
        type="left"
        text={ButtonText}
        to={Path2}
        className="BackButton-Margintop"
      />
      <div className="Guide-breabrumb BackButton-Margintop">
        <Link to={Path1} className="CSL-breadCrumb">
          {Crumb1}
        </Link>
        <span className="Path-breadCrumb">{">"}</span>
        <Link to={Path2} className="Learn-breadCrumb">
          {Crumb2}
        </Link>
        <span className="Path-breadCrumb">{">"}</span>
        <span className="What-is-a-Clinical-T-breadCrumb">{Crumb3}</span>
      </div>
    </React.Fragment>
  );
};
export default BackButton;
