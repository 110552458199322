import RightArrow from "../../assets/icons/ic_circle-arrow-right Torch.png";
import React from "react";
import { NavLink } from "react-router-dom";
import "./link.less";

const linklist = ({ LinkText, RedirectURL }) => {
  return (
    <div className="Rectangle-LinkList">
      <NavLink to={RedirectURL} className="Link-Title">
        {LinkText}
      </NavLink>
      <NavLink
        to={RedirectURL}
        style={{ backgroundImage: `url('${RightArrow}')` }}
        className="Oval"
      ></NavLink>
    </div>
  );
};
export default linklist;
