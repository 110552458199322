import React from "react";
import "./index.less";
import BackButton from "../shared/backButton";
import { gql, useQuery } from "@apollo/client";
import ResponsiveBg from "../shared/responsiveBg";
import Spinner from "../shared/spinner";

const GET_GUIDES_ONE = gql`
  query GetGuidesOne($pageId: String) {
    getGuidesOne(where: { pageId: $pageId }) {
      data {
        pageTitle
        crumbs1
        crumbs2
        crumbs3
        backButtonText
        circleArrowLeftTorch
        mainText
        whyIsItImportantSectionTitle
        whyIsItImportantSectionImg
        whyIsItImportantSectionText
        howAreCtConductedSectionTitle
        howAreCtConductedSectionImg
        howAreCtConductedSectionText
        whatAreClinicalPhasesSectionTitle
        whatAreClinicalPhasesSectionImg
        whatAreClinicalPhasesSectionText
      }
    }
  }
`;

const LearnGuide = () => {
  const { data, loading, error } = useQuery(GET_GUIDES_ONE, {
    variables: { pageId: "cpex_guides_one" },
    errorPolicy: "all",
  });

  if (loading) {
    return <Spinner />;
  }

  if (error) throw error;

  window.gtag("event", "page_view", {
    page_title: "What is a Clinical Trial",
    page_path: window.location.pathname,
  });

  return (
    <React.Fragment>
      <div className="Guides---What-is-a-Clinical-Trial---S">
        <div className="Div-Clinical-trials-cann2">
          <BackButton
            ButtonText={data.getGuidesOne.data.backButtonText}
            Crumb1={data.getGuidesOne.data.crumbs1}
            Crumb2={data.getGuidesOne.data.crumbs2}
            Crumb3={data.getGuidesOne.data.crumbs3}
            Path1="/"
            Path2="/learn"
          ></BackButton>
        </div>
        <div className="What-is-a-Clinical-T">
          {data.getGuidesOne.data.pageTitle}
        </div>
        <div className="A-clinical-trial-is">
          {data.getGuidesOne.data.mainText[0]}
        </div>
        <div className="Why-is-it-important">
          {data.getGuidesOne.data.whyIsItImportantSectionTitle}
        </div>
        <div className="Div-Clinical-trials-cann">
          <ResponsiveBg
            className="Bitmap-Guide-what-is-clinical-trial"
            smallBg={data.getGuidesOne.data.whyIsItImportantSectionImg}
            mediumBg={data.getGuidesOne.data.whyIsItImportantSectionImg}
            largeBg={data.getGuidesOne.data.whyIsItImportantSectionImg}
          />
          <div className="Clinical-trials-cann">
            {data.getGuidesOne.data.whyIsItImportantSectionText[0]}
          </div>
        </div>
        <div className="How-are-clinical-tri">
          {data.getGuidesOne.data.howAreCtConductedSectionTitle}
        </div>
        <div className="Div-Clinical-trials-cann1">
          <ResponsiveBg
            className="Bitmap-Guide-what-is-clinical-trial1"
            smallBg={data.getGuidesOne.data.howAreCtConductedSectionImg}
            mediumBg={data.getGuidesOne.data.howAreCtConductedSectionImg}
            largeBg={data.getGuidesOne.data.howAreCtConductedSectionImg}
          />
          <div className="Before-a-clinical-tr">
            {data.getGuidesOne.data.howAreCtConductedSectionText[0]}
          </div>
        </div>
        <div className="div-the-length-of-the">
          <div className="div-the-length-of-the-point1">
            <div className="div-the-length-of-the-line">.</div>
            <div className="div-the-length-of-the-text1">
              {data.getGuidesOne.data.howAreCtConductedSectionText[1]}
            </div>
          </div>
          <div className="div-the-length-of-the-point">
            <div className="div-the-length-of-the-line">.</div>
            <div className="div-the-length-of-the-text">
              {data.getGuidesOne.data.howAreCtConductedSectionText[2]}
            </div>
          </div>
          <div className="div-the-length-of-the-point">
            <div className="div-the-length-of-the-line">.</div>
            <div className="div-the-length-of-the-text">
              {data.getGuidesOne.data.howAreCtConductedSectionText[3]}
            </div>
          </div>
          <div className="div-the-length-of-the-point">
            <div className="div-the-length-of-the-line">.</div>
            <div className="div-the-length-of-the-text">
              {data.getGuidesOne.data.howAreCtConductedSectionText[4]}
            </div>
          </div>
        </div>
        <div className="A-research-team-is-a">
          <div className="A-research-team-is-a1">
            {data.getGuidesOne.data.howAreCtConductedSectionText[5]}
          </div>
          <div className="A-research-team-is-a1">
            {data.getGuidesOne.data.howAreCtConductedSectionText[6]}
          </div>
          <div className="A-research-team-is-a1">
            {data.getGuidesOne.data.howAreCtConductedSectionText[7]}
          </div>
          <div className="A-research-team-is-a1">
            {data.getGuidesOne.data.howAreCtConductedSectionText[8]}
          </div>
        </div>
        <div className="What-are-clinical-ph">
          {data.getGuidesOne.data.whatAreClinicalPhasesSectionTitle}
        </div>
        <div className="Div-Clinical-trials-cann1">
          <ResponsiveBg
            className="Bitmap-Guide-what-is-clinical-trial Bitmap-clinical-phases"
            smallBg={data.getGuidesOne.data.whatAreClinicalPhasesSectionImg}
            mediumBg={data.getGuidesOne.data.whatAreClinicalPhasesSectionImg}
            largeBg={data.getGuidesOne.data.whatAreClinicalPhasesSectionImg}
          />
          <div className="Clinical-trials-are">
            {data.getGuidesOne.data.whatAreClinicalPhasesSectionText[0]}
          </div>
          <div className="div-In-Phase-I-trials">
            <div className="div-In-Phase-I-trials-point">
              <div className="div-In-Phase-I-trials-line">.</div>
              <div className="div-In-Phase-I-trials-text">
                <div className="text-style-1">In Phase I trials</div>
                {data.getGuidesOne.data.whatAreClinicalPhasesSectionText[1].slice(
                  17
                )}
              </div>
            </div>
            <div className="div-In-Phase-I-trials-point">
              <div className="div-In-Phase-I-trials-line">.</div>
              <div className="div-In-Phase-I-trials-text">
                <div className="text-style-1">In Phase II trials</div>
                {data.getGuidesOne.data.whatAreClinicalPhasesSectionText[2].slice(
                  18
                )}
              </div>
            </div>
            <div className="div-In-Phase-I-trials-point">
              <div className="div-In-Phase-I-trials-line">.</div>
              <div className="div-In-Phase-I-trials-text">
                <div className="text-style-1">In Phase III trials</div>
                {data.getGuidesOne.data.whatAreClinicalPhasesSectionText[3].slice(
                  19
                )}
              </div>
            </div>
            <div className="div-In-Phase-I-trials-point margin-bot">
              <div className="div-In-Phase-I-trials-line">.</div>
              <div className="div-In-Phase-I-trials-text">
                <div className="text-style-1">In Phase IV trials</div>
                {data.getGuidesOne.data.whatAreClinicalPhasesSectionText[4].slice(
                  18
                )}
              </div>
            </div>
          </div>
        </div>              
      </div>  
      <br></br>
      <div className="Div-Clinical-trials-source">
      <p>Source: CISCRP (Center for Information and Study on 
        Clinical Research Participation)
      </p>
  </div>      
    </React.Fragment>

  );
};
export default LearnGuide;
